import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280 1280">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">
		<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M848.000000,1281.000000 
	C565.333313,1281.000000 283.166656,1281.000000 1.000000,1281.000000 
	C1.000000,854.333313 1.000000,427.666656 1.000000,1.000000 
	C427.666656,1.000000 854.333313,1.000000 1281.000000,1.000000 
	C1281.000000,427.666656 1281.000000,854.333313 1281.000000,1281.000000 
	C1136.833374,1281.000000 992.666687,1281.000000 848.000000,1281.000000 
M365.913666,643.224304 
	C357.325439,658.420349 348.942383,673.737061 340.092468,688.779175 
	C330.827698,704.526489 320.983185,719.931641 311.629547,735.627869 
	C299.118530,756.622437 286.836700,777.753479 274.455109,798.825134 
	C266.119537,813.011169 257.753082,827.179138 249.453888,841.386353 
	C242.747192,852.867493 236.219604,864.454163 229.435410,875.888977 
	C218.411118,894.470398 207.227997,912.957520 196.137711,931.499878 
	C182.856689,953.705200 169.594238,975.921631 156.350723,998.149292 
	C155.085358,1000.273071 153.763397,1002.418579 152.908386,1004.717896 
	C151.732925,1007.879272 150.978043,1011.197021 150.042923,1014.447754 
	C152.938385,1015.191162 155.833328,1016.580200 158.729385,1016.582458 
	C215.061203,1016.627258 271.393158,1016.436890 327.725067,1016.439209 
	C553.723999,1016.448486 779.722961,1016.456604 1005.721741,1016.597900 
	C1042.542725,1016.620972 1079.363159,1017.319519 1116.184082,1017.685364 
	C1119.334351,1017.716675 1122.518677,1017.859009 1125.629883,1017.480164 
	C1130.238770,1016.918823 1131.557129,1014.892090 1129.992432,1010.739990 
	C1128.767944,1007.491089 1127.233032,1004.302307 1125.418823,1001.342468 
	C1118.299805,989.727966 1110.866699,978.304688 1103.824585,966.644592 
	C1090.587402,944.726685 1077.561279,922.681274 1064.408447,900.712158 
	C1051.346313,878.894409 1038.159790,857.150696 1025.168823,835.290710 
	C1012.513000,813.994751 1000.078674,792.567322 987.467529,771.244629 
	C975.222351,750.540710 962.880981,729.893799 950.572998,709.227112 
	C937.048035,686.516907 923.388245,663.885620 910.053284,641.064514 
	C905.649536,633.528076 900.606445,626.076782 900.869141,616.679443 
	C901.283081,601.872864 901.605469,587.063660 901.985352,572.256104 
	C902.100647,567.764465 902.672546,563.243408 902.323425,558.791687 
	C901.916504,553.601196 899.711243,552.545349 895.714417,555.644775 
	C889.821899,560.214233 884.413818,565.403137 878.608582,570.091919 
	C874.296021,573.575073 871.195129,573.027588 868.091919,568.278320 
	C859.284485,554.798767 850.361572,541.383789 841.963867,527.650024 
	C829.741760,507.661560 817.964417,487.401489 805.948792,467.286285 
	C797.528137,453.189301 788.947937,439.187256 780.582642,425.057770 
	C772.209412,410.915009 764.146667,396.587494 755.705994,382.485779 
	C746.486755,367.083405 736.836914,351.938263 727.656860,336.513123 
	C718.305298,320.799805 709.405640,304.818085 700.113708,289.068573 
	C684.751892,263.030731 669.252258,237.074020 653.769043,211.107971 
	C649.775391,204.410416 645.600281,197.821274 641.559570,191.151413 
	C639.526428,187.795334 636.735596,187.350861 634.118469,189.880432 
	C631.309509,192.595474 628.670166,195.681259 626.716858,199.046448 
	C622.382568,206.513962 618.729370,214.375610 614.427124,221.863144 
	C605.983276,236.558594 597.283630,251.107010 588.694336,265.718903 
	C579.005310,282.201660 569.290100,298.669128 559.643921,315.176971 
	C550.701904,330.479736 541.841064,345.829895 532.935303,361.153870 
	C522.994995,378.258026 513.172913,395.432556 503.063263,412.436066 
	C492.279053,430.574158 481.054810,448.451141 470.317780,466.616547 
	C454.256134,493.790375 438.573730,521.188354 422.507111,548.359192 
	C403.889526,579.844055 385.030701,611.186218 365.913666,643.224304 
z"/>
<path fill="#6C4547" opacity="1.000000" stroke="none" 
	d="
M366.095154,642.907349 
	C385.030701,611.186218 403.889526,579.844055 422.507111,548.359192 
	C438.573730,521.188354 454.256134,493.790375 470.317780,466.616547 
	C481.054810,448.451141 492.279053,430.574158 503.063263,412.436066 
	C513.172913,395.432556 522.994995,378.258026 532.935303,361.153870 
	C541.841064,345.829895 550.701904,330.479736 559.643921,315.176971 
	C569.290100,298.669128 579.005310,282.201660 588.694336,265.718903 
	C597.283630,251.107010 605.983276,236.558594 614.427124,221.863144 
	C618.729370,214.375610 622.382568,206.513962 626.716858,199.046448 
	C628.670166,195.681259 631.309509,192.595474 634.118469,189.880432 
	C636.735596,187.350861 639.526428,187.795334 641.559570,191.151413 
	C645.600281,197.821274 649.775391,204.410416 653.769043,211.107971 
	C669.252258,237.074020 684.751892,263.030731 700.113708,289.068573 
	C709.405640,304.818085 718.305298,320.799805 727.656860,336.513123 
	C736.836914,351.938263 746.486755,367.083405 755.705994,382.485779 
	C764.146667,396.587494 772.209412,410.915009 780.582642,425.057770 
	C788.947937,439.187256 797.528137,453.189301 805.948792,467.286285 
	C817.964417,487.401489 829.741760,507.661560 841.963867,527.650024 
	C850.361572,541.383789 859.284485,554.798767 868.091919,568.278320 
	C871.195129,573.027588 874.296021,573.575073 878.608582,570.091919 
	C884.413818,565.403137 889.821899,560.214233 895.714417,555.644775 
	C899.711243,552.545349 901.916504,553.601196 902.323425,558.791687 
	C902.672546,563.243408 902.100647,567.764465 901.985352,572.256104 
	C901.605469,587.063660 901.283081,601.872864 900.869141,616.679443 
	C900.606445,626.076782 905.649536,633.528076 910.053284,641.064514 
	C923.388245,663.885620 937.048035,686.516907 950.572998,709.227112 
	C962.880981,729.893799 975.222351,750.540710 987.467529,771.244629 
	C1000.078674,792.567322 1012.513000,813.994751 1025.168823,835.290710 
	C1038.159790,857.150696 1051.346313,878.894409 1064.408447,900.712158 
	C1077.561279,922.681274 1090.587402,944.726685 1103.824585,966.644592 
	C1110.866699,978.304688 1118.299805,989.727966 1125.418823,1001.342468 
	C1127.233032,1004.302307 1128.767944,1007.491089 1129.992432,1010.739990 
	C1131.557129,1014.892090 1130.238770,1016.918823 1125.629883,1017.480164 
	C1122.518677,1017.859009 1119.334351,1017.716675 1116.184082,1017.685364 
	C1079.363159,1017.319519 1042.542725,1016.620972 1005.721741,1016.597900 
	C779.722961,1016.456604 553.723999,1016.448486 327.725067,1016.439209 
	C271.393158,1016.436890 215.061203,1016.627258 158.729385,1016.582458 
	C155.833328,1016.580200 152.938385,1015.191162 150.042923,1014.447754 
	C150.978043,1011.197021 151.732925,1007.879272 152.908386,1004.717896 
	C153.763397,1002.418579 155.085358,1000.273071 156.350723,998.149292 
	C169.594238,975.921631 182.856689,953.705200 196.137711,931.499878 
	C207.227997,912.957520 218.411118,894.470398 229.435410,875.888977 
	C236.219604,864.454163 242.747192,852.867493 249.453888,841.386353 
	C257.753082,827.179138 266.119537,813.011169 274.455109,798.825134 
	C286.836700,777.753479 299.118530,756.622437 311.629547,735.627869 
	C320.983185,719.931641 330.827698,704.526489 340.092468,688.779175 
	C348.942383,673.737061 357.325439,658.420349 366.095154,642.907349 
M968.031372,745.274597 
	C967.359802,744.502014 966.688293,743.729431 966.037659,742.496460 
	C966.037659,742.496460 966.041687,742.035583 966.523621,741.634399 
	C964.700317,739.373474 962.877014,737.112549 961.078918,734.254578 
	C961.068726,733.856506 961.058594,733.458496 961.012207,732.353516 
	C960.017883,731.540894 959.023560,730.728271 957.951599,729.206543 
	C955.975220,726.144287 953.998901,723.082031 951.816589,719.556580 
	C951.548767,719.358276 951.280884,719.159973 951.029663,718.230225 
	C951.034973,717.498657 951.040283,716.767029 951.033752,715.278748 
	C950.036255,714.476501 949.038818,713.674255 947.998291,712.265076 
	C947.674805,711.526917 947.351318,710.788757 947.007263,709.366882 
	C946.371155,708.205994 945.734985,707.045166 945.040710,705.314392 
	C944.724304,704.866943 944.407898,704.419556 943.976318,703.269348 
	C941.998962,700.833069 940.021606,698.396729 938.007324,695.989258 
	C938.007324,695.989258 938.039368,695.967102 938.054321,695.191650 
	C937.816528,691.755188 936.941406,688.764954 932.528320,687.630737 
	C932.393738,687.121521 932.259216,686.612244 932.594971,685.652527 
	C929.096252,679.606934 925.693848,673.502869 922.070496,667.532959 
	C918.749329,662.061035 914.991882,656.848450 911.788940,651.312012 
	C908.331970,645.336365 905.295471,639.117432 902.074829,632.164001 
	C900.941223,627.068909 899.807617,621.973755 898.702209,616.102905 
	C898.322632,612.804810 897.943115,609.506714 898.148376,605.920166 
	C898.154297,604.278992 898.160217,602.637878 898.088806,600.064514 
	C898.113708,594.378906 898.138611,588.693298 898.718506,582.506775 
	C899.426086,575.477112 900.277405,568.456299 900.710205,561.409729 
	C900.789124,560.124878 899.160095,558.735229 898.319458,557.393982 
	C897.193665,558.161072 896.067871,558.928162 894.263306,559.870911 
	C893.167297,560.889893 892.071228,561.908875 890.312134,563.031799 
	C888.880859,564.360962 887.449524,565.690186 886.000000,567.000000 
	C886.000000,567.000000 886.019348,567.018494 885.434814,567.240662 
	C884.632446,568.169739 883.830017,569.098816 883.000000,570.002441 
	C883.000000,570.002441 883.027283,570.033386 882.322083,570.092712 
	C878.273376,572.614685 874.740540,576.599426 868.996826,574.069763 
	C868.065857,572.507141 867.295166,570.816833 866.171875,569.407471 
	C863.553833,566.122681 860.766113,562.973145 857.726929,559.187622 
	C857.313599,558.465881 856.948059,557.711426 856.479370,557.027527 
	C852.341309,550.989990 848.068176,545.040894 844.079895,538.906372 
	C841.858398,535.489563 840.064087,531.790405 838.146729,528.181580 
	C836.317078,524.737976 834.640137,521.212585 832.782349,517.784668 
	C830.497986,513.569702 828.229675,509.336029 825.713684,505.258942 
	C821.850891,498.999329 817.600220,492.974426 813.855774,486.647766 
	C810.446045,480.886475 807.725159,474.714813 804.268494,468.984680 
	C800.954285,463.490509 796.981567,458.398041 793.491455,453.004028 
	C790.650879,448.613831 788.175415,443.988922 785.391602,439.560089 
	C782.791809,435.423981 779.718811,431.565552 777.344727,427.311157 
	C770.440796,414.939087 764.086365,402.252197 756.934937,390.029022 
	C750.896118,379.707520 743.755066,370.034149 737.629639,359.759277 
	C730.620361,348.001953 724.210022,335.888092 717.506592,323.947601 
	C714.694885,318.939178 711.798645,313.978210 708.659546,308.374695 
	C707.668701,306.731812 706.674133,305.091095 705.687744,303.445526 
	C703.801392,300.298584 701.919678,297.148834 700.017761,293.984802 
	C700.017761,293.984802 700.011536,293.961609 699.947144,293.261139 
	C697.640076,290.151947 695.333069,287.042755 692.944580,283.224792 
	C690.509827,279.844269 688.090027,276.452850 685.634827,273.087341 
	C684.138306,271.036011 682.587097,269.024567 681.020935,266.979584 
	C681.020935,266.979584 681.022766,266.937134 680.963135,266.226196 
	C674.537231,254.677475 668.152832,243.105301 661.650757,231.599609 
	C660.336182,229.273483 658.576538,227.198883 656.544800,224.964523 
	C656.544800,224.964523 656.068970,224.906708 656.051453,224.150269 
	C655.255493,222.204025 654.697205,220.113602 653.620239,218.337799 
	C649.977112,212.331055 645.926025,206.561188 642.502197,200.437271 
	C641.099670,197.928665 639.947693,195.122879 636.881531,196.147308 
	C634.346619,196.994247 631.682251,198.955536 630.209534,201.175552 
	C626.868164,206.212524 624.361450,211.793854 621.263733,217.004303 
	C617.586975,223.188736 613.642883,229.214157 609.826904,235.316147 
	C609.564331,235.735962 609.417786,236.226578 609.194092,236.672668 
	C606.992798,241.062103 605.081482,245.629822 602.525024,249.802032 
	C595.488708,261.285614 588.037415,272.515289 581.023315,284.011993 
	C574.445740,294.793365 568.178894,305.765686 561.889587,316.719574 
	C561.202332,317.916565 561.270203,319.547089 560.947144,321.451569 
	C560.947144,321.451569 560.883606,321.924103 560.179077,322.045837 
	C559.144409,323.792053 558.128235,325.549530 557.072083,327.282654 
	C550.092896,338.735718 543.102844,350.182129 536.121094,361.633606 
	C532.562256,367.470795 528.961487,373.283264 525.473572,379.162598 
	C521.459839,385.928223 517.483093,392.717682 513.613892,399.566650 
	C509.881165,406.174194 506.528595,413.005402 502.604523,419.493683 
	C497.806274,427.427246 492.515076,435.060883 487.573120,442.909912 
	C483.905457,448.735138 480.504242,454.727539 476.889465,460.587006 
	C474.562927,464.358276 471.896545,467.925934 469.672760,471.753510 
	C460.997284,486.685669 452.364288,501.643677 443.865448,516.676819 
	C439.531158,524.343506 435.765869,532.338440 431.284821,539.913757 
	C426.779449,547.530212 421.554626,554.717468 416.914551,562.258667 
	C409.738464,573.921265 402.697510,585.669800 395.777344,597.486145 
	C390.345490,606.761108 385.252411,616.233826 379.880859,625.544861 
	C374.711304,634.505798 369.332092,643.345459 364.128479,652.287048 
	C358.662598,661.679321 353.292694,671.127441 347.886353,680.554321 
	C340.777771,692.949463 333.756775,705.396301 326.490021,717.698059 
	C325.243408,719.808350 322.472260,721.021484 321.238464,723.135315 
	C315.952209,732.192200 311.129974,741.519836 305.852722,750.582275 
	C298.423706,763.339783 290.674042,775.910156 283.185577,788.633606 
	C274.714874,803.025879 266.349335,817.480469 258.004730,831.946411 
	C254.815781,837.474731 251.958160,843.197327 248.682312,848.671448 
	C245.303909,854.316956 241.406311,859.654053 238.078583,865.327087 
	C230.746353,877.827209 223.588837,890.430664 216.445007,903.040039 
	C213.488129,908.259277 210.749191,913.601868 207.317261,919.266602 
	C203.968521,925.275452 200.672806,931.314636 197.254288,937.283447 
	C194.466675,942.150818 191.612076,946.983826 188.642670,951.741882 
	C184.929169,957.692200 181.045670,963.536072 177.275803,969.451599 
	C172.487137,976.965759 170.687943,986.430908 162.646347,991.832581 
	C162.119263,992.186646 161.386810,992.894836 161.442123,993.355042 
	C162.074585,998.617493 157.691101,1001.473022 155.767914,1005.492920 
	C153.692978,1009.829956 155.891617,1013.631226 160.690887,1013.998413 
	C162.678757,1014.150635 164.687576,1014.031067 166.686874,1014.031128 
	C477.913757,1014.035339 789.140686,1014.035583 1100.367554,1014.070862 
	C1106.170288,1014.071533 1111.971802,1014.522705 1117.775879,1014.701782 
	C1123.804810,1014.887817 1126.718140,1011.272522 1124.218628,1006.495361 
	C1122.651733,1003.500793 1120.609863,1000.743652 1118.666870,997.959717 
	C1116.225708,994.461914 1112.199829,991.326172 1111.475098,987.533997 
	C1110.093384,980.304871 1104.465210,975.810730 1101.686279,969.438782 
	C1097.061523,958.834473 1088.982544,949.766296 1082.688721,939.841553 
	C1080.885498,936.997803 1080.706421,933.135132 1078.943848,930.252380 
	C1073.624756,921.552307 1067.729492,913.205566 1062.370972,904.528259 
	C1057.498535,896.637939 1053.145386,888.428467 1048.353516,880.486328 
	C1044.888428,874.743164 1040.878052,869.326111 1037.470825,863.551392 
	C1032.148560,854.531067 1027.203125,845.289246 1021.946350,836.229309 
	C1020.044250,832.951111 1017.364502,830.103333 1015.626465,826.754578 
	C1008.910645,813.814575 1002.616211,800.648438 993.262390,789.276367 
	C992.239929,788.033264 991.760376,786.320618 991.125183,784.785950 
	C987.475586,775.968567 982.394836,768.060242 976.058228,760.302734 
	C976.052063,759.878906 976.045898,759.455078 976.039185,758.295044 
	C974.708740,756.160645 973.378357,754.026306 971.834045,751.486084 
	C971.641296,751.233826 971.392578,751.062988 971.051941,750.240784 
	C970.041565,748.816467 969.031189,747.392151 967.996521,745.996826 
	C967.996521,745.996826 968.032410,745.986511 968.031372,745.274597 
z"/>
<path fill="#020102" opacity="1.000000" stroke="none" 
	d="
M897.563538,606.208618 
	C897.943115,609.506714 898.322632,612.804810 898.337036,616.642212 
	C897.682617,622.408691 897.393433,627.635864 897.016113,633.288513 
	C896.620300,635.659119 896.052856,637.603333 896.045410,639.549683 
	C895.975647,657.804260 895.978149,676.059387 896.041748,694.314087 
	C896.047180,695.877075 896.642578,697.438049 896.973816,699.335571 
	C896.990784,700.118958 896.997803,700.566833 897.003784,701.379761 
	C897.919678,703.250427 898.609802,704.970825 899.795349,706.221863 
	C903.653015,710.292603 907.734009,714.150452 911.652771,718.164734 
	C923.266418,730.061951 929.424377,744.847839 933.916260,760.495239 
	C934.999390,764.268433 936.645752,768.036133 936.863525,771.872681 
	C937.545227,783.882446 937.584961,795.926880 938.028076,807.952271 
	C938.614868,823.879272 934.274780,838.867188 928.887268,853.511780 
	C926.557922,859.843445 922.783203,865.760864 918.988708,871.412231 
	C910.287048,884.372131 898.604431,894.406006 886.028687,903.452148 
	C885.239624,904.019714 884.369812,904.494019 883.491272,904.915222 
	C879.137817,907.002563 874.840027,909.227844 870.389954,911.086914 
	C862.029358,914.579651 854.693054,920.973450 844.735291,920.121948 
	C843.604004,920.025146 842.340271,921.476501 841.138000,922.210022 
	C842.309631,922.810181 843.612610,924.094177 844.631409,923.898499 
	C850.895386,922.695801 857.614014,922.221375 863.199646,919.494019 
	C872.517578,914.944153 883.018677,912.682007 890.786804,904.816162 
	C897.075989,898.447815 904.670349,893.386169 911.162109,887.195862 
	C921.484924,877.352356 930.257812,866.289612 934.312988,852.247925 
	C936.603333,844.317322 938.611267,836.305237 940.923340,827.668884 
	C942.218567,828.513062 943.586060,828.943542 944.182129,829.870239 
	C947.205200,834.570251 950.006531,839.412292 952.927734,844.178711 
	C955.569641,848.489502 958.265869,852.767029 960.960083,857.425598 
	C963.587219,864.044189 965.920471,870.427429 968.855713,876.520691 
	C974.484131,888.204712 976.315613,900.659180 974.702087,913.181702 
	C973.719055,920.811157 970.614563,928.359863 963.994385,933.766296 
	C959.969666,937.053101 956.609558,941.153687 952.543823,944.924561 
	C949.739380,945.493530 947.206604,945.697815 944.969666,946.619995 
	C937.461914,949.714783 929.974365,952.655640 921.608276,952.091064 
	C920.763733,952.034058 919.867249,952.747681 918.578857,953.096802 
	C916.788635,953.196228 915.414429,953.302612 913.579285,953.289551 
	C903.458130,953.138184 893.797913,953.079224 884.137695,953.078735 
	C756.936829,953.072571 629.736023,953.068542 502.535156,953.077698 
	C457.061279,953.080994 411.587402,953.121033 365.651398,953.086426 
	C361.957001,952.721741 358.724670,952.173157 355.492432,952.173523 
	C351.994110,952.174011 348.495850,952.686646 344.618622,952.979187 
	C343.482300,953.013977 342.724884,953.045349 342.000793,953.038696 
	C342.034058,953.000610 342.062317,952.903381 341.995087,952.573975 
	C340.284790,951.824890 338.641724,951.405212 336.679199,950.971802 
	C335.935150,950.930908 335.510590,950.903748 335.029236,950.535217 
	C332.264313,948.162354 329.800903,945.607361 326.799042,944.204285 
	C319.698944,940.885559 314.226288,936.091858 310.459320,929.212097 
	C308.169617,925.030334 305.581940,921.011658 303.106262,916.527832 
	C303.094879,915.089783 303.105408,914.043640 303.372620,912.742554 
	C303.457977,910.658691 303.286621,908.829773 303.101349,906.680115 
	C303.100006,905.931824 303.112579,905.504211 303.494324,904.963074 
	C304.422333,902.192078 304.789642,899.476379 305.576202,896.888123 
	C307.904724,889.225708 310.899963,881.728577 312.684662,873.948914 
	C314.413696,866.411987 317.599884,859.690918 321.492310,853.189758 
	C333.241241,833.566589 345.083130,813.998901 356.768188,794.337830 
	C358.503906,791.417297 359.589386,788.110168 360.987854,784.990967 
	C361.000000,785.000000 360.980560,784.976624 361.322937,784.941040 
	C362.067322,784.631226 362.627991,784.448853 362.847595,784.069214 
	C366.118683,778.413452 369.278564,772.692749 372.597961,767.065918 
	C376.700409,760.111694 380.956665,753.248291 385.088623,746.311218 
	C387.856964,741.663513 390.455627,736.914185 393.252594,732.284363 
	C398.444458,723.690063 403.760101,715.170654 408.960785,706.581665 
	C414.525635,697.391296 419.980347,688.134155 425.552979,678.948547 
	C429.561188,672.341614 433.826111,665.887207 437.721802,659.216003 
	C443.274170,649.707764 448.450378,639.978516 454.067383,630.509888 
	C457.258728,625.130249 461.396515,620.295959 464.411743,614.831848 
	C468.506744,607.410950 470.730774,598.914856 477.415192,593.026917 
	C478.015778,592.497986 478.453949,591.733887 478.828766,591.007385 
	C482.055298,584.753601 484.982788,578.328247 488.512695,572.252197 
	C494.823273,561.389526 501.491333,550.734497 508.387024,539.949524 
	C512.661011,538.687378 516.615417,537.623474 520.438965,536.206482 
	C524.372620,534.748657 528.107788,531.705627 532.147034,536.075012 
	C533.993958,529.361328 533.942322,529.462830 540.265198,532.677246 
	C541.375977,533.241943 543.868347,533.113220 544.443726,532.345764 
	C546.578796,529.498230 550.983215,528.136353 550.383362,523.214050 
	C550.277832,522.348206 553.383972,520.285645 555.113464,520.151550 
	C560.407898,519.741150 565.755066,519.944275 571.080017,520.022644 
	C573.876038,520.063782 575.603699,519.594177 576.262207,516.171936 
	C576.578979,514.525818 579.385010,512.336670 581.221924,512.173401 
	C591.975586,511.217896 591.991333,511.395569 591.999878,500.494598 
	C592.000183,500.161713 591.998657,499.828827 592.000183,499.495941 
	C592.025879,493.978607 592.680298,493.459473 597.946899,494.006195 
	C605.140198,494.752899 612.398376,495.038910 619.524292,496.176453 
	C621.827637,496.544189 623.856750,498.901337 625.907776,500.482697 
	C627.273560,501.535767 628.526306,502.761353 629.703064,504.027313 
	C631.199646,505.637329 633.689697,507.375244 633.676331,509.041321 
	C633.662964,510.703369 631.082336,512.342163 629.632996,513.996155 
	C628.418823,515.381653 626.240967,516.725220 626.167725,518.172302 
	C625.806091,525.311096 625.810059,532.487427 626.158264,539.627502 
	C626.231628,541.132996 628.081787,543.506287 629.473572,543.805786 
	C632.973572,544.559082 633.201416,546.165222 632.510376,549.153503 
	C631.448853,553.744019 630.325562,558.410828 630.110046,563.085938 
	C629.749207,570.911438 633.660034,575.035706 641.452209,575.993347 
	C643.922241,576.296875 646.173279,576.714600 645.523071,579.982788 
	C611.705078,579.995422 578.354736,580.002563 544.800171,579.757507 
	C539.556030,579.850220 539.941589,583.525024 539.747070,587.184570 
	C538.996948,588.247681 538.497253,589.123840 537.612305,590.006104 
	C533.743652,590.117126 533.995667,592.638672 533.632202,595.006409 
	C531.842957,597.007690 530.421448,599.002625 529.000000,600.998779 
	C529.000000,601.000000 528.997559,601.000000 528.647095,601.035278 
	C522.755188,604.595825 520.679077,610.507935 517.868652,616.322998 
	C514.906860,624.145081 507.632751,628.123840 503.805328,635.399902 
	C497.719330,646.969604 488.455719,656.872925 480.494781,667.449890 
	C473.659302,676.531494 466.824219,685.614746 459.863037,694.599609 
	C457.750854,697.325867 455.005646,699.565613 452.927521,702.313354 
	C449.376709,707.008179 446.226715,712.004700 442.713898,716.730042 
	C439.768005,720.692749 434.886566,724.043335 433.822754,728.389587 
	C432.112640,735.376160 425.864807,738.306580 422.779877,744.081909 
	C418.386536,752.306763 410.970337,758.872864 405.186462,766.409363 
	C396.482269,777.750977 388.141144,789.370667 379.503815,800.764648 
	C376.199280,805.123840 372.596588,809.270508 368.928131,813.331970 
	C367.889496,814.481812 365.982361,814.798401 364.740082,815.820984 
	C356.700409,822.438782 348.643890,829.042236 340.796265,835.883789 
	C338.237610,838.114441 335.606689,840.768372 334.391571,843.785583 
	C333.903015,844.998779 337.132935,847.707458 338.663879,849.740540 
	C338.760437,849.868713 338.763214,850.074341 338.869781,850.187012 
	C345.173615,856.861084 351.260925,863.760376 357.864532,870.123840 
	C363.049835,875.120544 369.080048,879.231750 374.369446,884.130859 
	C384.147003,893.187012 393.561249,902.635742 403.350952,911.678345 
	C405.568512,913.726685 408.179810,916.769409 412.443970,914.043457 
	C511.908569,914.336548 610.934082,914.640137 710.361389,914.961914 
	C714.269775,915.118958 716.032349,913.466003 716.018921,909.924500 
	C716.000671,905.091492 716.014343,900.258423 716.014343,894.431458 
	C718.762451,895.625977 720.624390,896.200867 722.242554,897.179077 
	C729.415039,901.514771 736.431458,906.115906 743.693054,910.293152 
	C746.177673,911.722351 749.464844,911.757629 751.946960,913.189636 
	C757.743713,916.534058 763.305603,920.031433 770.344604,920.420654 
	C772.765320,920.554504 775.201416,922.195190 777.437805,923.482239 
	C781.291931,925.700195 785.970825,925.058777 789.030396,921.185486 
	C787.401611,920.533447 785.979614,919.735962 784.455750,919.398438 
	C780.410706,918.502380 776.126282,918.336121 772.288574,916.924072 
	C762.659424,913.381165 752.795349,910.100281 743.831726,905.230347 
	C734.787720,900.316772 726.735657,893.585449 718.189880,887.736938 
	C717.059509,886.963379 715.580078,886.699707 714.262329,886.199829 
	C713.838318,887.516418 713.079895,888.825012 713.048401,890.150940 
	C712.914246,895.805298 712.957703,901.465149 713.016235,907.122253 
	C713.038879,909.306763 712.943787,911.233948 709.515320,911.018494 
	C610.365173,911.010559 511.682587,911.005249 412.769958,910.729004 
	C408.808136,908.360046 403.587097,907.116516 401.757324,903.927307 
	C399.873169,900.643311 397.270874,900.021667 394.840851,898.371765 
	C393.778717,897.650635 392.845490,896.719116 391.928680,895.807800 
	C382.580231,886.516174 373.262695,877.193420 363.895264,867.920898 
	C362.282043,866.324097 360.404785,864.995850 358.770416,863.418213 
	C353.893860,858.711121 349.084869,853.934082 344.233307,849.200928 
	C342.699005,847.704102 341.113831,846.259399 339.234100,844.492004 
	C348.510986,836.807983 357.094330,829.815613 365.537079,822.657410 
	C369.961823,818.905945 374.694275,815.312927 378.308289,810.854309 
	C384.820312,802.820374 390.609222,794.198975 396.666138,785.798401 
	C397.708191,784.353149 398.402313,782.628723 399.566010,781.304749 
	C403.437744,776.899780 407.915131,772.952576 411.314301,768.222046 
	C418.954163,757.589905 425.930359,746.482666 433.496979,735.795471 
	C436.756866,731.191284 441.083099,727.350891 444.445496,722.808594 
	C450.673126,714.395874 455.645721,704.821045 462.935028,697.521729 
	C466.813385,693.638062 469.528748,689.220154 472.794739,685.126526 
	C482.168762,673.376892 491.114746,661.283813 500.147675,649.264709 
	C505.662964,641.926147 511.061554,634.499023 516.451233,627.067139 
	C521.550964,620.035095 526.333374,612.758545 531.696533,605.936401 
	C536.438599,599.904419 542.873108,594.951172 546.510986,588.386047 
	C549.202148,583.529419 552.235474,584.014099 556.003052,584.011658 
	C586.091797,583.992371 616.181580,583.892456 646.267944,584.162781 
	C649.559570,584.192383 653.173218,585.875488 656.036682,587.722900 
	C660.945129,590.889648 665.222900,595.019897 670.052246,598.326904 
	C675.887268,602.322510 681.890137,606.120911 688.088989,609.513794 
	C690.591125,610.883362 693.876709,611.714966 696.678650,611.449707 
	C701.863281,610.958740 704.834961,606.671570 708.365601,603.046021 
	C709.128052,603.033203 709.563660,603.026428 710.189209,603.258911 
	C711.247559,602.662354 712.115967,601.826599 712.992188,600.995483 
	C713.000000,601.000000 712.991272,600.984436 713.311768,600.951172 
	C714.099915,600.292969 714.567444,599.667969 715.429443,599.030151 
	C720.062500,597.784485 724.854492,597.850525 727.753601,593.509766 
	C728.165283,592.893372 729.864868,593.137085 731.291565,592.950623 
	C732.078125,592.288635 732.541504,591.658813 733.399902,591.016235 
	C734.849060,590.993958 735.903137,590.984314 737.314392,590.979370 
	C738.449219,590.331726 739.226746,589.679504 740.373657,589.015503 
	C741.482117,588.994995 742.221130,588.986145 743.333984,588.985107 
	C745.470459,587.998962 747.233276,587.004944 749.464966,586.006592 
	C761.607422,586.325928 773.281006,586.649536 785.339722,587.024170 
	C790.808228,588.058411 795.891602,589.041626 800.996216,590.419678 
	C803.003906,591.550903 804.990356,592.287109 806.981812,593.399048 
	C806.851074,594.697388 806.715393,595.619995 806.555115,596.709961 
	C803.059448,595.669128 799.873657,594.534485 796.593933,593.850403 
	C795.522644,593.626953 794.206604,594.576904 792.668457,594.995728 
	C791.888916,594.990112 791.443604,594.979004 790.956177,594.614075 
	C789.788269,591.700745 788.175659,591.155457 785.503052,593.061646 
	C777.362488,593.051819 769.688599,593.045959 761.734985,592.739563 
	C755.621826,592.686707 749.736572,592.571533 743.985046,593.392700 
	C742.143188,593.655640 740.654907,596.395691 738.610962,598.021545 
	C736.146912,598.366211 734.076904,598.691833 731.782471,598.744995 
	C727.540527,597.302856 726.073120,599.847656 724.663757,603.017822 
	C723.235413,603.386475 722.147339,603.709595 720.691406,604.055603 
	C716.593689,605.980774 711.754822,606.575195 711.001221,611.993896 
	C711.002441,612.002441 710.985352,612.000000 710.604736,612.000244 
	C708.490234,613.346863 706.756409,614.693176 704.822266,616.300171 
	C703.772827,616.720276 702.923706,616.879822 701.718628,617.057251 
	C698.250549,621.066040 695.138245,625.056885 691.988098,629.408569 
	C691.233765,631.415466 690.914368,633.457275 689.734375,634.641724 
	C683.536926,640.862549 681.507568,648.601562 680.608643,657.069702 
	C679.846741,659.450012 679.464233,661.744080 679.063782,664.492188 
	C679.060974,668.283691 679.076111,671.621094 679.061707,675.356934 
	C679.676331,677.181213 680.320557,678.607178 680.969116,680.447266 
	C680.994751,682.241821 681.016052,683.622253 680.803589,685.166748 
	C681.033752,685.901794 681.497742,686.472839 682.180908,687.069824 
	C682.400146,687.095825 682.839233,687.140747 682.860413,687.495850 
	C682.887512,688.562195 682.893433,689.273376 682.643555,690.217590 
	C682.601135,692.301086 682.814636,694.151672 682.796509,696.145447 
	C682.730957,696.856323 682.897095,697.423889 683.057739,698.320312 
	C683.062622,699.087646 683.072937,699.526001 683.062012,700.321045 
	C683.699036,701.443909 684.357300,702.210266 685.021729,703.308472 
	C685.043335,704.082581 685.058716,704.524780 685.055054,705.334839 
	C686.352600,707.472778 687.669128,709.242920 688.995117,711.394836 
	C690.677368,714.836487 692.350098,717.896484 694.264038,720.972900 
	C694.505249,720.989319 694.988464,721.007324 694.996948,721.381897 
	C697.186401,730.325867 704.076233,735.171753 710.425415,740.027588 
	C718.891113,746.502014 728.004333,752.147156 736.973999,757.940247 
	C745.219116,763.265503 753.676208,768.261536 761.966736,773.518127 
	C764.463257,775.101135 766.460815,777.907349 769.123352,778.715576 
	C779.709534,781.928955 784.517578,789.667358 787.006348,799.635864 
	C787.309204,800.848999 788.316650,801.886169 788.996216,803.378906 
	C789.002197,804.501160 789.009583,805.249329 788.740479,806.221558 
	C785.658813,811.984985 782.853638,817.524414 779.892456,823.355103 
	C777.842712,825.115540 775.948975,826.584778 773.644775,828.035767 
	C765.580444,831.799561 758.197021,829.517639 750.840698,826.985840 
	C747.188232,825.728760 743.568848,824.375671 739.577759,823.021179 
	C735.826538,821.300293 732.432068,819.624207 729.025208,817.582886 
	C724.695435,812.824829 720.441101,808.367859 716.046753,804.053528 
	C708.601807,796.744324 700.945129,789.647217 693.606567,782.234192 
	C686.125122,774.676697 679.091248,766.669312 671.469604,759.262146 
	C669.628723,757.473083 664.929382,755.739563 663.847534,756.666992 
	C661.715576,758.494507 660.491943,762.131897 660.185974,765.151733 
	C659.792786,769.032898 660.986389,773.046692 660.940063,776.996826 
	C660.827271,786.617981 660.165100,796.235168 660.155518,805.854370 
	C660.146484,814.889160 660.727905,823.923584 660.961182,832.960449 
	C661.012207,834.935059 660.533813,836.922302 660.571594,838.898499 
	C660.640259,842.493408 660.268982,846.295410 661.350159,849.595154 
	C661.966553,851.476501 665.335022,853.959778 667.037048,853.669250 
	C670.598328,853.061279 674.713501,851.577393 677.084167,849.037354 
	C681.105591,844.728638 685.771851,843.166382 691.192139,841.814026 
	C698.655029,839.952087 704.784668,842.198547 710.637756,846.172180 
	C712.634521,847.527771 714.032471,849.735229 715.940430,851.256958 
	C723.321533,857.143982 730.667297,863.090820 738.294922,868.646362 
	C742.026367,871.364136 745.073975,876.299255 751.304260,874.098022 
	C752.060303,874.403503 752.523865,874.709229 753.025635,875.373047 
	C754.601562,879.518677 757.683044,879.554199 761.328613,879.002319 
	C762.452148,878.803101 763.254456,878.601318 764.380798,878.367310 
	C765.721619,878.661011 766.738342,878.986877 767.811523,879.586182 
	C768.229309,880.265625 768.590698,880.671692 769.027100,881.435974 
	C773.900269,885.316284 779.349060,885.652161 785.360474,885.032593 
	C786.465759,885.057556 787.202209,885.088989 788.056519,885.501221 
	C788.696350,886.229370 789.214539,886.872437 789.740967,886.879211 
	C796.353882,886.964966 802.969360,887.025085 809.580200,886.892822 
	C810.415710,886.876099 811.228577,885.726379 812.462769,885.072144 
	C814.247314,885.029419 815.621094,885.016052 817.254822,885.288574 
	C822.526917,885.121643 827.900513,886.145081 832.242188,882.002258 
	C832.470154,881.980835 832.925537,881.931702 833.341614,881.951416 
	C850.838928,879.326599 862.332642,868.310059 872.082581,855.372925 
	C876.695557,849.251770 879.815674,841.958557 883.282715,835.031555 
	C888.653625,824.300415 891.710144,812.948669 892.030762,800.853271 
	C892.410217,786.537292 887.734375,773.290649 883.733704,759.903992 
	C883.049072,757.613281 881.314941,755.634338 880.054260,753.518127 
	C876.624146,747.759949 873.450867,741.824829 869.663513,736.311768 
	C867.053894,732.513000 862.717529,729.735168 860.720215,725.721191 
	C857.963501,720.181030 853.487671,717.553772 848.411560,715.174377 
	C846.482300,714.269958 844.543640,713.342041 842.746887,712.206970 
	C837.740234,709.043884 832.776062,705.811035 827.851685,702.520935 
	C823.676697,699.731628 819.720337,696.583557 815.390320,694.071350 
	C813.248657,692.828796 810.483093,692.661682 807.654053,691.970642 
	C806.208008,691.619080 805.107605,691.304810 803.968384,690.773926 
	C803.929504,690.557373 803.937500,690.117371 804.003723,689.764893 
	C803.637268,687.704773 803.659302,685.570923 802.604980,684.445801 
	C801.950073,683.746887 799.663940,684.576477 797.802124,684.738525 
	C796.719788,684.109741 795.945251,683.462524 795.167725,682.464417 
	C795.111511,681.409729 795.058167,680.705933 795.007568,679.631165 
	C794.345337,675.982544 792.549561,673.994324 789.004150,673.998291 
	C789.000000,674.000000 789.003479,673.991760 789.010132,673.601440 
	C789.208313,670.376282 788.064331,668.792297 784.737488,668.803223 
	C783.265259,666.097412 782.097717,663.555481 781.202087,660.825195 
	C780.968750,659.765930 780.463501,658.895081 779.933960,657.792969 
	C779.909546,657.561829 779.816162,657.106506 779.865906,656.661865 
	C779.806396,653.903564 779.527344,651.583069 779.620117,649.277466 
	C779.882690,642.755554 780.275879,635.600037 786.081543,631.816711 
	C798.897888,623.464722 814.132874,624.631104 827.059021,633.279541 
	C831.165955,636.027466 836.408447,637.078186 841.249878,639.166626 
	C841.942688,639.909119 842.516846,640.390625 843.091919,641.226318 
	C844.020325,642.690247 844.817749,643.950195 845.896973,644.884583 
	C851.533325,649.764099 857.141296,654.687195 862.981689,659.314270 
	C864.878052,660.816772 867.447449,661.469910 869.708984,662.511536 
	C870.797058,660.097656 872.819092,657.683594 872.818481,655.270020 
	C872.815247,641.726990 872.216492,628.185303 872.059631,614.640503 
	C872.006104,610.020569 873.217896,605.333069 872.745422,600.791504 
	C872.475525,598.196411 870.481628,594.999573 868.294495,593.677429 
	C866.978333,592.881836 863.734009,595.033447 861.478943,596.116150 
	C859.892212,596.877869 858.512024,598.069824 856.845581,598.783875 
	C856.104004,597.342651 855.556396,596.187256 855.212769,594.748047 
	C856.593628,593.939819 857.867493,593.562866 858.930298,592.864563 
	C863.098083,590.126221 869.031128,589.775879 871.357788,584.020386 
	C874.801880,581.327942 877.878418,578.644226 880.962463,575.963318 
	C880.970032,575.966187 880.958740,575.950562 881.313110,575.931763 
	C883.746765,574.158508 885.958069,572.535828 887.872925,570.617249 
	C890.445496,568.039917 892.700562,565.061401 897.015686,566.453979 
	C897.405945,572.283997 897.784790,577.645813 898.163574,583.007690 
	C898.138611,588.693298 898.113708,594.378906 897.807739,600.766357 
	C897.538940,603.048279 897.551270,604.628479 897.563538,606.208618 
M632.975586,784.500000 
	C632.972046,724.172363 632.969177,663.844788 632.964233,603.517151 
	C632.963806,597.839172 629.715820,594.356079 624.068665,594.034424 
	C622.574097,593.949280 621.069885,594.040222 619.570068,594.040466 
	C606.484741,594.042603 593.399292,594.018982 580.314087,594.059448 
	C576.994568,594.069702 573.676514,594.334473 570.356323,594.386108 
	C567.080505,594.437134 563.777832,594.106873 560.532654,594.419434 
	C557.044556,594.755310 553.515808,595.337341 550.172607,596.360657 
	C544.419983,598.121460 542.256042,603.571899 539.054749,607.951355 
	C534.028625,614.827148 528.840820,621.584595 523.789978,628.442627 
	C518.124878,636.134766 512.664429,643.980896 506.872589,651.575195 
	C502.358948,657.493591 497.211182,662.935791 492.809387,668.930481 
	C483.847992,681.134888 475.365845,693.691833 466.373779,705.872864 
	C458.279327,716.837891 449.642883,727.401672 441.478638,738.316711 
	C434.852203,747.175842 428.688812,756.380554 422.101685,765.270203 
	C416.394165,772.972778 410.402832,780.466003 404.495483,788.019287 
	C397.665833,796.751770 390.785309,805.444458 383.936798,814.162231 
	C376.610931,823.487671 369.220459,832.764648 362.080566,842.230652 
	C361.254242,843.326111 361.138184,846.352356 361.919434,846.997070 
	C364.045593,848.751648 366.815125,850.779419 369.355286,850.814026 
	C391.015900,851.108704 412.682281,851.001648 434.346863,850.974976 
	C441.471497,850.966248 443.363892,847.840149 442.544983,840.796997 
	C440.940155,826.994629 437.083710,812.791016 446.890320,800.115173 
	C447.981781,798.704407 448.491638,796.859009 449.441925,795.320618 
	C451.921021,791.307190 454.677246,787.101257 459.900818,787.067505 
	C487.230438,786.890747 514.563904,786.834106 541.888794,787.233154 
	C544.274048,787.268005 548.449829,790.749207 548.632690,792.898499 
	C549.290710,800.631714 548.762878,808.489380 548.343872,816.281799 
	C548.093018,820.947632 546.460266,825.072327 543.295593,828.889099 
	C540.793091,831.907349 539.910583,836.211914 537.768311,839.604065 
	C536.123596,842.208435 534.355835,844.236206 535.774231,847.709900 
	C537.214905,851.238098 539.815613,851.006592 542.690063,851.004089 
	C573.687134,850.976807 604.684204,850.987244 635.681274,850.985962 
	C636.681091,850.985901 637.936279,851.353943 638.634460,850.890503 
	C641.056274,849.282837 643.411194,847.512695 645.477173,845.484192 
	C645.878601,845.090027 645.153931,843.038940 644.456665,842.129211 
	C642.157593,839.129272 639.540222,836.374329 637.217834,833.390869 
	C634.172913,829.479126 632.716187,825.170715 632.869812,819.993530 
	C633.210632,808.504761 632.975769,796.998901 632.975586,784.500000 
M794.609924,922.769958 
	C794.189636,923.537476 793.769348,924.304993 793.348999,925.072449 
	C795.329346,925.629883 797.356323,926.744751 799.276672,926.584229 
	C801.109680,926.431091 802.831604,924.949402 804.602417,924.051697 
	C802.813782,923.374878 801.069519,922.490295 799.220642,922.094727 
	C798.004089,921.834412 796.636719,922.278809 794.609924,922.769958 
M834.997375,925.970581 
	C835.472778,925.057678 835.948181,924.144775 836.423645,923.231934 
	C834.260559,922.834290 832.087158,922.110474 829.938904,922.178894 
	C828.891113,922.212280 827.890320,923.723206 826.868286,924.565369 
	C827.057556,925.046021 827.246826,925.526611 827.436096,926.007263 
	C829.672363,926.007263 831.908569,926.007263 834.997375,925.970581 
M525.283630,539.971436 
	C523.969788,539.929565 522.655945,539.887695 521.342102,539.845825 
	C521.625244,540.712952 521.908447,541.580078 522.191589,542.447205 
	C523.343445,541.830505 524.495300,541.213806 525.283630,539.971436 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M656.068970,224.906708 
	C656.068970,224.906708 656.544800,224.964523 656.773254,225.379486 
	C657.501282,227.521576 657.695007,229.420029 658.550537,230.947815 
	C663.770752,240.269882 669.030029,249.574524 674.525757,258.735291 
	C676.304993,261.701080 678.835754,264.216003 681.022766,266.937134 
	C681.022766,266.937134 681.020935,266.979584 681.021240,267.372803 
	C682.398865,275.545624 687.090210,281.162964 692.947449,286.355988 
	C692.968628,287.124176 692.978577,287.563293 692.742676,288.202332 
	C696.148071,295.245819 699.772766,302.103882 703.484192,308.914642 
	C703.943115,309.756744 704.996704,310.256897 705.549072,311.068939 
	C708.402954,315.264374 711.481140,319.346222 713.915100,323.778473 
	C717.063721,329.511993 719.314636,335.751740 722.613220,341.386414 
	C726.803345,348.543884 731.918457,355.157074 736.164429,362.285126 
	C743.145996,374.005310 749.732361,385.960571 756.528320,397.791992 
	C757.750854,399.920288 759.216309,401.907471 760.508484,403.997528 
	C764.648987,410.694305 768.863708,417.348328 772.850098,424.136108 
	C775.901123,429.331360 778.490845,434.798676 781.569519,439.976166 
	C786.243103,447.835876 791.112976,455.580841 796.010254,463.304291 
	C797.379028,465.463043 799.149109,467.365814 800.712341,469.403442 
	C801.709595,470.703339 802.886841,471.925781 803.601013,473.371704 
	C806.850220,479.950256 809.240784,487.083221 813.297668,493.101685 
	C819.210022,501.872833 825.486877,510.276855 829.861450,520.058044 
	C832.979126,527.028687 837.443359,533.405273 841.404968,539.987793 
	C845.039734,546.027283 848.852478,551.959412 852.497192,557.993042 
	C854.725281,561.681580 856.084717,566.213318 859.082947,569.043457 
	C863.582336,573.290527 864.871948,578.465088 865.839355,584.073547 
	C865.406982,584.462646 865.202271,584.731323 864.606201,584.992859 
	C858.633972,587.759033 853.023621,590.474792 847.478455,593.317810 
	C836.721619,598.832947 826.226685,599.035156 816.009460,591.618652 
	C813.590820,587.591370 811.235596,583.897705 808.745300,580.297485 
	C807.716919,578.810669 806.066956,577.692078 805.269775,576.117432 
	C801.697144,569.060547 798.495728,561.811951 794.797180,554.824768 
	C793.523621,552.418762 790.770996,550.808899 789.432739,548.421326 
	C786.208862,542.669800 783.564270,536.596436 780.427368,530.792786 
	C774.487488,519.803101 768.896667,508.568512 762.128845,498.103027 
	C757.798584,491.406952 753.170227,484.961334 749.804382,477.690826 
	C747.270874,472.218201 745.084900,466.476166 741.768066,461.505371 
	C738.481567,456.579895 733.972717,452.470032 729.991089,447.632538 
	C728.973022,444.854492 727.963989,442.446411 726.965759,439.652222 
	C726.712402,438.317963 726.617004,437.277069 726.155518,436.437317 
	C723.876709,432.290039 721.400330,428.249451 719.182007,424.071228 
	C715.430176,417.004547 712.171204,409.651611 708.059753,402.806641 
	C703.103577,394.555298 697.461853,386.716431 692.146179,378.679718 
	C691.056030,377.031555 690.113281,375.286621 689.071289,373.605682 
	C684.984863,367.013214 680.960327,360.380341 676.747803,353.869232 
	C675.423096,351.821655 673.871826,349.703186 671.927795,348.309967 
	C661.569458,340.886536 649.645630,338.497406 637.357605,340.037842 
	C631.575745,340.762604 626.185913,344.470825 620.567749,346.699249 
	C617.149780,348.054962 615.570923,350.365692 616.028076,354.019257 
	C616.006165,354.004700 616.034973,354.048553 615.662659,354.069580 
	C608.934998,355.742096 606.272217,361.538483 602.738708,366.022614 
	C599.857605,369.678711 598.894348,374.782318 596.386597,378.812103 
	C593.065979,384.148041 588.832886,388.911804 585.440674,394.208801 
	C582.927063,398.133942 581.147583,402.526855 579.003235,406.691925 
	C577.992798,408.654633 577.037048,410.669678 575.796997,412.483582 
	C574.339600,414.615326 572.300049,416.383423 571.034912,418.606262 
	C565.141418,428.961060 559.603027,439.520416 553.602844,449.811401 
	C547.212585,460.771301 540.327026,471.441650 533.885925,482.372894 
	C529.535034,489.756836 525.592896,497.381195 521.424622,504.873962 
	C521.030640,505.582062 520.149597,506.030884 519.797363,506.748627 
	C517.804565,510.808746 515.904114,514.914124 513.868042,519.217651 
	C513.569763,519.696716 513.316101,519.879639 512.651550,520.020508 
	C507.506195,523.883423 504.890503,528.774902 504.702026,535.245789 
	C503.940460,538.249451 503.815308,541.022400 499.884216,541.486145 
	C498.663300,541.630188 497.433929,543.668091 496.634430,545.080200 
	C493.018036,551.467773 489.637360,557.989380 485.992645,564.360229 
	C484.618683,566.761780 482.526489,568.754517 481.170013,571.163513 
	C477.941833,576.896667 475.392853,583.056335 471.769012,588.512024 
	C466.702515,596.139709 461.116669,603.366760 457.401489,611.872375 
	C456.061462,614.940247 452.834015,617.127319 451.060974,620.073303 
	C446.019348,628.450134 443.254517,638.144775 436.347412,645.462158 
	C435.803314,646.038635 435.650146,646.966614 435.232513,647.682922 
	C432.134766,652.996399 429.083374,658.338562 425.887726,663.592773 
	C421.421021,670.937012 416.702942,678.131531 412.363434,685.548645 
	C409.812103,689.909485 407.879944,694.628052 405.484924,699.086670 
	C404.236969,701.409912 402.470795,703.449646 401.143829,705.736450 
	C396.117340,714.398926 394.259857,724.971985 385.602997,731.449829 
	C385.367249,731.626282 385.301575,732.024414 385.144806,732.312805 
	C381.633728,738.770447 378.198364,745.270996 374.572021,751.663330 
	C373.030945,754.379822 370.925781,756.774658 369.363403,759.481079 
	C366.110870,765.115234 363.164673,770.927979 359.849701,776.523499 
	C358.607544,778.620178 356.338013,780.118286 355.145844,782.232605 
	C351.502228,788.694397 348.294952,795.402100 344.656921,801.867249 
	C339.882599,810.351746 334.952728,818.752502 329.916504,827.084595 
	C325.084869,835.078064 319.970276,842.900269 315.107758,850.875549 
	C313.256439,853.911926 311.695496,857.133118 310.124542,860.330139 
	C308.983490,862.652161 308.089050,865.094177 306.994690,867.440735 
	C306.448486,868.611938 305.345734,869.616638 305.100037,870.820190 
	C303.063538,880.795410 300.458221,890.729126 299.459625,900.817444 
	C298.686005,908.632751 299.199158,916.794312 300.615082,924.531982 
	C301.589111,929.854736 305.001526,934.758728 307.516022,939.753235 
	C308.159637,941.031677 309.341431,942.302917 310.598480,942.965942 
	C319.017242,947.406311 327.523254,951.681213 336.041321,956.379395 
	C336.890442,961.795715 340.092712,963.466614 344.847778,963.005615 
	C348.563446,962.645325 352.283936,962.334717 355.783417,962.290771 
	C349.026520,966.323730 342.116821,966.025391 335.459808,963.512024 
	C333.180573,962.651489 332.081024,958.990906 329.820251,957.695557 
	C323.341309,953.983276 316.698730,950.449097 309.802002,947.621765 
	C306.641876,946.326355 305.395508,945.310425 304.725555,941.701172 
	C303.883698,937.165894 301.082611,932.994202 299.126984,928.665649 
	C298.497528,928.832275 297.868073,928.998901 297.238617,929.165527 
	C295.329132,932.879944 291.765167,936.016113 294.073792,941.158997 
	C294.529449,942.173950 294.414307,943.800293 293.910522,944.823669 
	C292.459778,947.770752 290.803741,950.640015 288.968933,953.365173 
	C288.032623,954.756042 286.599487,956.646362 285.284332,956.735352 
	C283.670380,956.844543 281.535706,955.679321 280.364532,954.392822 
	C278.647369,952.506470 277.585266,950.023804 276.091766,947.542297 
	C281.246857,945.763123 282.375671,941.995850 282.058990,937.188782 
	C281.772186,932.835815 283.111786,928.002380 276.597015,926.499146 
	C279.512451,924.961792 282.715179,924.318542 283.158051,922.845459 
	C284.550049,918.215698 286.390289,912.916321 285.334656,908.531555 
	C283.444153,900.678894 283.414246,893.303955 285.572723,885.698425 
	C285.928101,884.446289 286.332458,882.898193 285.914886,881.787292 
	C283.410065,875.123413 286.674744,870.404053 290.654144,865.641052 
	C291.690338,864.400818 291.550140,862.177734 291.950256,860.406006 
	C291.386841,860.237732 290.823456,860.069397 290.260040,859.901062 
	C286.545410,865.603210 282.164398,870.996216 279.329498,877.106506 
	C277.108978,881.892639 273.185791,881.338745 269.820923,881.731995 
	C266.455444,882.125305 266.250366,878.827026 265.789368,876.039795 
	C265.595978,874.870544 263.479736,873.185364 262.214966,873.165283 
	C255.107361,873.052429 248.376587,879.773010 247.629532,887.010559 
	C247.493118,888.332214 246.693192,890.165405 245.671326,890.651001 
	C244.666840,891.128418 242.833740,890.462219 241.662186,889.783508 
	C240.842926,889.308838 240.524872,887.969055 239.990265,887.028503 
	C235.170319,889.511536 235.090347,890.159058 238.645767,892.901550 
	C239.604736,893.641296 240.944550,894.950867 240.833466,895.839294 
	C240.181961,901.049744 239.282135,906.242126 238.135529,911.366577 
	C237.917343,912.341797 236.212448,913.664673 235.203812,913.655762 
	C232.105911,913.628601 228.824936,913.519531 226.005905,912.430603 
	C224.790665,911.961243 224.570541,908.991516 223.815857,907.192200 
	C223.658279,906.816467 223.137375,906.593140 222.657898,906.194824 
	C221.953918,906.774536 221.289581,907.259094 220.696976,907.819458 
	C215.362671,912.863403 215.112915,914.436462 217.385620,921.372742 
	C217.646255,922.168274 215.670670,924.701233 214.673981,924.735413 
	C209.550507,924.910645 208.544235,927.664978 209.226791,930.290039 
	C206.597321,933.855286 204.664429,936.298584 202.926437,938.873413 
	C200.164017,942.965698 197.651367,947.232422 194.759247,951.227173 
	C194.047470,952.210266 192.278610,952.427979 191.150192,952.685425 
	C192.449188,950.199707 193.472641,947.951294 194.761017,945.866638 
	C198.553513,939.730103 202.642181,933.767639 206.214630,927.508545 
	C207.665894,924.965881 208.083481,921.833374 209.132141,918.670288 
	C212.388245,914.211243 215.691757,910.187622 218.530396,905.859131 
	C222.818726,899.320190 226.973511,892.676941 230.832962,885.878052 
	C234.963425,878.601929 238.407303,870.929993 242.652283,863.726257 
	C248.856949,853.196777 255.614746,842.994751 261.927124,832.526733 
	C265.327972,826.886963 268.217316,820.941223 271.474945,815.211792 
	C274.637634,809.649353 278.006226,804.204529 281.229645,798.676208 
	C285.035278,792.149292 288.667725,785.518921 292.580444,779.057678 
	C299.063721,768.351562 305.768829,757.780029 312.272217,747.085938 
	C318.216614,737.311157 323.995483,727.435913 329.890045,717.630615 
	C333.288910,711.976685 336.845245,706.416504 340.193542,700.733276 
	C342.594177,696.658630 344.768433,692.450623 347.041779,688.301086 
	C351.008514,681.060547 354.788300,673.709351 358.996307,666.611938 
	C362.601990,660.530457 366.842651,654.828186 370.536530,648.795349 
	C374.509949,642.306030 378.200073,635.642090 381.963165,629.025574 
	C384.382538,624.771545 386.535767,620.356750 389.119171,616.207581 
	C393.346344,609.418396 397.809875,602.775879 402.212433,596.096985 
	C403.304840,594.439697 404.737152,592.985657 405.687408,591.258911 
	C413.114777,577.762146 420.354706,564.161255 427.874725,550.716858 
	C432.534485,542.386108 437.693726,534.335754 442.416321,526.038696 
	C445.502533,520.616516 447.904694,514.792786 451.147339,509.474548 
	C457.883148,498.427338 466.280090,488.550262 471.482971,476.361176 
	C475.604584,466.705353 482.295105,458.143646 487.869110,449.111053 
	C492.056244,442.325775 496.401459,435.634979 500.462067,428.775330 
	C506.360931,418.810272 512.083191,408.740356 517.850281,398.697693 
	C523.256897,389.282776 528.809692,379.944885 533.947693,370.384766 
	C537.825073,363.170288 543.312744,357.062347 546.574707,349.311310 
	C549.761963,341.737732 555.270325,335.160370 559.495605,327.990082 
	C560.499268,326.286865 560.449036,323.962646 560.883545,321.924103 
	C560.883606,321.924103 560.947144,321.451569 561.329712,321.187256 
	C562.765503,320.203674 564.255066,319.725006 564.804688,318.728119 
	C570.630310,308.162079 576.290222,297.504852 582.042664,286.898132 
	C582.824158,285.457214 583.792419,284.101440 584.797180,282.801239 
	C586.685852,280.357117 588.964478,278.163757 590.547852,275.543945 
	C594.880676,268.375000 599.286438,261.213043 603.017700,253.726624 
	C608.256531,243.215637 616.195496,234.447632 621.043396,223.559906 
	C624.231567,216.399628 629.780212,210.199234 632.372253,202.482666 
	C632.695557,201.520157 634.017578,200.177460 634.802124,200.225708 
	C636.799133,200.348587 639.689514,200.520706 640.566040,201.804214 
	C644.700928,207.859299 648.209473,214.338120 652.104187,220.563904 
	C653.126770,222.198563 654.731567,223.468994 656.068970,224.906708 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M208.971405,918.969238 
	C208.083481,921.833374 207.665894,924.965881 206.214630,927.508545 
	C202.642181,933.767639 198.553513,939.730103 194.761017,945.866638 
	C193.472641,947.951294 192.449188,950.199707 190.883820,952.805115 
	C189.974701,953.824402 189.486450,954.411804 188.680267,955.157837 
	C187.574814,956.511597 186.705887,957.661865 186.015472,958.910767 
	C184.155350,962.275513 182.402435,965.699463 180.550171,969.068726 
	C177.437256,974.731262 174.275940,980.367249 171.156143,986.026123 
	C171.009018,986.292969 171.066803,986.672791 171.021606,987.333862 
	C171.011383,988.113098 171.008728,988.558350 170.695221,989.160278 
	C167.305862,993.601196 163.943314,997.718384 161.277618,1002.245850 
	C160.079880,1004.280029 159.223190,1007.688354 160.151688,1009.451721 
	C160.982498,1011.029602 164.470215,1011.228577 166.804443,1011.977417 
	C167.257797,1012.122864 167.797638,1011.998840 168.297592,1011.998840 
	C483.430542,1012.000854 798.563477,1012.002869 1113.696411,1012.004578 
	C1114.862915,1012.004578 1116.035034,1012.074280 1117.194946,1011.985962 
	C1121.689453,1011.643555 1123.028442,1008.949036 1120.567993,1005.256714 
	C1114.605713,996.309082 1108.511475,987.445496 1102.745850,978.372925 
	C1100.734375,975.207764 1099.841797,971.341492 1097.898315,968.121155 
	C1092.560791,959.276917 1086.967529,950.585754 1081.396118,941.884888 
	C1080.476318,940.448425 1079.155273,939.268860 1078.017090,937.599609 
	C1078.003296,936.485779 1077.991821,935.742859 1077.838623,934.695435 
	C1074.807251,931.255127 1071.917847,928.119324 1069.025635,924.570923 
	C1068.759644,922.388489 1068.981445,920.336426 1068.145508,918.899841 
	C1065.254517,913.931824 1061.988770,909.182190 1058.877563,904.341858 
	C1055.229004,898.665649 1051.386841,893.101318 1047.997070,887.274109 
	C1042.177490,877.270264 1036.643921,867.100281 1030.853271,856.675903 
	C1027.442017,850.599670 1025.978271,843.712280 1020.142578,839.553833 
	C1019.656067,839.207031 1019.440002,838.463196 1019.132263,837.885742 
	C1016.391052,832.742310 1013.741516,827.547668 1010.897217,822.462036 
	C1008.205078,817.648621 1005.393555,812.896057 1002.451843,808.231873 
	C1001.637268,806.940430 1000.171753,806.059509 998.957764,804.661987 
	C998.271301,803.222534 997.631958,802.111267 996.995117,800.616211 
	C996.209045,798.757812 995.544983,797.197571 994.602966,795.828491 
	C993.158630,793.729309 991.347473,791.870300 990.011169,789.711792 
	C988.136536,786.683838 986.852966,783.260132 984.785645,780.387817 
	C980.539490,774.488159 976.278015,768.687073 975.991821,761.007568 
	C976.017151,760.983459 976.068237,760.935669 976.068237,760.935669 
	C982.394836,768.060242 987.475586,775.968567 991.125183,784.785950 
	C991.760376,786.320618 992.239929,788.033264 993.262390,789.276367 
	C1002.616211,800.648438 1008.910645,813.814575 1015.626465,826.754578 
	C1017.364502,830.103333 1020.044250,832.951111 1021.946350,836.229309 
	C1027.203125,845.289246 1032.148560,854.531067 1037.470825,863.551392 
	C1040.878052,869.326111 1044.888428,874.743164 1048.353516,880.486328 
	C1053.145386,888.428467 1057.498535,896.637939 1062.370972,904.528259 
	C1067.729492,913.205566 1073.624756,921.552307 1078.943848,930.252380 
	C1080.706421,933.135132 1080.885498,936.997803 1082.688721,939.841553 
	C1088.982544,949.766296 1097.061523,958.834473 1101.686279,969.438782 
	C1104.465210,975.810730 1110.093384,980.304871 1111.475098,987.533997 
	C1112.199829,991.326172 1116.225708,994.461914 1118.666870,997.959717 
	C1120.609863,1000.743652 1122.651733,1003.500793 1124.218628,1006.495361 
	C1126.718140,1011.272522 1123.804810,1014.887817 1117.775879,1014.701782 
	C1111.971802,1014.522705 1106.170288,1014.071533 1100.367554,1014.070862 
	C789.140686,1014.035583 477.913757,1014.035339 166.686874,1014.031128 
	C164.687576,1014.031067 162.678757,1014.150635 160.690887,1013.998413 
	C155.891617,1013.631226 153.692978,1009.829956 155.767914,1005.492920 
	C157.691101,1001.473022 162.074585,998.617493 161.442123,993.355042 
	C161.386810,992.894836 162.119263,992.186646 162.646347,991.832581 
	C170.687943,986.430908 172.487137,976.965759 177.275803,969.451599 
	C181.045670,963.536072 184.929169,957.692200 188.642670,951.741882 
	C191.612076,946.983826 194.466675,942.150818 197.254288,937.283447 
	C200.672806,931.314636 203.968521,925.275452 207.878876,919.110107 
	C208.617447,918.958801 208.794418,918.964050 208.971405,918.969238 
z"/>
<path fill="#6E2F32" opacity="1.000000" stroke="none" 
	d="
M209.132111,918.670288 
	C208.794418,918.964050 208.617447,918.958801 208.175812,918.921021 
	C210.749191,913.601868 213.488129,908.259277 216.445007,903.040039 
	C223.588837,890.430664 230.746353,877.827209 238.078583,865.327087 
	C241.406311,859.654053 245.303909,854.316956 248.682312,848.671448 
	C251.958160,843.197327 254.815781,837.474731 258.004730,831.946411 
	C266.349335,817.480469 274.714874,803.025879 283.185577,788.633606 
	C290.674042,775.910156 298.423706,763.339783 305.852722,750.582275 
	C311.129974,741.519836 315.952209,732.192200 321.238464,723.135315 
	C322.472260,721.021484 325.243408,719.808350 326.490021,717.698059 
	C333.756775,705.396301 340.777771,692.949463 347.886353,680.554321 
	C353.292694,671.127441 358.662598,661.679321 364.128479,652.287048 
	C369.332092,643.345459 374.711304,634.505798 379.880859,625.544861 
	C385.252411,616.233826 390.345490,606.761108 395.777344,597.486145 
	C402.697510,585.669800 409.738464,573.921265 416.914551,562.258667 
	C421.554626,554.717468 426.779449,547.530212 431.284821,539.913757 
	C435.765869,532.338440 439.531158,524.343506 443.865448,516.676819 
	C452.364288,501.643677 460.997284,486.685669 469.672760,471.753510 
	C471.896545,467.925934 474.562927,464.358276 476.889465,460.587006 
	C480.504242,454.727539 483.905457,448.735138 487.573120,442.909912 
	C492.515076,435.060883 497.806274,427.427246 502.604523,419.493683 
	C506.528595,413.005402 509.881165,406.174194 513.613892,399.566650 
	C517.483093,392.717682 521.459839,385.928223 525.473572,379.162598 
	C528.961487,373.283264 532.562256,367.470795 536.121094,361.633606 
	C543.102844,350.182129 550.092896,338.735718 557.072083,327.282654 
	C558.128235,325.549530 559.144409,323.792053 560.531311,321.984985 
	C560.449036,323.962646 560.499268,326.286865 559.495605,327.990082 
	C555.270325,335.160370 549.761963,341.737732 546.574707,349.311310 
	C543.312744,357.062347 537.825073,363.170288 533.947693,370.384766 
	C528.809692,379.944885 523.256897,389.282776 517.850281,398.697693 
	C512.083191,408.740356 506.360931,418.810272 500.462067,428.775330 
	C496.401459,435.634979 492.056244,442.325775 487.869110,449.111053 
	C482.295105,458.143646 475.604584,466.705353 471.482971,476.361176 
	C466.280090,488.550262 457.883148,498.427338 451.147339,509.474548 
	C447.904694,514.792786 445.502533,520.616516 442.416321,526.038696 
	C437.693726,534.335754 432.534485,542.386108 427.874725,550.716858 
	C420.354706,564.161255 413.114777,577.762146 405.687408,591.258911 
	C404.737152,592.985657 403.304840,594.439697 402.212433,596.096985 
	C397.809875,602.775879 393.346344,609.418396 389.119171,616.207581 
	C386.535767,620.356750 384.382538,624.771545 381.963165,629.025574 
	C378.200073,635.642090 374.509949,642.306030 370.536530,648.795349 
	C366.842651,654.828186 362.601990,660.530457 358.996307,666.611938 
	C354.788300,673.709351 351.008514,681.060547 347.041779,688.301086 
	C344.768433,692.450623 342.594177,696.658630 340.193542,700.733276 
	C336.845245,706.416504 333.288910,711.976685 329.890045,717.630615 
	C323.995483,727.435913 318.216614,737.311157 312.272217,747.085938 
	C305.768829,757.780029 299.063721,768.351562 292.580444,779.057678 
	C288.667725,785.518921 285.035278,792.149292 281.229645,798.676208 
	C278.006226,804.204529 274.637634,809.649353 271.474945,815.211792 
	C268.217316,820.941223 265.327972,826.886963 261.927124,832.526733 
	C255.614746,842.994751 248.856949,853.196777 242.652283,863.726257 
	C238.407303,870.929993 234.963425,878.601929 230.832962,885.878052 
	C226.973511,892.676941 222.818726,899.320190 218.530396,905.859131 
	C215.691757,910.187622 212.388245,914.211243 209.132111,918.670288 
z"/>
<path fill="#6E2F32" opacity="1.000000" stroke="none" 
	d="
M866.067017,583.953125 
	C864.871948,578.465088 863.582336,573.290527 859.082947,569.043457 
	C856.084717,566.213318 854.725281,561.681580 852.497192,557.993042 
	C848.852478,551.959412 845.039734,546.027283 841.404968,539.987793 
	C837.443359,533.405273 832.979126,527.028687 829.861450,520.058044 
	C825.486877,510.276855 819.210022,501.872833 813.297668,493.101685 
	C809.240784,487.083221 806.850220,479.950256 803.601013,473.371704 
	C802.886841,471.925781 801.709595,470.703339 800.712341,469.403442 
	C799.149109,467.365814 797.379028,465.463043 796.010254,463.304291 
	C791.112976,455.580841 786.243103,447.835876 781.569519,439.976166 
	C778.490845,434.798676 775.901123,429.331360 772.850098,424.136108 
	C768.863708,417.348328 764.648987,410.694305 760.508484,403.997528 
	C759.216309,401.907471 757.750854,399.920288 756.528320,397.791992 
	C749.732361,385.960571 743.145996,374.005310 736.164429,362.285126 
	C731.918457,355.157074 726.803345,348.543884 722.613220,341.386414 
	C719.314636,335.751740 717.063721,329.511993 713.915100,323.778473 
	C711.481140,319.346222 708.402954,315.264374 705.549072,311.068939 
	C704.996704,310.256897 703.943115,309.756744 703.484192,308.914642 
	C699.772766,302.103882 696.148071,295.245819 693.049072,288.346130 
	C695.738037,290.180603 697.874756,292.071106 700.011536,293.961609 
	C700.011536,293.961609 700.017761,293.984802 700.016663,294.384125 
	C700.367798,296.371643 700.255310,298.324860 701.161072,299.478363 
	C703.687500,302.695709 703.384766,308.289429 708.940796,308.995697 
	C711.798645,313.978210 714.694885,318.939178 717.506592,323.947601 
	C724.210022,335.888092 730.620361,348.001953 737.629639,359.759277 
	C743.755066,370.034149 750.896118,379.707520 756.934937,390.029022 
	C764.086365,402.252197 770.440796,414.939087 777.344727,427.311157 
	C779.718811,431.565552 782.791809,435.423981 785.391602,439.560089 
	C788.175415,443.988922 790.650879,448.613831 793.491455,453.004028 
	C796.981567,458.398041 800.954285,463.490509 804.268494,468.984680 
	C807.725159,474.714813 810.446045,480.886475 813.855774,486.647766 
	C817.600220,492.974426 821.850891,498.999329 825.713684,505.258942 
	C828.229675,509.336029 830.497986,513.569702 832.782349,517.784668 
	C834.640137,521.212585 836.317078,524.737976 838.146729,528.181580 
	C840.064087,531.790405 841.858398,535.489563 844.079895,538.906372 
	C848.068176,545.040894 852.341309,550.989990 856.479370,557.027527 
	C856.948059,557.711426 857.313599,558.465881 857.983521,559.855286 
	C861.799866,566.931763 865.354065,573.343750 868.945496,579.734802 
	C869.056519,579.932495 869.552917,579.913635 869.919678,580.261963 
	C870.012207,580.703674 870.054749,580.879089 869.938599,581.343872 
	C868.542236,582.406616 867.304626,583.179871 866.067017,583.953125 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M902.075562,633.004761 
	C905.295471,639.117432 908.331970,645.336365 911.788940,651.312012 
	C914.991882,656.848450 918.749329,662.061035 922.070496,667.532959 
	C925.693848,673.502869 929.096252,679.606934 932.084229,685.748535 
	C931.045776,685.563110 930.518066,685.281555 929.838196,684.695312 
	C928.119812,682.723083 926.313293,681.221375 925.028442,679.359680 
	C919.048340,670.694885 916.263367,660.044495 908.371826,652.537170 
	C907.706421,651.904175 907.650452,650.675293 907.099609,649.867737 
	C905.992859,648.245239 904.739807,646.722473 903.547119,645.158569 
	C902.695862,647.104858 901.144836,649.037964 901.107849,650.999634 
	C900.882141,662.970581 900.841675,674.950867 901.058533,686.922241 
	C901.206909,695.111816 903.318420,702.646545 910.382385,707.859192 
	C912.222839,709.217224 914.671936,710.172485 915.827271,711.966064 
	C921.627441,720.970520 927.146484,730.158386 932.657959,739.345703 
	C938.467346,749.029480 940.152344,760.123718 941.716858,770.887207 
	C943.541870,783.442993 943.076538,796.316406 944.154419,809.005310 
	C944.473999,812.767822 946.647400,816.372864 947.977051,820.401489 
	C948.649902,822.174255 949.317017,823.595459 950.022705,825.378235 
	C951.706421,829.827393 953.351440,833.914917 955.002625,838.354614 
	C955.668762,839.478882 956.328674,840.250977 957.025513,841.363892 
	C958.370056,842.805420 959.677673,843.906250 960.989197,845.380981 
	C960.992249,846.502991 960.991455,847.251038 960.740112,848.210693 
	C960.644226,849.978271 960.799011,851.534180 960.610352,853.012085 
	C956.946106,847.465088 953.474365,842.080994 950.337769,836.508179 
	C944.793640,826.658325 939.349182,817.160889 940.722778,804.705872 
	C942.395142,789.541870 940.683472,774.260620 936.858704,759.375122 
	C932.367126,741.894592 923.668396,726.659668 911.688293,713.263794 
	C909.660034,710.995850 907.214478,709.101135 904.930420,706.708862 
	C904.252441,705.946655 903.605835,705.509521 902.830322,704.782227 
	C901.755737,703.346497 900.810181,702.200928 899.903381,700.705811 
	C899.583496,699.571289 899.224792,698.786377 898.894043,697.533630 
	C898.944031,675.703186 898.966125,654.340637 899.373352,632.982788 
	C900.530823,632.993225 901.303162,632.998962 902.075562,633.004761 
z"/>
<path fill="#6E2F32" opacity="1.000000" stroke="none" 
	d="
M656.060181,224.528473 
	C654.731567,223.468994 653.126770,222.198563 652.104187,220.563904 
	C648.209473,214.338120 644.700928,207.859299 640.566040,201.804214 
	C639.689514,200.520706 636.799133,200.348587 634.802124,200.225708 
	C634.017578,200.177460 632.695557,201.520157 632.372253,202.482666 
	C629.780212,210.199234 624.231567,216.399628 621.043396,223.559906 
	C616.195496,234.447632 608.256531,243.215637 603.017700,253.726624 
	C599.286438,261.213043 594.880676,268.375000 590.547852,275.543945 
	C588.964478,278.163757 586.685852,280.357117 584.797180,282.801239 
	C583.792419,284.101440 582.824158,285.457214 582.042664,286.898132 
	C576.290222,297.504852 570.630310,308.162079 564.804688,318.728119 
	C564.255066,319.725006 562.765503,320.203674 561.350037,320.949707 
	C561.270203,319.547089 561.202332,317.916565 561.889587,316.719574 
	C568.178894,305.765686 574.445740,294.793365 581.023315,284.011993 
	C588.037415,272.515289 595.488708,261.285614 602.525024,249.802032 
	C605.081482,245.629822 606.992798,241.062103 609.194092,236.672668 
	C609.417786,236.226578 609.564331,235.735962 609.826904,235.316147 
	C613.642883,229.214157 617.586975,223.188736 621.263733,217.004303 
	C624.361450,211.793854 626.868164,206.212524 630.209534,201.175552 
	C631.682251,198.955536 634.346619,196.994247 636.881531,196.147308 
	C639.947693,195.122879 641.099670,197.928665 642.502197,200.437271 
	C645.926025,206.561188 649.977112,212.331055 653.620239,218.337799 
	C654.697205,220.113602 655.255493,222.204025 656.060181,224.528473 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M951.045654,716.035461 
	C951.040283,716.767029 951.034973,717.498657 951.125366,718.826965 
	C951.488159,719.622375 951.755371,719.821045 952.022583,720.019775 
	C953.998901,723.082031 955.975220,726.144287 958.011353,729.914062 
	C959.063599,731.434570 960.056030,732.247498 961.048462,733.060425 
	C961.058594,733.458496 961.068726,733.856506 961.074585,734.916260 
	C962.727356,737.730469 964.384521,739.882996 966.041687,742.035583 
	C966.041687,742.035583 966.037659,742.496460 966.013062,743.003662 
	C965.977783,743.695129 965.967102,743.879456 965.611877,744.008179 
	C960.550598,736.953003 955.813416,729.966980 951.130310,722.944946 
	C949.862305,721.043640 948.761780,719.030884 947.578064,717.073120 
	C942.724121,709.045898 937.867737,701.020203 933.344482,693.025269 
	C935.130981,694.026733 936.585205,694.996887 938.039368,695.967041 
	C938.039368,695.967102 938.007324,695.989258 938.042358,696.333862 
	C940.082092,699.109680 942.086792,701.540955 944.091492,703.972168 
	C944.407898,704.419556 944.724304,704.866943 944.810486,705.801880 
	C945.396118,707.543091 946.212036,708.796814 947.027893,710.050598 
	C947.351318,710.788757 947.674805,711.526917 948.033936,712.926514 
	C949.061646,714.403748 950.053650,715.219604 951.045654,716.035461 
z"/>
<path fill="#6E2F32" opacity="1.000000" stroke="none" 
	d="
M680.992920,266.581665 
	C678.835754,264.216003 676.304993,261.701080 674.525757,258.735291 
	C669.030029,249.574524 663.770752,240.269882 658.550537,230.947815 
	C657.695007,229.420029 657.501282,227.521576 657.011841,225.401367 
	C658.576538,227.198883 660.336182,229.273483 661.650757,231.599609 
	C668.152832,243.105301 674.537231,254.677475 680.992920,266.581665 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M898.441040,582.757202 
	C897.784790,577.645813 897.405945,572.283997 897.031616,566.070679 
	C896.338074,563.377869 895.640076,561.536499 894.942078,559.695190 
	C896.067871,558.928162 897.193665,558.161072 898.319458,557.393982 
	C899.160095,558.735229 900.789124,560.124878 900.710205,561.409729 
	C900.277405,568.456299 899.426086,575.477112 898.441040,582.757202 
z"/>
<path fill="#1E0E0E" opacity="1.000000" stroke="none" 
	d="
M894.602661,559.783081 
	C895.640076,561.536499 896.338074,563.377869 897.020142,565.602478 
	C892.700562,565.061401 890.445496,568.039917 887.872925,570.617249 
	C885.958069,572.535828 883.746765,574.158508 881.316589,575.571533 
	C881.652954,573.497864 882.340149,571.765625 883.027344,570.033386 
	C883.027283,570.033386 883.000000,570.002441 883.380737,570.003906 
	C884.514099,569.009827 885.266724,568.014160 886.019348,567.018494 
	C886.019348,567.018494 886.000000,567.000000 886.372803,567.003052 
	C888.155457,565.646667 889.565308,564.287231 890.975220,562.927856 
	C892.071228,561.908875 893.167297,560.889893 894.602661,559.783081 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M902.075195,632.584351 
	C901.303162,632.998962 900.530823,632.993225 899.058228,632.975586 
	C897.940125,632.930115 897.522217,632.896606 897.104248,632.863098 
	C897.393433,627.635864 897.682617,622.408691 898.322876,617.030029 
	C899.807617,621.973755 900.941223,627.068909 902.075195,632.584351 
z"/>
<path fill="#6E2F32" opacity="1.000000" stroke="none" 
	d="
M692.936157,286.026947 
	C687.090210,281.162964 682.398865,275.545624 681.041016,267.380615 
	C682.587097,269.024567 684.138306,271.036011 685.634827,273.087341 
	C688.090027,276.452850 690.509827,279.844269 692.973022,283.928894 
	C692.979675,285.097656 692.957947,285.562317 692.936157,286.026947 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M869.869690,579.995667 
	C869.552917,579.913635 869.056519,579.932495 868.945496,579.734802 
	C865.354065,573.343750 861.799866,566.931763 858.143616,560.145874 
	C860.766113,562.973145 863.553833,566.122681 866.171875,569.407471 
	C867.295166,570.816833 868.065857,572.507141 869.100220,574.697327 
	C869.463806,575.839966 869.723938,576.355042 869.983398,577.171143 
	C869.945007,578.313293 869.907349,579.154480 869.869690,579.995667 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M869.984131,576.870117 
	C869.723938,576.355042 869.463806,575.839966 869.175049,575.031738 
	C874.740540,576.599426 878.273376,572.614685 882.674683,570.063049 
	C882.340149,571.765625 881.652954,573.497864 880.962280,575.590332 
	C880.958740,575.950562 880.970032,575.966187 880.591187,575.966492 
	C876.802917,576.267944 873.393555,576.569031 869.984131,576.870117 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M976.039734,759.031250 
	C976.045898,759.455078 976.052063,759.878906 976.063232,760.619202 
	C976.068237,760.935669 976.017151,760.983459 975.646973,760.962585 
	C969.473999,757.202698 967.547180,751.205261 966.249512,745.102112 
	C967.019897,745.468262 967.526123,745.727356 968.032410,745.986511 
	C968.032410,745.986511 967.996521,745.996826 968.037231,746.346436 
	C969.081299,748.121887 970.084595,749.547791 971.087891,750.973633 
	C971.392578,751.062988 971.641296,751.233826 971.922180,752.077637 
	C973.353455,754.789856 974.696594,756.910522 976.039734,759.031250 
z"/>
<path fill="#563233" opacity="1.000000" stroke="none" 
	d="
M708.800171,308.685181 
	C703.384766,308.289429 703.687500,302.695709 701.161072,299.478363 
	C700.255310,298.324860 700.367798,296.371643 700.025818,294.391846 
	C701.919678,297.148834 703.801392,300.298584 705.687744,303.445526 
	C706.674133,305.091095 707.668701,306.731812 708.800171,308.685181 
z"/>
<path fill="#563233" opacity="1.000000" stroke="none" 
	d="
M692.947510,286.355988 
	C692.957947,285.562317 692.979675,285.097656 693.013733,284.283295 
	C695.333069,287.042755 697.640076,290.151947 699.979370,293.611389 
	C697.874756,292.071106 695.738037,290.180603 693.294922,288.146240 
	C692.978577,287.563293 692.968628,287.124176 692.947510,286.355988 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M938.046875,695.579346 
	C936.585205,694.996887 935.130981,694.026733 933.336548,692.654419 
	C932.990173,691.511292 932.984070,690.770203 932.983521,689.681152 
	C932.984680,688.868225 932.980347,688.403320 932.976013,687.938354 
	C936.941406,688.764954 937.816528,691.755188 938.046875,695.579346 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M944.033936,703.620728 
	C942.086792,701.540955 940.082092,699.109680 938.060852,696.319458 
	C940.021606,698.396729 941.998962,700.833069 944.033936,703.620728 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M966.282654,741.834961 
	C964.384521,739.882996 962.727356,737.730469 961.061951,735.214722 
	C962.877014,737.112549 964.700317,739.373474 966.282654,741.834961 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M976.039429,758.663147 
	C974.696594,756.910522 973.353455,754.789856 972.029114,752.280518 
	C973.378357,754.026306 974.708740,756.160645 976.039429,758.663147 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M890.643677,562.979858 
	C889.565308,564.287231 888.155457,565.646667 886.381958,567.012695 
	C887.449524,565.690186 888.880859,564.360962 890.643677,562.979858 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M971.069946,750.607178 
	C970.084595,749.547791 969.081299,748.121887 968.049438,746.331909 
	C969.031189,747.392151 970.041565,748.816467 971.069946,750.607178 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M897.855957,606.064392 
	C897.551270,604.628479 897.538940,603.048279 897.846436,601.232422 
	C898.160217,602.637878 898.154297,604.278992 897.855957,606.064392 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M932.752197,687.784546 
	C932.980347,688.403320 932.984680,688.868225 932.637268,689.617554 
	C931.520447,688.267944 930.755371,686.633972 929.990356,685.000000 
	C930.518066,685.281555 931.045776,685.563110 931.849121,685.973816 
	C932.259216,686.612244 932.393738,687.121521 932.752197,687.784546 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M947.017578,709.708740 
	C946.212036,708.796814 945.396118,707.543091 944.839539,706.086792 
	C945.734985,707.045166 946.371155,708.205994 947.017578,709.708740 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M951.039673,715.657104 
	C950.053650,715.219604 949.061646,714.403748 948.055542,713.229980 
	C949.038818,713.674255 950.036255,714.476501 951.039673,715.657104 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M961.030334,732.706970 
	C960.056030,732.247498 959.063599,731.434570 958.050171,730.268677 
	C959.023560,730.728271 960.017883,731.540894 961.030334,732.706970 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M885.727051,567.129578 
	C885.266724,568.014160 884.514099,569.009827 883.394531,570.016663 
	C883.830017,569.098816 884.632446,568.169739 885.727051,567.129578 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M968.031860,745.630554 
	C967.526123,745.727356 967.019897,745.468262 966.242310,744.869263 
	C965.971008,744.529480 965.956360,744.063721 965.956360,744.063721 
	C965.967102,743.879456 965.977783,743.695129 966.002563,743.233826 
	C966.688293,743.729431 967.359802,744.502014 968.031860,745.630554 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M951.919556,719.788208 
	C951.755371,719.821045 951.488159,719.622375 951.117065,719.192688 
	C951.280884,719.159973 951.548767,719.358276 951.919556,719.788208 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M645.990723,579.977356 
	C646.173279,576.714600 643.922241,576.296875 641.452209,575.993347 
	C633.660034,575.035706 629.749207,570.911438 630.110046,563.085938 
	C630.325562,558.410828 631.448853,553.744019 632.510376,549.153503 
	C633.201416,546.165222 632.973572,544.559082 629.473572,543.805786 
	C628.081787,543.506287 626.231628,541.132996 626.158264,539.627502 
	C625.810059,532.487427 625.806091,525.311096 626.167725,518.172302 
	C626.240967,516.725220 628.418823,515.381653 629.632996,513.996155 
	C631.082336,512.342163 633.662964,510.703369 633.676331,509.041321 
	C633.689697,507.375244 631.199646,505.637329 629.703064,504.027313 
	C628.526306,502.761353 627.273560,501.535767 625.907776,500.482697 
	C623.856750,498.901337 621.827637,496.544189 619.524292,496.176453 
	C612.398376,495.038910 605.140198,494.752899 597.946899,494.006195 
	C592.680298,493.459473 592.025879,493.978607 592.000183,499.495941 
	C591.998657,499.828827 592.000183,500.161713 591.999878,500.494598 
	C591.991333,511.395569 591.975586,511.217896 581.221924,512.173401 
	C579.385010,512.336670 576.578979,514.525818 576.262207,516.171936 
	C575.603699,519.594177 573.876038,520.063782 571.080017,520.022644 
	C565.755066,519.944275 560.407898,519.741150 555.113464,520.151550 
	C553.383972,520.285645 550.277832,522.348206 550.383362,523.214050 
	C550.983215,528.136353 546.578796,529.498230 544.443726,532.345764 
	C543.868347,533.113220 541.375977,533.241943 540.265198,532.677246 
	C533.942322,529.462830 533.993958,529.361328 532.147034,536.075012 
	C528.107788,531.705627 524.372620,534.748657 520.438965,536.206482 
	C516.615417,537.623474 512.661011,538.687378 508.415588,539.605347 
	C509.711670,537.198792 511.356293,535.093323 513.396240,532.992432 
	C515.196777,532.334595 516.602051,531.672180 518.339355,531.007507 
	C519.114319,531.003479 519.557190,531.001770 520.228027,531.271240 
	C524.312317,531.676086 529.008118,527.769348 528.763123,523.203430 
	C528.475891,517.851807 530.678162,514.929871 534.947449,512.577454 
	C538.083862,510.849335 541.097351,508.856934 543.982727,506.733826 
	C545.230042,505.815979 546.016418,504.271851 547.428833,503.008728 
	C554.069458,501.893646 560.313049,500.875458 566.497009,499.573578 
	C567.469238,499.368896 568.170715,497.878296 569.327637,496.982361 
	C570.098328,496.981140 570.538757,496.979797 571.301697,496.946838 
	C572.087708,496.283722 572.551086,495.652283 573.345398,495.015686 
	C574.117554,495.005493 574.558777,495.000488 575.231140,495.266815 
	C577.231567,494.571869 578.995972,493.596497 580.771118,492.641022 
	C585.442139,490.126831 590.533569,488.167206 594.700989,484.993317 
	C602.297119,479.208130 610.680908,473.766754 612.208130,463.002960 
	C612.311890,462.272034 613.253784,461.696442 613.527527,460.946930 
	C614.733643,457.645203 615.837463,454.306183 616.982605,450.650482 
	C616.988770,449.879822 616.990723,449.439331 617.354370,448.937439 
	C620.319702,441.861969 622.923340,434.847839 625.526978,427.833740 
	C626.050354,427.679657 626.573730,427.525604 627.097107,427.371552 
	C631.194885,435.014648 642.036438,435.558197 644.320923,445.218872 
	C644.502014,445.984711 646.057617,446.425568 646.979004,447.470398 
	C646.992554,450.948517 647.001587,453.973633 646.733398,457.235107 
	C646.358154,464.155365 646.288452,470.618164 651.750427,475.975372 
	C653.743652,477.930328 655.060974,481.789459 654.802063,484.606567 
	C653.884399,494.588806 658.842896,502.927582 662.081055,511.529602 
	C666.135559,522.300110 664.007263,532.592529 654.320374,538.836609 
	C651.818237,540.449463 649.519043,542.551575 646.809204,543.634094 
	C639.147400,546.694885 637.297363,553.369873 636.242676,560.306335 
	C635.697998,563.889404 636.516113,567.416809 640.843750,569.190491 
	C645.063049,570.919617 648.943787,573.459534 653.040466,575.509033 
	C655.627014,576.802979 658.336914,577.850220 661.257812,579.128662 
	C662.013611,579.835876 662.503113,580.422668 663.027161,581.355713 
	C664.049316,582.792725 665.037048,583.883423 666.371887,584.983887 
	C667.819214,585.989502 668.919373,586.985291 669.961365,588.323120 
	C668.484619,588.498718 666.815308,588.758118 665.689026,588.095032 
	C659.493713,584.447510 654.526489,577.945251 645.990723,579.977356 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M336.000977,956.009766 
	C327.523254,951.681213 319.017242,947.406311 310.598480,942.965942 
	C309.341431,942.302917 308.159637,941.031677 307.516022,939.753235 
	C305.001526,934.758728 301.589111,929.854736 300.615082,924.531982 
	C299.199158,916.794312 298.686005,908.632751 299.459625,900.817444 
	C300.458221,890.729126 303.063538,880.795410 305.100037,870.820190 
	C305.345734,869.616638 306.448486,868.611938 306.994690,867.440735 
	C308.089050,865.094177 308.983490,862.652161 310.124542,860.330139 
	C311.695496,857.133118 313.256439,853.911926 315.107758,850.875549 
	C319.970276,842.900269 325.084869,835.078064 329.916504,827.084595 
	C334.952728,818.752502 339.882599,810.351746 344.656921,801.867249 
	C348.294952,795.402100 351.502228,788.694397 355.145844,782.232605 
	C356.338013,780.118286 358.607544,778.620178 359.849701,776.523499 
	C363.164673,770.927979 366.110870,765.115234 369.363403,759.481079 
	C370.925781,756.774658 373.030945,754.379822 374.572021,751.663330 
	C378.198364,745.270996 381.633728,738.770447 385.144806,732.312805 
	C385.301575,732.024414 385.367249,731.626282 385.602997,731.449829 
	C394.259857,724.971985 396.117340,714.398926 401.143829,705.736450 
	C402.470795,703.449646 404.236969,701.409912 405.484924,699.086670 
	C407.879944,694.628052 409.812103,689.909485 412.363434,685.548645 
	C416.702942,678.131531 421.421021,670.937012 425.887726,663.592773 
	C429.083374,658.338562 432.134766,652.996399 435.232513,647.682922 
	C435.650146,646.966614 435.803314,646.038635 436.347412,645.462158 
	C443.254517,638.144775 446.019348,628.450134 451.060974,620.073303 
	C452.834015,617.127319 456.061462,614.940247 457.401489,611.872375 
	C461.116669,603.366760 466.702515,596.139709 471.769012,588.512024 
	C475.392853,583.056335 477.941833,576.896667 481.170013,571.163513 
	C482.526489,568.754517 484.618683,566.761780 485.992645,564.360229 
	C489.637360,557.989380 493.018036,551.467773 496.634430,545.080200 
	C497.433929,543.668091 498.663300,541.630188 499.884216,541.486145 
	C503.815308,541.022400 503.940460,538.249451 505.032410,535.249390 
	C506.073273,534.998901 506.512573,534.999878 506.925781,535.368408 
	C505.472198,538.703247 504.291107,541.822021 502.575165,544.611877 
	C494.493103,557.751831 486.057861,570.678345 478.150116,583.920776 
	C471.231995,595.505981 465.075836,607.545898 458.167999,619.137634 
	C450.327423,632.294617 441.874939,645.085999 433.970551,658.206299 
	C427.358124,669.182129 421.186249,680.422607 414.755859,691.509033 
	C409.211090,701.068542 403.575989,710.575684 397.982574,720.106995 
	C392.266388,729.847412 386.585815,739.608948 380.826782,749.323975 
	C375.944000,757.560730 370.859741,765.680176 366.092072,773.982056 
	C364.086823,777.473877 362.666687,781.301575 360.980591,784.976624 
	C360.980560,784.976624 361.000000,785.000000 360.637421,785.026062 
	C359.539062,785.670532 358.571014,786.149658 358.103607,786.928955 
	C353.777161,794.142883 349.523193,801.400452 345.274994,808.661072 
	C338.425781,820.367188 331.519562,832.040955 324.786835,843.813843 
	C320.281311,851.692200 315.688934,859.554443 311.799500,867.739380 
	C309.408142,872.771790 307.875000,878.311890 306.665100,883.780762 
	C305.112823,890.797058 304.272339,897.970703 303.125183,905.076599 
	C303.112579,905.504211 303.100006,905.931824 302.803131,906.909302 
	C302.717834,909.305298 302.916870,911.151367 303.115906,912.997437 
	C303.105408,914.043640 303.094879,915.089783 303.049805,916.928345 
	C305.877594,929.231018 311.428101,938.874512 322.035767,945.360840 
	C322.748901,946.107605 323.413879,946.532715 324.309723,946.959961 
	C324.540588,946.962097 325.001068,946.995728 325.030518,947.289856 
	C325.709106,947.716614 326.358246,947.849304 327.304138,948.003967 
	C328.071106,948.341797 328.541321,948.657654 329.056946,949.322876 
	C331.863770,952.390930 334.455933,955.612183 339.391266,953.006226 
	C340.535950,952.970032 341.299133,952.936707 342.062317,952.903442 
	C342.062317,952.903381 342.034058,953.000610 342.058472,953.354736 
	C343.059601,953.799011 344.036285,953.889038 345.455994,953.975342 
	C348.261230,953.998352 350.623535,954.025208 353.062744,954.421509 
	C355.740662,955.527283 358.346558,956.913574 360.941803,956.893982 
	C377.232147,956.770996 393.520874,956.017334 409.808777,956.054382 
	C510.253876,956.282654 610.698547,956.697449 711.143616,956.949341 
	C721.432312,956.975159 731.722168,956.070007 742.012451,956.047668 
	C789.339600,955.945068 836.667053,956.001526 883.994446,956.006287 
	C885.492798,956.006470 886.991272,956.095337 888.489502,956.090271 
	C896.238037,956.064026 903.988770,956.094666 911.731934,955.871216 
	C912.524353,955.848328 913.271851,954.267029 914.040222,953.408997 
	C915.414429,953.302612 916.788635,953.196228 918.885498,953.367188 
	C923.738708,953.756531 927.869324,953.868652 931.999634,954.240234 
	C932.000427,954.672607 932.001404,954.845459 931.910767,955.411316 
	C931.474426,956.141296 931.087036,956.806030 930.791138,956.767578 
	C922.244568,955.655090 914.300598,959.321655 905.663940,959.301392 
	C828.228027,959.119629 750.790649,959.732361 673.353210,959.940369 
	C656.900940,959.984558 640.445679,959.006775 623.993713,959.060974 
	C574.715210,959.223206 525.437927,959.716797 476.159515,959.942871 
	C467.872437,959.980957 459.583252,959.081787 451.293213,959.045898 
	C423.467773,958.925354 395.641510,958.987793 367.815552,959.013977 
	C364.390594,959.017151 360.918121,959.638977 357.550812,959.251465 
	C350.338684,958.421509 343.180969,957.118835 336.000977,956.009766 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M662.992676,581.009460 
	C662.503113,580.422668 662.013611,579.835876 661.115845,578.823730 
	C658.163208,576.079590 655.686279,573.680908 653.056519,571.463501 
	C650.106445,568.976013 650.058655,566.057251 652.332825,563.018982 
	C654.001953,560.788940 655.298828,558.071106 656.000366,555.372742 
	C657.202332,550.749329 661.226257,547.647400 665.459106,549.407654 
	C672.696716,552.417480 680.229980,555.179749 686.685303,559.478333 
	C693.299377,563.882629 699.759399,567.544739 707.729126,565.868286 
	C712.821960,564.797058 718.165649,563.190857 722.362183,560.314697 
	C724.457825,558.878479 724.234863,554.056702 725.053772,550.764587 
	C725.133850,550.442505 725.136414,550.100525 725.223389,549.780945 
	C728.178223,538.925293 724.718140,529.318726 719.197083,520.249329 
	C714.827454,513.071350 710.123779,506.095093 705.484009,499.085175 
	C704.907654,498.214478 703.916138,497.405640 702.940796,497.045715 
	C695.495117,494.298065 692.384583,498.359253 688.928528,506.204315 
	C687.589478,509.243835 685.577759,512.090027 683.369507,514.577759 
	C682.747070,515.278870 679.558289,515.014771 678.877563,514.148010 
	C672.104370,505.524292 665.854187,496.584656 663.952148,485.374481 
	C663.041931,480.009949 661.253540,474.939728 655.880981,472.352539 
	C651.645203,470.312775 650.422974,466.395782 649.046204,462.479614 
	C648.399963,460.641327 647.691101,458.825043 647.010620,456.998779 
	C647.001587,453.973633 646.992554,450.948517 646.940552,447.068176 
	C646.293762,436.801941 642.031006,429.144470 634.742188,423.521301 
	C632.221680,421.576813 628.161255,420.443268 625.013245,420.797394 
	C621.078613,421.239960 619.470154,425.181824 619.081909,428.996979 
	C618.798706,431.780579 619.241028,434.642792 618.903076,437.414215 
	C618.709961,438.997833 617.601990,440.469879 616.634888,441.712463 
	C616.573425,435.181946 617.173889,428.914215 616.903015,422.684357 
	C616.472656,412.786774 620.658630,412.257812 628.646118,409.022278 
	C631.970947,407.675507 634.385010,409.117981 637.020325,411.381531 
	C645.637146,418.782593 647.322266,428.946747 649.032227,439.239899 
	C649.750549,443.563599 650.910583,447.813934 651.890259,452.411346 
	C651.892395,453.145355 651.874084,453.565613 651.597168,454.268372 
	C650.533020,462.533539 654.223267,468.656891 661.663452,470.924011 
	C667.070923,472.571716 671.013794,475.183075 673.617676,480.658081 
	C676.900513,487.560699 682.591492,488.572968 689.465149,485.130035 
	C693.540039,483.088959 697.667542,482.880554 701.225281,486.684540 
	C703.644714,489.271393 706.318604,491.620331 708.896362,494.071655 
	C708.914124,494.067688 708.931152,494.090759 708.945557,494.463196 
	C710.606079,497.582001 712.252258,500.328430 714.167847,503.268677 
	C715.933105,506.000214 717.428772,508.537872 718.940674,511.422180 
	C719.616516,512.530457 720.276245,513.292114 721.208618,514.266663 
	C723.826965,519.377258 726.094238,524.314941 728.559631,529.151550 
	C729.452759,530.903748 730.819885,532.414246 731.982056,534.404480 
	C732.013306,535.512695 732.033386,536.251526 731.804443,537.143250 
	C732.004395,537.884033 732.453491,538.471802 732.937134,539.038574 
	C732.971741,539.017456 733.038757,538.971802 732.994751,539.347717 
	C732.918823,540.477722 732.886841,541.231873 732.612183,542.209106 
	C732.532654,543.613953 732.695923,544.795593 732.876831,546.300781 
	C732.894958,547.055176 732.895447,547.486084 732.506958,548.028442 
	C731.739380,549.328369 731.077454,550.504639 731.026184,551.707031 
	C730.508667,563.855591 727.647705,566.411499 716.887634,571.405212 
	C706.235107,576.349060 698.001953,573.320923 688.730896,568.157043 
	C683.081360,565.010315 675.938416,564.395630 669.368286,563.168762 
	C667.953857,562.904602 665.713318,564.555664 664.555420,565.920288 
	C661.666809,569.324707 662.411499,573.472534 666.405396,577.894470 
	C668.501221,580.215027 671.104370,582.131775 672.927429,584.632507 
	C676.284912,589.238220 682.865540,590.915405 688.257996,589.353271 
	C692.209045,588.208618 697.268250,587.227539 700.720520,588.730347 
	C709.950073,592.748230 717.715881,590.399841 725.681091,585.894897 
	C732.174072,582.222656 738.760315,579.122253 746.741211,579.849548 
	C756.754333,580.761902 764.541260,575.222473 771.958557,569.436035 
	C773.388428,568.320557 774.618774,566.338745 774.967712,564.565063 
	C775.929260,559.677673 776.669128,554.704956 776.944336,549.735474 
	C777.241333,544.373535 777.789124,539.127747 772.601929,534.791687 
	C766.736389,529.888672 765.844543,522.325623 765.242920,515.234009 
	C765.979553,515.931519 766.476257,516.481323 766.977173,517.019897 
	C766.981384,517.008667 766.969849,517.023315 766.999512,517.375488 
	C768.646851,519.530823 770.264587,521.333923 771.933777,523.492615 
	C774.325928,528.571960 776.666626,533.295776 778.997681,538.381348 
	C779.969849,539.858398 780.951660,540.973694 782.077637,542.401550 
	C783.173157,544.802673 784.124512,546.891235 785.049805,549.316223 
	C785.006287,550.101562 784.988892,550.550598 784.587402,551.086792 
	C782.083801,556.898621 780.380005,562.824341 777.733582,568.294067 
	C774.806885,574.343262 770.819763,579.602173 762.802673,579.131714 
	C761.382202,579.048340 759.898682,580.040161 758.444336,580.535461 
	C758.521118,581.108215 758.597961,581.680969 758.674744,582.253662 
	C763.444153,581.843628 768.213501,581.433594 773.402588,581.032532 
	C777.363525,581.543396 780.904785,582.045288 784.954590,582.619263 
	C784.954590,584.094666 784.954590,585.533936 784.954590,586.973145 
	C773.281006,586.649536 761.607422,586.325928 749.241394,585.738098 
	C745.145447,584.184021 744.139099,586.718262 742.960205,588.977234 
	C742.221130,588.986145 741.482117,588.994995 740.020020,589.010742 
	C738.517029,589.670044 737.737122,590.322388 736.957153,590.974731 
	C735.903137,590.984314 734.849060,590.993958 733.078918,591.047485 
	C731.898010,591.721863 731.433228,592.352295 730.968384,592.982727 
	C729.864868,593.137085 728.165283,592.893372 727.753601,593.509766 
	C724.854492,597.850525 720.062500,597.784485 715.104187,599.053833 
	C713.920105,599.721680 713.455688,600.353088 712.991272,600.984436 
	C712.991272,600.984436 713.000000,601.000000 712.671265,601.022217 
	C711.561462,601.702820 710.780396,602.361267 709.999329,603.019653 
	C709.563660,603.026428 709.128052,603.033203 708.002747,603.027344 
	C706.336365,602.769775 705.359680,602.524841 704.194702,602.232727 
	C705.683960,600.569397 706.810791,599.310791 708.495239,597.429443 
	C706.844238,597.336914 706.160522,597.106995 705.626831,597.297424 
	C697.817993,600.084412 690.270264,600.455994 683.209534,595.197021 
	C681.916321,594.233826 680.519897,593.386169 679.095581,592.626953 
	C676.096802,591.028503 673.047913,589.524048 670.019531,587.981079 
	C668.919373,586.985291 667.819214,585.989502 666.332764,584.640137 
	C664.961914,583.194214 663.977295,582.101807 662.992676,581.009460 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M632.975586,785.000000 
	C632.975769,796.998901 633.210632,808.504761 632.869812,819.993530 
	C632.716187,825.170715 634.172913,829.479126 637.217834,833.390869 
	C639.540222,836.374329 642.157593,839.129272 644.456665,842.129211 
	C645.153931,843.038940 645.878601,845.090027 645.477173,845.484192 
	C643.411194,847.512695 641.056274,849.282837 638.634460,850.890503 
	C637.936279,851.353943 636.681091,850.985901 635.681274,850.985962 
	C604.684204,850.987244 573.687134,850.976807 542.690063,851.004089 
	C539.815613,851.006592 537.214905,851.238098 535.774231,847.709900 
	C534.355835,844.236206 536.123596,842.208435 537.768311,839.604065 
	C539.910583,836.211914 540.793091,831.907349 543.295593,828.889099 
	C546.460266,825.072327 548.093018,820.947632 548.343872,816.281799 
	C548.762878,808.489380 549.290710,800.631714 548.632690,792.898499 
	C548.449829,790.749207 544.274048,787.268005 541.888794,787.233154 
	C514.563904,786.834106 487.230438,786.890747 459.900818,787.067505 
	C454.677246,787.101257 451.921021,791.307190 449.441925,795.320618 
	C448.491638,796.859009 447.981781,798.704407 446.890320,800.115173 
	C437.083710,812.791016 440.940155,826.994629 442.544983,840.796997 
	C443.363892,847.840149 441.471497,850.966248 434.346863,850.974976 
	C412.682281,851.001648 391.015900,851.108704 369.355286,850.814026 
	C366.815125,850.779419 364.045593,848.751648 361.919434,846.997070 
	C361.138184,846.352356 361.254242,843.326111 362.080566,842.230652 
	C369.220459,832.764648 376.610931,823.487671 383.936798,814.162231 
	C390.785309,805.444458 397.665833,796.751770 404.495483,788.019287 
	C410.402832,780.466003 416.394165,772.972778 422.101685,765.270203 
	C428.688812,756.380554 434.852203,747.175842 441.478638,738.316711 
	C449.642883,727.401672 458.279327,716.837891 466.373779,705.872864 
	C475.365845,693.691833 483.847992,681.134888 492.809387,668.930481 
	C497.211182,662.935791 502.358948,657.493591 506.872589,651.575195 
	C512.664429,643.980896 518.124878,636.134766 523.789978,628.442627 
	C528.840820,621.584595 534.028625,614.827148 539.054749,607.951355 
	C542.256042,603.571899 544.419983,598.121460 550.172607,596.360657 
	C553.515808,595.337341 557.044556,594.755310 560.532654,594.419434 
	C563.777832,594.106873 567.080505,594.437134 570.356323,594.386108 
	C573.676514,594.334473 576.994568,594.069702 580.314087,594.059448 
	C593.399292,594.018982 606.484741,594.042603 619.570068,594.040466 
	C621.069885,594.040222 622.574097,593.949280 624.068665,594.034424 
	C629.715820,594.356079 632.963806,597.839172 632.964233,603.517151 
	C632.969177,663.844788 632.972046,724.172363 632.975586,785.000000 
M552.806274,809.942139 
	C552.850220,812.959106 552.894165,815.976135 552.830261,819.401550 
	C552.830261,819.401550 552.801575,819.823059 552.153503,820.038208 
	C548.319336,826.970459 544.408203,833.862000 540.708801,840.865479 
	C539.857178,842.477600 539.056519,844.676636 539.520020,846.218506 
	C539.809387,847.180969 542.509399,847.903503 544.130371,847.908813 
	C574.458069,848.007385 604.786255,848.011902 635.113892,847.909485 
	C636.955139,847.903259 638.792786,846.840759 640.632080,846.269653 
	C639.992065,844.536926 639.646423,842.611572 638.660522,841.105286 
	C634.639526,834.961731 629.776489,829.821228 629.818115,821.240417 
	C630.164673,749.754700 629.982056,678.266418 629.952637,606.778870 
	C629.949219,598.489258 628.295044,596.840393 619.914368,597.073425 
	C613.010010,597.265442 606.108643,597.921082 599.209534,597.872314 
	C585.242432,597.773682 571.275513,597.096802 557.313721,597.224976 
	C554.212097,597.253479 550.218994,598.817505 548.214478,601.075562 
	C542.396057,607.630005 537.296082,614.834473 532.065918,621.896240 
	C522.231689,635.174377 512.572754,648.582581 502.703094,661.834106 
	C497.756409,668.475830 492.332397,674.763855 487.432709,681.438354 
	C479.237457,692.602234 471.482422,704.091980 463.187714,715.179443 
	C453.171265,728.568298 442.623566,741.559448 432.598602,754.942078 
	C418.498169,773.765320 404.829712,792.913025 390.657654,811.681152 
	C382.588989,822.366577 373.935577,832.613770 365.430786,842.964050 
	C363.485504,845.331482 364.568207,846.659546 366.596619,847.591370 
	C367.726654,848.110413 369.203125,847.964783 370.523621,847.966125 
	C390.853394,847.987122 411.183197,847.992737 431.512939,847.964355 
	C432.991486,847.962280 434.484009,847.752441 435.945160,847.496887 
	C439.480957,846.878540 440.209198,844.860229 439.723694,841.359009 
	C438.542389,832.839905 437.471954,824.266235 437.159821,815.685364 
	C437.036469,812.293091 438.573029,808.581238 440.186615,805.443176 
	C442.912842,800.141296 446.869781,795.428406 449.239990,790.000854 
	C451.285278,785.317383 454.260651,783.941956 458.878601,783.952393 
	C487.706787,784.017700 516.535156,783.974243 545.363464,783.998352 
	C551.134216,784.003174 551.821594,784.840149 551.987305,790.744019 
	C552.158691,796.846191 552.479492,802.944153 552.806274,809.942139 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M669.961365,588.323120 
	C673.047913,589.524048 676.096802,591.028503 679.095581,592.626953 
	C680.519897,593.386169 681.916321,594.233826 683.209534,595.197021 
	C690.270264,600.455994 697.817993,600.084412 705.626831,597.297424 
	C706.160522,597.106995 706.844238,597.336914 708.495239,597.429443 
	C706.810791,599.310791 705.683960,600.569397 704.194702,602.232727 
	C705.359680,602.524841 706.336365,602.769775 707.676025,603.033325 
	C704.834961,606.671570 701.863281,610.958740 696.678650,611.449707 
	C693.876709,611.714966 690.591125,610.883362 688.088989,609.513794 
	C681.890137,606.120911 675.887268,602.322510 670.052246,598.326904 
	C665.222900,595.019897 660.945129,590.889648 656.036682,587.722900 
	C653.173218,585.875488 649.559570,584.192383 646.267944,584.162781 
	C616.181580,583.892456 586.091797,583.992371 556.003052,584.011658 
	C552.235474,584.014099 549.202148,583.529419 546.510986,588.386047 
	C542.873108,594.951172 536.438599,599.904419 531.696533,605.936401 
	C526.333374,612.758545 521.550964,620.035095 516.451233,627.067139 
	C511.061554,634.499023 505.662964,641.926147 500.147675,649.264709 
	C491.114746,661.283813 482.168762,673.376892 472.794739,685.126526 
	C469.528748,689.220154 466.813385,693.638062 462.935028,697.521729 
	C455.645721,704.821045 450.673126,714.395874 444.445496,722.808594 
	C441.083099,727.350891 436.756866,731.191284 433.496979,735.795471 
	C425.930359,746.482666 418.954163,757.589905 411.314301,768.222046 
	C407.915131,772.952576 403.437744,776.899780 399.566010,781.304749 
	C398.402313,782.628723 397.708191,784.353149 396.666138,785.798401 
	C390.609222,794.198975 384.820312,802.820374 378.308289,810.854309 
	C374.694275,815.312927 369.961823,818.905945 365.537079,822.657410 
	C357.094330,829.815613 348.510986,836.807983 339.234100,844.492004 
	C341.113831,846.259399 342.699005,847.704102 344.233307,849.200928 
	C349.084869,853.934082 353.893860,858.711121 358.770416,863.418213 
	C360.404785,864.995850 362.282043,866.324097 363.895264,867.920898 
	C373.262695,877.193420 382.580231,886.516174 391.928680,895.807800 
	C392.845490,896.719116 393.778717,897.650635 394.840851,898.371765 
	C397.270874,900.021667 399.873169,900.643311 401.757324,903.927307 
	C403.587097,907.116516 408.808136,908.360046 412.641907,911.027710 
	C412.497559,912.416321 412.251221,913.235107 412.004883,914.053894 
	C408.179810,916.769409 405.568512,913.726685 403.350952,911.678345 
	C393.561249,902.635742 384.147003,893.187012 374.369446,884.130859 
	C369.080048,879.231750 363.049835,875.120544 357.864532,870.123840 
	C351.260925,863.760376 345.173615,856.861084 338.869781,850.187012 
	C338.763214,850.074341 338.760437,849.868713 338.663879,849.740540 
	C337.132935,847.707458 333.903015,844.998779 334.391571,843.785583 
	C335.606689,840.768372 338.237610,838.114441 340.796265,835.883789 
	C348.643890,829.042236 356.700409,822.438782 364.740082,815.820984 
	C365.982361,814.798401 367.889496,814.481812 368.928131,813.331970 
	C372.596588,809.270508 376.199280,805.123840 379.503815,800.764648 
	C388.141144,789.370667 396.482269,777.750977 405.186462,766.409363 
	C410.970337,758.872864 418.386536,752.306763 422.779877,744.081909 
	C425.864807,738.306580 432.112640,735.376160 433.822754,728.389587 
	C434.886566,724.043335 439.768005,720.692749 442.713898,716.730042 
	C446.226715,712.004700 449.376709,707.008179 452.927521,702.313354 
	C455.005646,699.565613 457.750854,697.325867 459.863037,694.599609 
	C466.824219,685.614746 473.659302,676.531494 480.494781,667.449890 
	C488.455719,656.872925 497.719330,646.969604 503.805328,635.399902 
	C507.632751,628.123840 514.906860,624.145081 518.218262,616.281128 
	C524.443787,612.541199 525.121887,605.668274 528.997559,601.000000 
	C528.997559,601.000000 529.000000,601.000000 529.350159,600.962158 
	C531.133545,598.949585 532.566772,596.974792 534.295776,594.858276 
	C535.726868,593.144348 536.862244,591.572205 537.997559,590.000000 
	C538.497253,589.123840 538.996948,588.247681 540.069153,587.024536 
	C543.237793,585.202026 545.989319,583.828308 545.004395,580.009766 
	C578.354736,580.002563 611.705078,579.995422 645.523071,579.982788 
	C654.526489,577.945251 659.493713,584.447510 665.689026,588.095032 
	C666.815308,588.758118 668.484619,588.498718 669.961365,588.323120 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M913.579285,953.289551 
	C913.271851,954.267029 912.524353,955.848328 911.731934,955.871216 
	C903.988770,956.094666 896.238037,956.064026 888.489502,956.090271 
	C886.991272,956.095337 885.492798,956.006470 883.994446,956.006287 
	C836.667053,956.001526 789.339600,955.945068 742.012451,956.047668 
	C731.722168,956.070007 721.432312,956.975159 711.143616,956.949341 
	C610.698547,956.697449 510.253876,956.282654 409.808777,956.054382 
	C393.520874,956.017334 377.232147,956.770996 360.941803,956.893982 
	C358.346558,956.913574 355.740662,955.527283 353.488037,954.403137 
	C355.278168,954.293701 356.760925,954.931946 358.153076,954.773560 
	C360.833649,954.468567 363.462952,953.713013 366.113525,953.144287 
	C411.587402,953.121033 457.061279,953.080994 502.535156,953.077698 
	C629.736023,953.068542 756.936829,953.072571 884.137695,953.078735 
	C893.797913,953.079224 903.458130,953.138184 913.579285,953.289551 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M412.443970,914.043457 
	C412.251221,913.235107 412.497559,912.416321 412.871948,911.298767 
	C511.682587,911.005249 610.365173,911.010559 709.522217,911.411133 
	C709.984375,912.852173 709.971985,913.897949 709.959595,914.943787 
	C610.934082,914.640137 511.908569,914.336548 412.443970,914.043457 
z"/>
<path fill="#1E0E0E" opacity="1.000000" stroke="none" 
	d="
M904.961731,707.033997 
	C907.214478,709.101135 909.660034,710.995850 911.688293,713.263794 
	C923.668396,726.659668 932.367126,741.894592 936.858704,759.375122 
	C940.683472,774.260620 942.395142,789.541870 940.722778,804.705872 
	C939.349182,817.160889 944.793640,826.658325 950.337769,836.508179 
	C953.474365,842.080994 956.946106,847.465088 960.620483,853.238525 
	C960.974121,853.542847 961.026672,853.994812 961.018921,854.358826 
	C961.044739,855.447021 961.078308,856.171204 961.068359,856.936401 
	C961.024963,856.977356 960.938110,857.059265 960.938110,857.059265 
	C958.265869,852.767029 955.569641,848.489502 952.927734,844.178711 
	C950.006531,839.412292 947.205200,834.570251 944.182129,829.870239 
	C943.586060,828.943542 942.218567,828.513062 940.923340,827.668884 
	C938.611267,836.305237 936.603333,844.317322 934.312988,852.247925 
	C930.257812,866.289612 921.484924,877.352356 911.162109,887.195862 
	C904.670349,893.386169 897.075989,898.447815 890.786804,904.816162 
	C883.018677,912.682007 872.517578,914.944153 863.199646,919.494019 
	C857.614014,922.221375 850.895386,922.695801 844.631409,923.898499 
	C843.612610,924.094177 842.309631,922.810181 841.138000,922.210022 
	C842.340271,921.476501 843.604004,920.025146 844.735291,920.121948 
	C854.693054,920.973450 862.029358,914.579651 870.389954,911.086914 
	C874.840027,909.227844 879.137817,907.002563 883.491272,904.915222 
	C884.369812,904.494019 885.239624,904.019714 886.028687,903.452148 
	C898.604431,894.406006 910.287048,884.372131 918.988708,871.412231 
	C922.783203,865.760864 926.557922,859.843445 928.887268,853.511780 
	C934.274780,838.867188 938.614868,823.879272 938.028076,807.952271 
	C937.584961,795.926880 937.545227,783.882446 936.863525,771.872681 
	C936.645752,768.036133 934.999390,764.268433 933.916260,760.495239 
	C929.424377,744.847839 923.266418,730.061951 911.652771,718.164734 
	C907.734009,714.150452 903.653015,710.292603 899.795349,706.221863 
	C898.609802,704.970825 897.919678,703.250427 897.362793,701.377930 
	C898.436829,701.025696 899.150696,701.040527 899.864624,701.055359 
	C900.810181,702.200928 901.755737,703.346497 902.880005,705.094482 
	C903.693115,706.142639 904.327393,706.588318 904.961731,707.033997 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M795.170715,682.815308 
	C795.945251,683.462524 796.719788,684.109741 797.780762,685.103516 
	C800.024048,687.005920 801.980774,688.561646 803.937500,690.117371 
	C803.937500,690.117371 803.929504,690.557373 803.985474,691.120483 
	C807.679382,694.149109 811.317322,696.614624 815.282288,699.155640 
	C817.380005,700.509888 819.150635,701.788696 820.975464,703.390259 
	C821.715088,704.092041 822.400574,704.471008 823.296265,704.908081 
	C823.506470,704.966125 823.933044,705.057556 823.992432,705.401184 
	C825.026978,706.178284 826.002014,706.611755 827.180725,707.098877 
	C827.384399,707.152466 827.803040,707.199524 827.904175,707.519775 
	C837.524231,714.796387 847.030884,721.769592 856.591980,728.667480 
	C857.172363,729.086182 858.184204,728.906799 859.229858,729.170044 
	C859.970581,729.894165 860.476746,730.453918 861.000488,731.365234 
	C864.054016,735.608337 867.817749,739.134888 869.986938,743.460999 
	C876.098511,755.649597 883.200012,767.480469 885.126282,781.420044 
	C885.683289,785.451233 887.493774,789.309204 888.743652,793.588989 
	C888.751587,794.616211 888.745605,795.301086 888.491760,796.304199 
	C888.020447,809.403320 884.530823,821.423767 879.413147,832.985962 
	C876.553528,839.446533 873.153931,845.668213 869.939636,852.195801 
	C869.881714,852.394043 869.811218,852.800964 869.479858,852.870178 
	C868.002441,853.869263 866.625916,854.621033 865.747192,855.757629 
	C859.503967,863.833069 851.652222,869.890564 842.679810,874.618103 
	C840.070435,875.992981 837.560364,877.556335 834.686890,879.035522 
	C833.948425,879.085999 833.527954,879.134277 832.709351,879.117859 
	C827.877197,880.391052 823.443115,881.728943 818.688965,883.053650 
	C817.946167,883.085632 817.523315,883.130798 816.674805,883.106384 
	C814.816040,882.965393 813.382935,882.893982 812.046387,882.475708 
	C815.165100,881.068420 818.112793,879.486511 821.223022,879.041992 
	C832.140503,877.481628 841.728394,872.819031 850.269775,866.274841 
	C859.863281,858.924438 867.829651,849.899475 873.402588,839.048096 
	C875.603760,834.762085 877.320374,830.217102 879.097961,825.726807 
	C880.520691,822.132935 881.828003,818.481567 882.948914,814.783691 
	C883.807129,811.952148 884.486694,809.035034 884.894653,806.107056 
	C885.343628,802.884033 885.678223,799.591675 885.526367,796.354675 
	C885.396179,793.579285 884.503723,790.840454 883.958191,788.083496 
	C882.772156,782.089722 882.962097,775.330200 880.130188,770.256470 
	C873.874573,759.049072 870.255798,746.455139 860.867859,736.735107 
	C855.575928,731.255920 849.939941,726.370605 843.874390,722.173645 
	C832.865845,714.556519 821.164612,707.942017 809.782593,700.860596 
	C808.669800,700.168274 807.762512,699.143372 806.768250,698.263428 
	C801.661438,693.744324 796.957703,688.629456 791.354919,684.845337 
	C783.663879,679.650879 778.934753,671.889893 775.487488,664.281250 
	C772.279541,657.200928 767.190796,648.368652 774.615845,640.062439 
	C775.152588,639.461975 774.898010,638.145752 774.993713,637.160583 
	C775.562378,631.309021 783.210938,623.811890 789.152893,623.524292 
	C781.952759,628.373535 776.670410,634.114624 776.098999,643.376343 
	C776.036987,644.132874 776.017700,644.566406 775.702637,645.277527 
	C775.863586,648.790405 776.320190,652.025696 776.771484,655.552185 
	C776.789124,656.230713 776.812012,656.618103 776.611450,657.237061 
	C777.597839,663.294250 779.666931,668.619751 784.943970,672.311890 
	C784.982849,672.523193 785.113220,672.933899 785.102844,673.283325 
	C788.451843,676.693604 791.811279,679.754456 795.170715,682.815308 
z"/>
<path fill="#2E1110" opacity="1.000000" stroke="none" 
	d="
M361.322937,784.941040 
	C362.666687,781.301575 364.086823,777.473877 366.092072,773.982056 
	C370.859741,765.680176 375.944000,757.560730 380.826782,749.323975 
	C386.585815,739.608948 392.266388,729.847412 397.982574,720.106995 
	C403.575989,710.575684 409.211090,701.068542 414.755859,691.509033 
	C421.186249,680.422607 427.358124,669.182129 433.970551,658.206299 
	C441.874939,645.085999 450.327423,632.294617 458.167999,619.137634 
	C465.075836,607.545898 471.231995,595.505981 478.150116,583.920776 
	C486.057861,570.678345 494.493103,557.751831 502.575165,544.611877 
	C504.291107,541.822021 505.472198,538.703247 507.244904,535.210449 
	C508.090790,533.176270 508.591492,531.667603 509.433044,530.074219 
	C510.845306,528.328735 511.916748,526.668030 513.012878,524.773315 
	C513.037598,524.539246 513.044312,524.068665 513.374146,523.998413 
	C514.800049,522.289551 515.896118,520.650879 517.298584,518.859985 
	C518.751770,516.498169 519.898621,514.288513 521.359253,512.010376 
	C522.109924,511.290619 522.546875,510.639465 522.991943,509.994141 
	C523.000000,510.000000 522.987976,509.986664 523.279846,509.852295 
	C524.023743,508.807129 524.475830,507.896393 524.950500,506.735229 
	C524.981201,506.317383 524.989380,506.149963 525.248474,505.889099 
	C526.332703,505.851227 527.165955,505.906830 528.003601,506.297424 
	C528.024719,507.077789 528.041443,507.523193 527.683228,508.044983 
	C526.870605,509.745972 526.432800,511.370544 525.997498,512.997559 
	C526.000000,513.000000 525.992676,512.994812 525.651733,513.045898 
	C520.718994,518.392029 516.406311,523.847900 514.681641,531.026001 
	C513.907532,531.710327 513.454224,532.349060 513.000916,532.987793 
	C511.356293,535.093323 509.711670,537.198792 508.038483,539.648499 
	C501.491333,550.734497 494.823273,561.389526 488.512695,572.252197 
	C484.982788,578.328247 482.055298,584.753601 478.828766,591.007385 
	C478.453949,591.733887 478.015778,592.497986 477.415192,593.026917 
	C470.730774,598.914856 468.506744,607.410950 464.411743,614.831848 
	C461.396515,620.295959 457.258728,625.130249 454.067383,630.509888 
	C448.450378,639.978516 443.274170,649.707764 437.721802,659.216003 
	C433.826111,665.887207 429.561188,672.341614 425.552979,678.948547 
	C419.980347,688.134155 414.525635,697.391296 408.960785,706.581665 
	C403.760101,715.170654 398.444458,723.690063 393.252594,732.284363 
	C390.455627,736.914185 387.856964,741.663513 385.088623,746.311218 
	C380.956665,753.248291 376.700409,760.111694 372.597961,767.065918 
	C369.278564,772.692749 366.118683,778.413452 362.847595,784.069214 
	C362.627991,784.448853 362.067322,784.631226 361.322937,784.941040 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M732.006836,599.017456 
	C734.076904,598.691833 736.146912,598.366211 739.008179,598.018982 
	C747.204468,596.344910 754.609619,594.692505 762.014709,593.040161 
	C769.688599,593.045959 777.362488,593.051819 785.681702,593.370300 
	C787.884094,594.111267 789.441162,594.539551 790.998230,594.967834 
	C791.443604,594.979004 791.888916,594.990112 793.005737,595.156006 
	C798.960205,597.450134 804.234253,599.612061 809.536133,601.703552 
	C810.279785,601.997009 811.159058,601.946533 812.184204,602.325073 
	C814.572449,603.409668 816.751953,604.224426 819.011230,605.395874 
	C826.013794,610.203308 833.390320,610.230774 841.364990,608.002441 
	C842.823425,607.671631 843.915344,607.338257 845.327759,606.965210 
	C846.120544,606.295227 846.592957,605.664978 847.415405,605.033569 
	C849.180786,604.027771 850.596191,603.023010 852.377441,602.015442 
	C854.175415,601.031616 855.607483,600.050659 857.039612,599.069702 
	C858.512024,598.069824 859.892212,596.877869 861.478943,596.116150 
	C863.734009,595.033447 866.978333,592.881836 868.294495,593.677429 
	C870.481628,594.999573 872.475525,598.196411 872.745422,600.791504 
	C873.217896,605.333069 872.006104,610.020569 872.059631,614.640503 
	C872.216492,628.185303 872.815247,641.726990 872.818481,655.270020 
	C872.819092,657.683594 870.797058,660.097656 869.708984,662.511536 
	C867.447449,661.469910 864.878052,660.816772 862.981689,659.314270 
	C857.141296,654.687195 851.533325,649.764099 845.896973,644.884583 
	C844.817749,643.950195 844.020325,642.690247 843.452881,641.229004 
	C844.537415,640.924438 845.261841,640.971375 846.044312,641.359009 
	C847.061707,642.139648 848.021057,642.579590 848.990234,643.010986 
	C849.000000,643.002441 848.981506,643.025269 849.033691,643.354370 
	C849.785400,644.078430 850.485046,644.473511 851.448547,645.014282 
	C852.436279,645.470703 853.160095,645.781311 854.018921,646.382019 
	C855.106689,647.776855 856.059509,648.881592 857.047485,650.312744 
	C857.746826,651.067078 858.410828,651.495056 859.346680,652.079773 
	C860.735840,653.171265 861.853210,654.106079 863.020508,655.358521 
	C863.726318,656.096680 864.382080,656.517212 865.330261,657.106018 
	C870.895935,660.638611 869.867737,655.767883 869.886963,653.636597 
	C870.046143,635.990540 869.997681,618.342407 869.929871,600.695190 
	C869.924194,599.216675 869.318359,597.740540 868.902954,595.863708 
	C862.334229,599.941284 856.704224,604.081116 850.518555,607.071472 
	C845.586853,609.455627 840.017761,611.555054 834.658325,611.748413 
	C828.523315,611.969788 822.307251,609.945374 816.029663,608.568970 
	C814.960693,607.813171 813.988464,607.385071 812.702515,606.928467 
	C811.970703,606.874023 811.552795,606.848083 810.933105,606.596191 
	C809.850647,605.875549 808.969910,605.380859 808.017822,604.568848 
	C807.237488,603.917480 806.528564,603.583374 805.598755,603.206726 
	C805.377808,603.164185 804.940002,603.060120 804.904114,602.694275 
	C803.585632,601.864441 802.302979,601.400452 800.694946,600.913086 
	C799.935120,600.891907 799.500854,600.894043 798.793945,600.649109 
	C796.058472,599.875061 793.595642,599.348022 791.051025,598.519897 
	C790.285706,597.878662 789.602112,597.538513 788.529297,597.154541 
	C787.096008,597.089355 786.052002,597.067932 784.828369,596.796753 
	C783.764343,596.680176 782.879883,596.813477 781.527039,596.941406 
	C773.706970,596.929932 766.355286,596.923767 758.826904,596.709229 
	C758.055542,596.939270 757.460815,597.377808 756.480713,597.848755 
	C755.065247,597.925598 754.035095,597.970032 752.815308,597.757996 
	C751.712830,597.950623 750.799927,598.399841 749.488586,598.883911 
	C747.765930,599.046326 746.441589,599.173950 744.780212,599.214661 
	C743.981079,599.138184 743.518982,599.148560 742.737488,599.149963 
	C741.898499,599.722473 741.378906,600.303894 740.640686,600.901062 
	C740.422058,600.916809 739.987854,600.977600 739.574036,600.976318 
	C735.562500,602.033264 731.458313,602.320496 728.459717,604.290588 
	C718.444702,610.870605 708.825500,618.053101 698.797485,624.818970 
	C699.718384,622.101868 700.896423,619.570618 702.074463,617.039368 
	C702.923706,616.879822 703.772827,616.720276 705.162720,616.259766 
	C707.464050,614.639099 709.224670,613.319580 710.985352,612.000000 
	C710.985352,612.000000 711.002441,612.002441 711.349609,611.965088 
	C714.817627,609.296082 717.938416,606.664368 721.059265,604.032715 
	C722.147339,603.709595 723.235413,603.386475 725.012268,602.994080 
	C727.802979,601.622375 729.904907,600.319946 732.006836,599.017456 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M751.011780,874.098267 
	C745.073975,876.299255 742.026367,871.364136 738.294922,868.646362 
	C730.667297,863.090820 723.321533,857.143982 715.940430,851.256958 
	C714.032471,849.735229 712.634521,847.527771 710.637756,846.172180 
	C704.784668,842.198547 698.655029,839.952087 691.192139,841.814026 
	C685.771851,843.166382 681.105591,844.728638 677.084167,849.037354 
	C674.713501,851.577393 670.598328,853.061279 667.037048,853.669250 
	C665.335022,853.959778 661.966553,851.476501 661.350159,849.595154 
	C660.268982,846.295410 660.640259,842.493408 660.571594,838.898499 
	C660.533813,836.922302 661.012207,834.935059 660.961182,832.960449 
	C660.727905,823.923584 660.146484,814.889160 660.155518,805.854370 
	C660.165100,796.235168 660.827271,786.617981 660.940063,776.996826 
	C660.986389,773.046692 659.792786,769.032898 660.185974,765.151733 
	C660.491943,762.131897 661.715576,758.494507 663.847534,756.666992 
	C664.929382,755.739563 669.628723,757.473083 671.469604,759.262146 
	C679.091248,766.669312 686.125122,774.676697 693.606567,782.234192 
	C700.945129,789.647217 708.601807,796.744324 716.046753,804.053528 
	C720.441101,808.367859 724.695435,812.824829 728.702026,817.468262 
	C726.190735,816.588562 723.990295,815.458069 721.793396,814.292114 
	C721.796997,814.256653 721.783936,814.191772 721.724609,813.856567 
	C718.109314,810.681763 714.553284,807.842163 710.783386,804.888428 
	C710.302002,804.575134 710.110291,804.319214 709.979004,803.662109 
	C708.974365,802.547791 707.985046,801.777893 706.997864,801.004028 
	C707.000000,801.000000 706.991943,801.004211 706.970581,800.676575 
	C706.297241,799.902283 705.645264,799.455627 704.996582,799.004517 
	C705.000000,799.000000 704.990906,799.006775 704.978455,798.658936 
	C699.646606,793.205444 694.327271,788.099731 689.003906,782.997009 
	C689.000000,783.000000 689.005981,782.992065 688.989136,782.645386 
	C686.977417,780.535583 684.982666,778.772522 682.920410,776.819519 
	C682.852905,776.629517 682.766846,776.235535 682.819580,775.873840 
	C677.911926,770.696533 672.951599,765.880859 667.973145,761.056274 
	C667.955078,761.047302 667.935059,761.012268 667.929199,760.643066 
	C666.796082,759.635010 665.668884,758.996216 664.541626,758.357422 
	C664.023743,760.005188 663.059875,761.651489 663.054504,763.300964 
	C662.965698,790.785828 662.993408,818.271118 663.013367,845.756287 
	C663.014221,846.911255 662.758606,848.484558 663.381653,849.128540 
	C664.476013,850.259888 666.868896,851.866638 667.478149,851.452087 
	C671.185791,848.929443 674.543579,845.892578 678.222290,842.975220 
	C678.426453,842.923218 678.842285,842.855225 679.236328,842.878235 
	C680.898804,842.507629 682.201538,842.199524 683.430115,841.706543 
	C692.032776,838.254272 700.531921,837.972229 708.894958,842.444336 
	C711.632202,843.907959 714.351196,845.405823 717.295959,846.936035 
	C717.513367,846.983948 717.954346,847.045593 717.998047,847.385864 
	C720.719543,850.117310 723.397339,852.508606 726.293213,854.939453 
	C726.511353,854.978943 726.947998,855.055908 727.010376,855.380737 
	C728.353088,856.828247 729.633362,857.950928 731.127197,859.191650 
	C731.607544,859.510559 731.804626,859.765381 731.986755,860.392334 
	C732.710083,861.120544 733.378540,861.530518 734.266968,861.975769 
	C734.486938,862.011047 734.927124,862.080261 734.979309,862.413452 
	C736.041016,863.472412 737.050537,864.198120 738.289062,864.958130 
	C738.518127,864.992432 738.980530,865.020325 739.010498,865.370728 
	C741.716125,867.450806 744.391724,869.180542 747.286316,870.945496 
	C747.505310,870.980774 747.941833,871.059937 747.998901,871.386536 
	C749.041260,872.508179 750.026489,873.303223 751.011780,874.098267 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M860.982849,731.013733 
	C860.476746,730.453918 859.970581,729.894165 859.194580,728.829163 
	C856.284668,725.685120 853.885254,722.732544 850.948425,720.480957 
	C846.525330,717.090088 841.841614,713.995117 837.044067,711.153503 
	C834.185791,709.460571 830.897827,708.493103 827.803040,707.199524 
	C827.803040,707.199524 827.384399,707.152466 827.062378,706.789368 
	C825.804565,705.970032 824.868774,705.513794 823.933044,705.057556 
	C823.933044,705.057556 823.506470,704.966125 823.236816,704.594421 
	C822.285156,703.837585 821.603210,703.452515 820.921265,703.067444 
	C819.150635,701.788696 817.380005,700.509888 815.177368,698.826538 
	C812.496826,696.283936 810.248291,694.145935 807.999756,692.007935 
	C810.483093,692.661682 813.248657,692.828796 815.390320,694.071350 
	C819.720337,696.583557 823.676697,699.731628 827.851685,702.520935 
	C832.776062,705.811035 837.740234,709.043884 842.746887,712.206970 
	C844.543640,713.342041 846.482300,714.269958 848.411560,715.174377 
	C853.487671,717.553772 857.963501,720.181030 860.720215,725.721191 
	C862.717529,729.735168 867.053894,732.513000 869.663513,736.311768 
	C873.450867,741.824829 876.624146,747.759949 880.054260,753.518127 
	C881.314941,755.634338 883.049072,757.613281 883.733704,759.903992 
	C887.734375,773.290649 892.410217,786.537292 892.030762,800.853271 
	C891.710144,812.948669 888.653625,824.300415 883.282715,835.031555 
	C879.815674,841.958557 876.695557,849.251770 872.082581,855.372925 
	C862.332642,868.310059 850.838928,879.326599 833.358582,881.607117 
	C833.008789,880.556213 833.058105,879.869324 833.107422,879.182495 
	C833.527954,879.134277 833.948425,879.085999 834.902283,879.289368 
	C846.030151,877.653503 853.716370,870.751160 861.282654,863.968933 
	C864.709167,860.897461 867.003296,856.562622 869.811218,852.800964 
	C869.811218,852.800964 869.881714,852.394043 870.290222,852.171143 
	C873.441650,847.899658 876.904175,844.155945 878.775452,839.738159 
	C882.493164,830.961426 885.939148,821.968628 888.304199,812.758728 
	C889.660034,807.478699 888.672241,801.596863 888.739563,795.985962 
	C888.745605,795.301086 888.751587,794.616211 888.841736,793.134277 
	C888.668091,789.882080 888.592346,787.391479 888.122742,784.977478 
	C885.263062,770.277039 880.372986,756.247559 872.464539,743.535217 
	C869.524597,738.809448 864.860657,735.156128 860.982849,731.013733 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M785.339722,587.024170 
	C784.954590,585.533936 784.954590,584.094666 784.954590,582.619263 
	C780.904785,582.045288 777.363525,581.543396 773.443542,580.682739 
	C773.593079,579.519714 774.006409,578.602600 774.667725,577.928894 
	C779.826965,572.673096 784.751587,567.348999 785.152588,559.342102 
	C785.193787,558.519165 786.993286,557.784302 788.291260,557.157898 
	C790.804260,561.036926 792.851746,564.866699 795.239197,568.471313 
	C798.315063,573.115417 801.663391,577.579163 804.908875,582.347290 
	C804.923401,582.573975 805.012329,583.019531 804.985229,583.346497 
	C804.940369,584.110779 804.922668,584.548157 804.559326,585.024902 
	C803.143311,586.708740 802.072876,588.353149 800.996826,590.003113 
	C800.991272,590.008667 800.975037,590.024841 800.975037,590.024780 
	C795.891602,589.041626 790.808228,588.058411 785.339722,587.024170 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M710.361389,914.961914 
	C709.971985,913.897949 709.984375,912.852173 709.989868,911.413696 
	C712.943787,911.233948 713.038879,909.306763 713.016235,907.122253 
	C712.957703,901.465149 712.914246,895.805298 713.048401,890.150940 
	C713.079895,888.825012 713.838318,887.516418 714.262329,886.199829 
	C715.580078,886.699707 717.059509,886.963379 718.189880,887.736938 
	C726.735657,893.585449 734.787720,900.316772 743.831726,905.230347 
	C752.795349,910.100281 762.659424,913.381165 772.288574,916.924072 
	C776.126282,918.336121 780.410706,918.502380 784.455750,919.398438 
	C785.979614,919.735962 787.401611,920.533447 789.030396,921.185486 
	C785.970825,925.058777 781.291931,925.700195 777.437805,923.482239 
	C775.201416,922.195190 772.765320,920.554504 770.344604,920.420654 
	C763.305603,920.031433 757.743713,916.534058 751.946960,913.189636 
	C749.464844,911.757629 746.177673,911.722351 743.693054,910.293152 
	C736.431458,906.115906 729.415039,901.514771 722.242554,897.179077 
	C720.624390,896.200867 718.762451,895.625977 716.014343,894.431458 
	C716.014343,900.258423 716.000671,905.091492 716.018921,909.924500 
	C716.032349,913.466003 714.269775,915.118958 710.361389,914.961914 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M682.899414,689.984619 
	C682.893433,689.273376 682.887512,688.562195 682.927002,687.126892 
	C683.243347,685.256104 683.514404,684.109436 684.114563,683.208862 
	C684.820801,685.722595 685.001709,688.043823 685.605530,690.249329 
	C691.451050,711.599304 702.377502,729.330933 721.676270,741.226135 
	C739.566101,752.252869 757.026123,764.013733 775.244019,774.459534 
	C790.108337,782.982361 794.445007,800.238342 792.834167,812.146667 
	C792.164795,817.095947 790.009033,821.632080 784.998169,823.741699 
	C784.963989,823.291138 784.950317,823.110962 784.966797,822.613464 
	C785.014709,821.870422 785.032532,821.444641 785.163086,820.775696 
	C785.553528,820.074219 785.831299,819.615967 786.459839,819.071167 
	C791.304443,816.480225 790.768555,812.101318 790.928040,807.652466 
	C790.929993,806.897156 790.917297,806.465576 790.941650,805.646301 
	C790.684875,803.830200 790.391113,802.401794 790.081665,800.645752 
	C790.035950,799.882080 790.005920,799.445923 789.952515,798.690918 
	C789.340454,797.844116 788.751892,797.316284 788.099487,796.416626 
	C786.399841,788.071716 782.984863,781.384338 774.862366,777.709290 
	C773.818176,776.944092 772.907898,776.472046 771.677551,775.847656 
	C770.163635,775.150208 768.858032,774.766785 767.794678,774.032654 
	C762.650330,770.480408 757.695923,766.639954 752.444031,763.259155 
	C739.795776,755.116943 726.613525,747.734314 714.455444,738.928528 
	C707.806702,734.113037 702.802307,727.027161 697.003662,720.628418 
	C695.668579,719.144043 694.386292,717.996277 692.947876,716.526245 
	C692.193665,714.469238 691.595642,712.734619 690.962646,710.682983 
	C690.324219,709.857666 689.720764,709.349487 689.107727,708.533203 
	C689.068420,707.815369 689.038696,707.405579 688.977661,706.625366 
	C686.930725,700.831482 684.915100,695.408081 682.899414,689.984619 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M697.056458,720.965088 
	C702.802307,727.027161 707.806702,734.113037 714.455444,738.928528 
	C726.613525,747.734314 739.795776,755.116943 752.444031,763.259155 
	C757.695923,766.639954 762.650330,770.480408 767.794678,774.032654 
	C768.858032,774.766785 770.163635,775.150208 771.729492,776.192383 
	C773.066345,777.127075 774.031311,777.564758 774.996216,778.002441 
	C782.984863,781.384338 786.399841,788.071716 788.078247,796.759644 
	C788.739136,797.986145 789.357544,798.497925 789.975952,799.009766 
	C790.005920,799.445923 790.035950,799.882080 789.818726,800.808350 
	C789.380188,801.867310 789.188904,802.436096 788.997559,803.004883 
	C788.316650,801.886169 787.309204,800.848999 787.006348,799.635864 
	C784.517578,789.667358 779.709534,781.928955 769.123352,778.715576 
	C766.460815,777.907349 764.463257,775.101135 761.966736,773.518127 
	C753.676208,768.261536 745.219116,763.265503 736.973999,757.940247 
	C728.004333,752.147156 718.891113,746.502014 710.425415,740.027588 
	C704.076233,735.171753 697.186401,730.325867 695.341553,721.376465 
	C696.137207,720.985962 696.596863,720.975525 697.056458,720.965088 
z"/>
<path fill="#2E1110" opacity="1.000000" stroke="none" 
	d="
M303.494324,904.963074 
	C304.272339,897.970703 305.112823,890.797058 306.665100,883.780762 
	C307.875000,878.311890 309.408142,872.771790 311.799500,867.739380 
	C315.688934,859.554443 320.281311,851.692200 324.786835,843.813843 
	C331.519562,832.040955 338.425781,820.367188 345.274994,808.661072 
	C349.523193,801.400452 353.777161,794.142883 358.103607,786.928955 
	C358.571014,786.149658 359.539062,785.670532 360.625275,785.017029 
	C359.589386,788.110168 358.503906,791.417297 356.768188,794.337830 
	C345.083130,813.998901 333.241241,833.566589 321.492310,853.189758 
	C317.599884,859.690918 314.413696,866.411987 312.684662,873.948914 
	C310.899963,881.728577 307.904724,889.225708 305.576202,896.888123 
	C304.789642,899.476379 304.422333,902.192078 303.494324,904.963074 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M775.998413,645.000000 
	C776.017700,644.566406 776.036987,644.132874 776.439209,643.297485 
	C777.477966,641.993347 778.531921,641.168884 778.726196,640.176208 
	C780.169128,632.803955 784.650757,628.378174 791.646851,626.119751 
	C794.833557,625.091064 797.932007,623.788696 801.488708,622.618347 
	C803.579102,622.626404 805.251282,622.626526 807.130737,622.973877 
	C809.929504,623.840576 812.520874,624.360046 815.512451,624.896851 
	C817.244568,624.994751 818.576660,625.075134 819.984863,625.462280 
	C820.739380,626.144531 821.417786,626.520020 822.322083,626.917480 
	C822.548035,626.939453 822.997559,627.002441 823.054077,627.346558 
	C824.761475,628.438904 826.412415,629.187134 828.292480,629.956055 
	C828.521545,629.976624 828.978516,630.029297 829.035767,630.369507 
	C832.095947,632.435486 835.098816,634.161194 838.461609,635.924316 
	C839.591492,636.942993 840.361389,637.924255 841.131348,638.905579 
	C836.408447,637.078186 831.165955,636.027466 827.059021,633.279541 
	C814.132874,624.631104 798.897888,623.464722 786.081543,631.816711 
	C780.275879,635.600037 779.882690,642.755554 779.620117,649.277466 
	C779.527344,651.583069 779.806396,653.903564 779.498779,656.638550 
	C778.332886,657.041687 777.583862,657.023621 776.834900,657.005493 
	C776.812012,656.618103 776.789124,656.230713 776.847412,655.083618 
	C776.618469,651.215942 776.308411,648.107971 775.998413,645.000000 
z"/>
<path fill="#1E0E0E" opacity="1.000000" stroke="none" 
	d="
M952.950928,944.888733 
	C956.609558,941.153687 959.969666,937.053101 963.994385,933.766296 
	C970.614563,928.359863 973.719055,920.811157 974.702087,913.181702 
	C976.315613,900.659180 974.484131,888.204712 968.855713,876.520691 
	C965.920471,870.427429 963.587219,864.044189 960.960083,857.425598 
	C960.938110,857.059265 961.024963,856.977356 961.397339,857.025391 
	C962.308655,857.663330 962.954651,858.186584 963.370667,858.853271 
	C970.689514,870.581970 974.041077,883.742126 977.052612,897.452271 
	C977.030579,900.258789 977.042725,902.623474 977.024414,905.365540 
	C977.013184,906.499023 977.032471,907.255249 977.030823,908.453125 
	C976.988953,911.254211 976.968018,913.613464 976.671387,916.200562 
	C973.929016,921.951416 971.462402,927.474487 968.844421,933.270142 
	C967.799438,934.374207 966.905762,935.205627 965.703735,936.163513 
	C961.284485,939.227417 957.173462,942.164856 953.034790,945.048828 
	C953.007080,944.995300 952.950928,944.888733 952.950928,944.888733 
z"/>
<path fill="#1E0E0E" opacity="1.000000" stroke="none" 
	d="
M897.016113,633.288513 
	C897.522217,632.896606 897.940125,632.930115 898.673218,632.970825 
	C898.966125,654.340637 898.944031,675.703186 898.652161,697.646362 
	C897.909607,698.484619 897.436768,698.742310 896.963867,698.999939 
	C896.642578,697.438049 896.047180,695.877075 896.041748,694.314087 
	C895.978149,676.059387 895.975647,657.804260 896.045410,639.549683 
	C896.052856,637.603333 896.620300,635.659119 897.016113,633.288513 
z"/>
<path fill="#2E1110" opacity="1.000000" stroke="none" 
	d="
M321.987640,945.039246 
	C311.428101,938.874512 305.877594,929.231018 303.071716,917.320190 
	C305.581940,921.011658 308.169617,925.030334 310.459320,929.212097 
	C314.226288,936.091858 319.698944,940.885559 326.799042,944.204285 
	C329.800903,945.607361 332.264313,948.162354 334.662964,950.551514 
	C332.572845,950.263977 330.792175,949.618774 329.011536,948.973511 
	C328.541321,948.657654 328.071106,948.341797 327.135925,947.759521 
	C326.114288,947.327209 325.557678,947.161499 325.001068,946.995728 
	C325.001068,946.995728 324.540588,946.962097 324.243530,946.645386 
	C323.293549,945.898865 322.640594,945.469055 321.987640,945.039246 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M721.789795,814.327637 
	C723.990295,815.458069 726.190735,816.588562 728.714478,817.833557 
	C732.432068,819.624207 735.826538,821.300293 739.746033,823.316895 
	C742.388367,825.123779 744.353699,826.920227 746.657776,827.980530 
	C750.294312,829.654114 754.158203,830.833496 757.660156,832.459473 
	C753.920471,832.150085 750.446594,831.603210 746.602356,831.038208 
	C741.360718,828.374451 736.276978,826.046997 731.685852,822.981689 
	C728.072021,820.568909 725.065857,817.246155 721.789795,814.327637 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M680.988037,656.983398 
	C681.507568,648.601562 683.536926,640.862549 689.734375,634.641724 
	C690.914368,633.457275 691.233765,631.415466 692.341125,629.358154 
	C694.482300,628.289734 696.232483,627.632446 697.880615,627.273926 
	C697.490356,628.353149 697.202209,629.133606 696.575195,629.986755 
	C692.496948,636.360779 688.527405,642.541992 685.143372,649.028687 
	C683.961487,651.294067 684.223572,654.312683 683.820801,657.340820 
	C683.775818,658.398987 683.736267,659.103699 683.383606,659.865356 
	C682.439758,660.017578 681.808960,660.112976 681.128845,659.807495 
	C681.049011,658.598816 681.018555,657.791138 680.988037,656.983398 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M819.908691,625.155518 
	C818.576660,625.075134 817.244568,624.994751 815.478394,624.575012 
	C814.976013,623.594421 814.907776,622.953064 815.230347,622.261719 
	C819.659302,623.529480 824.016724,624.269775 827.667175,626.288696 
	C834.647034,630.148804 841.224365,634.736694 848.120728,639.314819 
	C849.177307,640.742859 850.081970,641.878479 850.652588,643.015747 
	C849.872864,643.020020 849.427185,643.022644 848.981506,643.025269 
	C848.981506,643.025269 849.000000,643.002441 848.860962,642.714172 
	C847.810120,641.956665 846.898193,641.487488 845.986328,641.018311 
	C845.261841,640.971375 844.537415,640.924438 843.451965,640.874817 
	C842.516846,640.390625 841.942688,639.909119 841.249878,639.166626 
	C840.361389,637.924255 839.591492,636.942993 838.390015,635.587158 
	C834.965210,633.484802 831.971863,631.757080 828.978516,630.029297 
	C828.978516,630.029297 828.521545,629.976624 828.219116,629.619507 
	C826.276917,628.509033 824.637268,627.755737 822.997559,627.002441 
	C822.997559,627.002441 822.548035,626.939453 822.247070,626.606934 
	C821.267029,625.901489 820.587830,625.528503 819.908691,625.155518 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M865.839355,584.073547 
	C867.304626,583.179871 868.542236,582.406616 870.182129,581.554443 
	C870.719666,582.326721 870.854980,583.177856 870.990234,584.029053 
	C869.031128,589.775879 863.098083,590.126221 858.930298,592.864563 
	C857.867493,593.562866 856.593628,593.939819 854.915039,594.865601 
	C853.274658,595.856934 852.136047,596.446960 850.614990,597.033447 
	C847.060486,597.254456 845.042847,602.433777 841.253906,598.868286 
	C845.632996,596.671326 849.986938,595.070801 853.949951,592.790955 
	C857.839294,590.553284 861.331238,587.625122 864.997559,585.000000 
	C865.202271,584.731323 865.406982,584.462646 865.839355,584.073547 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M811.975586,602.055298 
	C811.159058,601.946533 810.279785,601.997009 809.536133,601.703552 
	C804.234253,599.612061 798.960205,597.450134 793.339905,595.150391 
	C794.206604,594.576904 795.522644,593.626953 796.593933,593.850403 
	C799.873657,594.534485 803.059448,595.669128 806.555115,596.709961 
	C806.715393,595.619995 806.851074,594.697388 806.989624,593.390747 
	C806.992493,593.006836 806.997131,593.004395 807.335449,593.006592 
	C808.124878,593.000916 808.575989,592.993042 809.269775,592.997681 
	C809.512329,593.010132 809.996216,593.053223 810.029114,593.401978 
	C811.713196,595.498108 813.364380,597.245361 814.671753,599.029358 
	C813.543884,600.062439 812.759766,601.058899 811.975586,602.055298 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M757.925903,832.222046 
	C754.158203,830.833496 750.294312,829.654114 746.657776,827.980530 
	C744.353699,826.920227 742.388367,825.123779 740.102783,823.361755 
	C743.568848,824.375671 747.188232,825.728760 750.840698,826.985840 
	C758.197021,829.517639 765.580444,831.799561 773.469238,828.346802 
	C769.099182,829.879395 764.494141,831.082581 759.565308,832.231567 
	C758.803040,832.192200 758.364441,832.207092 757.925903,832.222046 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M759.889099,832.285767 
	C764.494141,831.082581 769.099182,829.879395 773.879761,828.365112 
	C775.948975,826.584778 777.842712,825.115540 780.259399,823.341675 
	C781.518555,823.017883 782.254700,822.998657 783.315796,822.983154 
	C784.072693,822.968079 784.504700,822.949402 784.936707,822.930725 
	C784.950317,823.110962 784.963989,823.291138 784.760620,824.012085 
	C783.625427,824.895447 782.237732,824.968506 781.862122,825.622681 
	C778.311218,831.806458 772.095520,833.237976 765.694336,834.591980 
	C763.589355,833.654907 761.739258,832.970337 759.889099,832.285767 
z"/>
<path fill="#2E1110" opacity="1.000000" stroke="none" 
	d="
M931.999878,953.980835 
	C927.869324,953.868652 923.738708,953.756531 919.301514,953.374023 
	C919.867249,952.747681 920.763733,952.034058 921.608276,952.091064 
	C929.974365,952.655640 937.461914,949.714783 944.969666,946.619995 
	C947.206604,945.697815 949.739380,945.493530 952.543823,944.924561 
	C952.950928,944.888733 953.007080,944.995300 953.019714,945.363159 
	C953.020752,946.153198 953.009155,946.575378 952.998779,946.998779 
	C953.000000,947.000000 952.998352,946.997559 952.607910,946.996704 
	C949.138367,948.316589 946.059387,949.637390 942.652100,950.967896 
	C941.886780,951.003479 941.449707,951.029358 940.610840,951.038818 
	C937.472595,952.008545 934.736206,952.994690 931.999878,953.980835 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M811.949829,882.822510 
	C813.382935,882.893982 814.816040,882.965393 816.656006,883.409180 
	C817.040222,884.188538 817.017517,884.595581 816.994812,885.002625 
	C815.621094,885.016052 814.247314,885.029419 811.990356,885.028931 
	C803.384277,885.050232 795.661438,885.085327 787.938660,885.120361 
	C787.202209,885.088989 786.465759,885.057556 785.357788,884.740479 
	C784.982483,884.066589 784.978577,883.678284 785.118713,883.048706 
	C786.188232,882.820007 787.113770,882.832581 788.503479,882.887939 
	C796.628418,882.894592 804.289124,882.858582 811.949829,882.822510 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M761.734985,592.739502 
	C754.609619,594.692505 747.204468,596.344910 739.402100,597.999878 
	C740.654907,596.395691 742.143188,593.655640 743.985046,593.392700 
	C749.736572,592.571533 755.621826,592.686707 761.734985,592.739502 
z"/>
<path fill="#1E0E0E" opacity="1.000000" stroke="none" 
	d="
M871.357788,584.020386 
	C870.854980,583.177856 870.719666,582.326721 870.340881,581.265015 
	C870.054749,580.879089 870.012207,580.703674 869.919678,580.261963 
	C869.907349,579.154480 869.945007,578.313293 869.983398,577.171143 
	C873.393555,576.569031 876.802917,576.267944 880.583618,575.963623 
	C877.878418,578.644226 874.801880,581.327942 871.357788,584.020386 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M788.056519,885.501160 
	C795.661438,885.085327 803.384277,885.050232 811.579468,885.058350 
	C811.228577,885.726379 810.415710,886.876099 809.580200,886.892822 
	C802.969360,887.025085 796.353882,886.964966 789.740967,886.879211 
	C789.214539,886.872437 788.696350,886.229370 788.056519,885.501160 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M794.973389,922.588745 
	C796.636719,922.278809 798.004089,921.834412 799.220642,922.094727 
	C801.069519,922.490295 802.813782,923.374878 804.602417,924.051697 
	C802.831604,924.949402 801.109680,926.431091 799.276672,926.584229 
	C797.356323,926.744751 795.329346,925.629883 793.348999,925.072449 
	C793.769348,924.304993 794.189636,923.537476 794.973389,922.588745 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M782.990845,822.979492 
	C782.254700,822.998657 781.518555,823.017883 780.415405,823.050537 
	C782.853638,817.524414 785.658813,811.984985 789.055481,806.223022 
	C790.066223,806.011658 790.485413,806.022827 790.904541,806.033936 
	C790.917297,806.465576 790.929993,806.897156 790.570007,807.740967 
	C788.834534,811.821289 787.471802,815.489502 786.109070,819.157654 
	C785.831299,819.615967 785.553528,820.074219 784.837524,820.808289 
	C783.929749,821.715881 783.460266,822.347656 782.990845,822.979492 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M776.611450,657.237061 
	C777.583862,657.023621 778.332886,657.041687 779.448975,657.083130 
	C779.816162,657.106506 779.909546,657.561829 779.928955,658.128174 
	C780.275635,659.467529 780.602966,660.240601 780.930237,661.013611 
	C782.097717,663.555481 783.265259,666.097412 784.721436,669.195068 
	C784.975098,670.534119 784.940125,671.317383 784.905151,672.100586 
	C779.666931,668.619751 777.597839,663.294250 776.611450,657.237061 
z"/>
<path fill="#2E1110" opacity="1.000000" stroke="none" 
	d="
M365.651398,953.086426 
	C363.462952,953.713013 360.833649,954.468567 358.153076,954.773560 
	C356.760925,954.931946 355.278168,954.293701 353.411072,954.033691 
	C350.623535,954.025208 348.261230,953.998352 345.450531,953.724609 
	C345.000610,953.310486 344.999084,953.143188 344.997559,952.975830 
	C348.495850,952.686646 351.994110,952.174011 355.492432,952.173523 
	C358.724670,952.173157 361.957001,952.721741 365.651398,953.086426 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M679.091248,674.958496 
	C679.076111,671.621094 679.060974,668.283691 679.404175,664.482788 
	C680.218628,664.007935 680.674622,663.996521 681.353638,664.098083 
	C681.999451,664.479370 682.422363,664.747803 682.852539,665.459106 
	C682.856018,668.266968 682.852173,670.631958 682.634888,673.158691 
	C682.003174,673.884827 681.584900,674.449219 680.820129,675.004639 
	C680.012817,674.983215 679.552063,674.970886 679.091248,674.958496 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M528.647095,601.035278 
	C525.121887,605.668274 524.443787,612.541199 518.356934,615.967896 
	C520.679077,610.507935 522.755188,604.595825 528.647095,601.035278 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M697.982727,626.975098 
	C696.232483,627.632446 694.482300,628.289734 692.379028,628.997437 
	C695.138245,625.056885 698.250549,621.066040 701.718628,617.057251 
	C700.896423,619.570618 699.718384,622.101868 698.748474,625.110352 
	C698.632019,626.050049 698.307373,626.512573 697.982727,626.975098 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M834.571106,925.988892 
	C831.908569,926.007263 829.672363,926.007263 827.436096,926.007263 
	C827.246826,925.526611 827.057556,925.046021 826.868286,924.565369 
	C827.890320,923.723206 828.891113,922.212280 829.938904,922.178894 
	C832.087158,922.110474 834.260559,922.834290 836.423645,923.231934 
	C835.948181,924.144775 835.472778,925.057678 834.571106,925.988892 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M682.643555,690.217590 
	C684.915100,695.408081 686.930725,700.831482 688.650085,706.580811 
	C687.260559,706.260132 686.167358,705.613586 685.074097,704.967041 
	C685.058716,704.524780 685.043335,704.082581 685.016296,702.960205 
	C684.364197,701.508240 683.723755,700.736328 683.083313,699.964417 
	C683.072937,699.526001 683.062622,699.087646 683.273315,698.171509 
	C683.338867,697.129944 683.183533,696.566101 683.028198,696.002197 
	C682.814636,694.151672 682.601135,692.301086 682.643555,690.217590 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M784.974670,883.290039 
	C784.978577,883.678284 784.982483,884.066589 784.989014,884.746948 
	C779.349060,885.652161 773.900269,885.316284 769.427002,881.415527 
	C774.170227,881.702820 778.588501,882.368774 783.339844,883.034424 
	C784.106873,883.119446 784.540771,883.204712 784.974670,883.290039 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M751.304260,874.098022 
	C750.026489,873.303223 749.041260,872.508179 748.377808,871.400146 
	C751.126709,871.731506 753.553894,872.375793 756.208862,873.048584 
	C756.436707,873.077209 756.893188,873.123779 756.975464,873.452820 
	C759.054016,874.831177 761.050354,875.880493 763.277222,876.954712 
	C763.507812,876.979614 763.968567,877.032593 763.988770,877.374390 
	C764.024841,877.943909 764.040833,878.171631 764.056763,878.399414 
	C763.254456,878.601318 762.452148,878.803101 761.132935,878.723755 
	C758.073120,877.300110 755.530273,876.157532 752.987366,875.015015 
	C752.523865,874.709229 752.060303,874.403503 751.304260,874.098022 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M817.254822,885.288574 
	C817.017517,884.595581 817.040222,884.188538 817.081726,883.478699 
	C817.523315,883.130798 817.946167,883.085632 818.939941,883.327637 
	C823.678711,883.084412 827.846436,882.554077 832.014160,882.023682 
	C827.900513,886.145081 822.526917,885.121643 817.254822,885.288574 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M832.242188,882.002319 
	C827.846436,882.554077 823.678711,883.084412 819.260010,883.340820 
	C823.443115,881.728943 827.877197,880.391052 832.709351,879.117798 
	C833.058105,879.869324 833.008789,880.556213 832.942505,881.587402 
	C832.925537,881.931702 832.470154,881.980835 832.242188,882.002319 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M795.167725,682.464417 
	C791.811279,679.754456 788.451843,676.693604 785.437378,673.323792 
	C786.856018,673.340454 787.929749,673.666077 789.003479,673.991699 
	C789.003479,673.991760 789.000000,674.000000 789.027588,674.342346 
	C791.038452,676.457153 793.021667,678.229675 795.004883,680.002136 
	C795.058167,680.705933 795.111511,681.409729 795.167725,682.464417 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M804.003723,689.764893 
	C801.980774,688.561646 800.024048,687.005920 798.088623,685.085083 
	C799.663940,684.576477 801.950073,683.746887 802.604980,684.445801 
	C803.659302,685.570923 803.637268,687.704773 804.003723,689.764893 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M783.006775,883.034668 
	C778.588501,882.368774 774.170227,881.702820 769.352051,881.057373 
	C768.590698,880.671692 768.229309,880.265625 768.151489,879.449829 
	C768.668823,878.973755 768.902588,878.907471 769.517334,878.873352 
	C770.923218,878.914246 771.948120,878.922852 773.190796,879.009399 
	C773.408630,879.087341 773.863403,879.172241 773.960327,879.497925 
	C775.048340,880.201843 776.039368,880.580017 777.391907,881.015625 
	C779.504517,881.726929 781.255615,882.380798 783.006775,883.034668 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M807.654053,691.970642 
	C810.248291,694.145935 812.496826,696.283936 814.850220,698.751099 
	C811.317322,696.614624 807.679382,694.149109 804.024414,691.337036 
	C805.107605,691.304810 806.208008,691.619080 807.654053,691.970642 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M720.691406,604.055664 
	C717.938416,606.664368 714.817627,609.296082 711.348389,611.956543 
	C711.754822,606.575195 716.593689,605.980774 720.691406,604.055664 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M544.800171,579.757507 
	C545.989319,583.828308 543.237793,585.202026 540.319580,586.837524 
	C539.941589,583.525024 539.556030,579.850220 544.800171,579.757507 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M850.997437,597.036987 
	C852.136047,596.446960 853.274658,595.856934 854.710999,595.149353 
	C855.556396,596.187256 856.104004,597.342651 856.845581,598.783936 
	C855.607483,600.050659 854.175415,601.031616 852.369019,601.645752 
	C851.662231,599.864868 851.329834,598.450928 850.997437,597.036987 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M329.056946,949.322876 
	C330.792175,949.618774 332.572845,950.263977 334.719757,950.892883 
	C335.510590,950.903748 335.935150,950.930908 336.821960,951.226562 
	C337.859344,951.999756 338.434540,952.504395 339.009766,953.009094 
	C334.455933,955.612183 331.863770,952.390930 329.056946,949.322876 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M690.997559,711.000000 
	C691.595642,712.734619 692.193665,714.469238 692.938049,716.886902 
	C693.397156,718.698730 693.710022,719.827576 694.022827,720.956421 
	C692.350098,717.896484 690.677368,714.836487 689.006348,711.384094 
	C689.008118,710.991638 689.029297,710.970703 689.357117,710.980896 
	C690.122498,710.994080 690.559998,710.997009 690.997559,711.000000 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M753.025635,875.373047 
	C755.530273,876.157532 758.073120,877.300110 760.811646,878.721191 
	C757.683044,879.554199 754.601562,879.518677 753.025635,875.373047 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M795.007568,679.631165 
	C793.021667,678.229675 791.038452,676.457153 789.031738,674.340576 
	C792.549561,673.994324 794.345337,675.982544 795.007568,679.631165 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M683.785461,682.962708 
	C683.514404,684.109436 683.243347,685.256104 682.905762,686.771790 
	C682.839233,687.140747 682.400146,687.095825 682.176514,686.768677 
	C681.647766,685.961914 681.342590,685.482300 681.037415,685.002686 
	C681.016052,683.622253 680.994751,682.241821 681.088196,680.221313 
	C681.474060,679.385681 681.745178,679.190063 682.293823,679.009155 
	C682.934021,679.085571 683.296753,679.147278 683.701538,679.583130 
	C683.757629,680.959106 683.771545,681.960876 683.785461,682.962708 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M789.010132,673.601379 
	C787.929749,673.666077 786.856018,673.340454 785.447754,672.974365 
	C785.113220,672.933899 784.982849,672.523193 784.943970,672.311890 
	C784.940125,671.317383 784.975098,670.534119 785.026184,669.359009 
	C788.064331,668.792297 789.208313,670.376282 789.010132,673.601379 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M525.465393,540.284302 
	C524.495300,541.213806 523.343445,541.830505 522.191589,542.447205 
	C521.908447,541.580078 521.625244,540.712952 521.342102,539.845825 
	C522.655945,539.887695 523.969788,539.929565 525.465393,540.284302 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M731.782471,598.744995 
	C729.904907,600.319946 727.802979,601.622375 725.352478,602.948608 
	C726.073120,599.847656 727.540527,597.302856 731.782471,598.744995 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M743.333984,588.985107 
	C744.139099,586.718262 745.145447,584.184021 748.772461,585.742432 
	C747.233276,587.004944 745.470459,587.998962 743.333984,588.985107 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M533.632202,595.006409 
	C532.566772,596.974792 531.133545,598.949585 529.350159,600.960938 
	C530.421448,599.002625 531.842957,597.007690 533.632202,595.006409 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M685.055115,705.334839 
	C686.167358,705.613586 687.260559,706.260132 688.681396,706.951233 
	C689.038696,707.405579 689.068420,707.815369 689.069092,708.888794 
	C689.036499,710.025208 689.032898,710.497925 689.029297,710.970703 
	C689.029297,710.970703 689.008118,710.991638 688.996887,711.002380 
	C687.669128,709.242920 686.352600,707.472778 685.055115,705.334839 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M681.130615,663.985168 
	C680.674622,663.996521 680.218628,664.007935 679.422119,664.028687 
	C679.464233,661.744080 679.846741,659.450012 680.608643,657.069702 
	C681.018555,657.791138 681.049011,658.598816 681.102905,660.179443 
	C681.127808,661.963318 681.129211,662.974243 681.130615,663.985168 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M806.984680,593.015076 
	C804.990356,592.287109 803.003906,591.550903 800.996216,590.419739 
	C800.975037,590.024841 800.991272,590.008667 801.349976,590.041382 
	C803.471558,591.050842 805.234314,592.027588 806.997131,593.004395 
	C806.997131,593.004395 806.992493,593.006836 806.984680,593.015076 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M710.604736,612.000244 
	C709.224670,613.319580 707.464050,614.639099 705.362976,615.999084 
	C706.756409,614.693176 708.490234,613.346863 710.604736,612.000244 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M790.956177,594.614075 
	C789.441162,594.539551 787.884094,594.111267 786.148376,593.374268 
	C788.175659,591.155457 789.788269,591.700745 790.956177,594.614075 
z"/>
<path fill="#2E1110" opacity="1.000000" stroke="none" 
	d="
M303.372620,912.742554 
	C302.916870,911.151367 302.717834,909.305298 302.817047,907.229980 
	C303.286621,908.829773 303.457977,910.658691 303.372620,912.742554 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M537.612305,590.006104 
	C536.862244,591.572205 535.726868,593.144348 534.295776,594.858276 
	C533.995667,592.638672 533.743652,590.117126 537.612305,590.006104 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M682.016296,678.994507 
	C681.745178,679.190063 681.474060,679.385681 681.083862,679.807129 
	C680.320557,678.607178 679.676331,677.181213 679.061707,675.356934 
	C679.552063,674.970886 680.012817,674.983215 680.824951,675.346313 
	C681.456299,676.796204 681.736267,677.895386 682.016296,678.994507 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M694.264038,720.972900 
	C693.710022,719.827576 693.397156,718.698730 693.094238,717.209229 
	C694.386292,717.996277 695.668579,719.144043 697.003662,720.628418 
	C696.596863,720.975525 696.137207,720.985962 695.333008,721.001831 
	C694.988464,721.007324 694.505249,720.989319 694.264038,720.972900 
z"/>
<path fill="#2E1110" opacity="1.000000" stroke="none" 
	d="
M339.391266,953.006226 
	C338.434540,952.504395 337.859344,951.999756 337.141418,951.240295 
	C338.641724,951.405212 340.284790,951.824890 341.995087,952.573975 
	C341.299133,952.936707 340.535950,952.970032 339.391266,953.006226 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M790.941650,805.646301 
	C790.485413,806.022827 790.066223,806.011658 789.332031,805.999023 
	C789.009583,805.249329 789.002197,804.501160 788.996216,803.378906 
	C789.188904,802.436096 789.380188,801.867310 789.834412,801.135925 
	C790.391113,802.401794 790.684875,803.830200 790.941650,805.646301 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M896.973877,699.335571 
	C897.436768,698.742310 897.909607,698.484619 898.624268,698.114197 
	C899.224792,698.786377 899.583496,699.571289 899.903381,700.705811 
	C899.150696,701.040527 898.436829,701.025696 897.363892,701.012817 
	C896.997803,700.566833 896.990784,700.118958 896.973877,699.335571 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M769.136353,878.841125 
	C768.902588,878.907471 768.668823,878.973755 768.095093,879.176392 
	C766.738342,878.986877 765.721619,878.661011 764.380798,878.367249 
	C764.040833,878.171631 764.024841,877.943909 764.259644,877.346436 
	C764.690613,876.957336 764.870911,876.938049 765.417297,876.945801 
	C766.901062,877.595642 768.018677,878.218384 769.136353,878.841125 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M710.189209,603.258911 
	C710.780396,602.361267 711.561462,601.702820 712.663452,601.017700 
	C712.115967,601.826599 711.247559,602.662354 710.189209,603.258911 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M683.062012,700.321045 
	C683.723755,700.736328 684.364197,701.508240 685.010132,702.628357 
	C684.357300,702.210266 683.699036,701.443909 683.062012,700.321045 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M737.314392,590.979370 
	C737.737122,590.322388 738.517029,589.670044 739.650635,589.022461 
	C739.226746,589.679504 738.449219,590.331726 737.314392,590.979370 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M781.202087,660.825195 
	C780.602966,660.240601 780.275635,659.467529 779.953369,658.359375 
	C780.463501,658.895081 780.968750,659.765930 781.202087,660.825195 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M344.618622,952.979187 
	C344.999084,953.143188 345.000610,953.310486 345.007568,953.728394 
	C344.036285,953.889038 343.059601,953.799011 342.025208,953.392822 
	C342.724884,953.045349 343.482300,953.013977 344.618622,952.979187 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M731.291626,592.950623 
	C731.433228,592.352295 731.898010,591.721863 732.683838,591.060181 
	C732.541504,591.658813 732.078125,592.288635 731.291626,592.950623 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M713.311768,600.951172 
	C713.455688,600.353088 713.920105,599.721680 714.709717,599.066589 
	C714.567444,599.667969 714.099915,600.292969 713.311768,600.951172 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M682.796509,696.145447 
	C683.183533,696.566101 683.338867,697.129944 683.278687,697.842651 
	C682.897095,697.423889 682.730957,696.856323 682.796509,696.145447 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M680.803589,685.166748 
	C681.342590,685.482300 681.647766,685.961914 681.957336,686.742676 
	C681.497742,686.472839 681.033752,685.901794 680.803589,685.166748 
z"/>
<path fill="#871217" opacity="1.000000" stroke="none" 
	d="
M171.006073,989.003662 
	C171.008728,988.558350 171.011383,988.113098 171.399124,987.340149 
	C172.898315,988.006653 174.503296,988.791992 175.032135,990.035095 
	C176.471664,993.418945 178.919037,994.659180 182.197067,993.733948 
	C188.896286,991.842957 195.697739,991.856750 202.546555,991.975525 
	C205.471878,992.026306 209.246429,992.365417 209.507034,988.375000 
	C210.046860,980.109009 208.686554,978.417603 202.921326,977.995483 
	C198.171600,977.647766 196.981110,976.061768 198.792770,971.516724 
	C200.531387,967.154846 201.886932,962.017395 205.096588,959.034912 
	C208.498642,955.873596 209.188171,952.469055 209.453369,948.706299 
	C209.580338,946.904724 207.997696,944.982727 207.189362,943.115234 
	C205.795486,944.690735 203.461838,946.123901 203.197449,947.870422 
	C202.590759,951.878174 201.035858,954.323242 196.946625,955.078918 
	C196.117874,955.231995 195.422836,956.137512 194.678528,956.712158 
	C193.373444,957.719849 192.077988,958.739929 190.778503,959.754822 
	C190.185059,958.169617 189.591614,956.584412 188.998184,954.999207 
	C189.486450,954.411804 189.974701,953.824402 190.729340,953.117249 
	C192.278610,952.427979 194.047470,952.210266 194.759247,951.227173 
	C197.651367,947.232422 200.164017,942.965698 202.926437,938.873413 
	C204.664429,936.298584 206.597321,933.855286 209.226791,930.290039 
	C208.544235,927.664978 209.550507,924.910645 214.673981,924.735413 
	C215.670670,924.701233 217.646255,922.168274 217.385620,921.372742 
	C215.112915,914.436462 215.362671,912.863403 220.696976,907.819458 
	C221.289581,907.259094 221.953918,906.774536 222.657898,906.194824 
	C223.137375,906.593140 223.658279,906.816467 223.815857,907.192200 
	C224.570541,908.991516 224.790665,911.961243 226.005905,912.430603 
	C228.824936,913.519531 232.105911,913.628601 235.203812,913.655762 
	C236.212448,913.664673 237.917343,912.341797 238.135529,911.366577 
	C239.282135,906.242126 240.181961,901.049744 240.833466,895.839294 
	C240.944550,894.950867 239.604736,893.641296 238.645767,892.901550 
	C235.090347,890.159058 235.170319,889.511536 239.990265,887.028503 
	C240.524872,887.969055 240.842926,889.308838 241.662186,889.783508 
	C242.833740,890.462219 244.666840,891.128418 245.671326,890.651001 
	C246.693192,890.165405 247.493118,888.332214 247.629532,887.010559 
	C248.376587,879.773010 255.107361,873.052429 262.214966,873.165283 
	C263.479736,873.185364 265.595978,874.870544 265.789368,876.039795 
	C266.250366,878.827026 266.455444,882.125305 269.820923,881.731995 
	C273.185791,881.338745 277.108978,881.892639 279.329498,877.106506 
	C282.164398,870.996216 286.545410,865.603210 290.260040,859.901062 
	C290.823456,860.069397 291.386841,860.237732 291.950256,860.406006 
	C291.550140,862.177734 291.690338,864.400818 290.654144,865.641052 
	C286.674744,870.404053 283.410065,875.123413 285.914886,881.787292 
	C286.332458,882.898193 285.928101,884.446289 285.572723,885.698425 
	C283.414246,893.303955 283.444153,900.678894 285.334656,908.531555 
	C286.390289,912.916321 284.550049,918.215698 283.158051,922.845459 
	C282.715179,924.318542 279.512451,924.961792 276.597015,926.499146 
	C283.111786,928.002380 281.772186,932.835815 282.058990,937.188782 
	C282.375671,941.995850 281.246857,945.763123 276.091766,947.542297 
	C277.585266,950.023804 278.647369,952.506470 280.364532,954.392822 
	C281.535706,955.679321 283.670380,956.844543 285.284332,956.735352 
	C286.599487,956.646362 288.032623,954.756042 288.968933,953.365173 
	C290.803741,950.640015 292.459778,947.770752 293.910522,944.823669 
	C294.414307,943.800293 294.529449,942.173950 294.073792,941.158997 
	C291.765167,936.016113 295.329132,932.879944 297.238617,929.165527 
	C297.868073,928.998901 298.497528,928.832275 299.126984,928.665649 
	C301.082611,932.994202 303.883698,937.165894 304.725555,941.701172 
	C305.395508,945.310425 306.641876,946.326355 309.802002,947.621765 
	C316.698730,950.449097 323.341309,953.983276 329.820251,957.695557 
	C332.081024,958.990906 333.180573,962.651489 335.459808,963.512024 
	C342.116821,966.025391 349.026520,966.323730 356.184387,962.297852 
	C360.358154,962.556824 363.907013,963.471130 367.467651,963.519592 
	C370.320374,963.558472 373.187927,962.092773 376.047943,962.096130 
	C381.671539,962.102844 387.294098,962.948669 392.917725,962.956238 
	C447.245453,963.029175 501.578857,963.485718 555.899536,962.863220 
	C610.106689,962.242004 664.353943,965.427551 718.534912,961.157227 
	C724.789368,960.664307 731.212402,962.891541 737.560303,962.911621 
	C787.888000,963.070435 838.216431,963.016785 888.544617,962.961792 
	C891.836853,962.958191 895.155884,961.989014 898.414001,962.203796 
	C903.448547,962.535767 908.741272,962.674866 913.393188,964.361389 
	C925.468567,968.739258 934.614502,966.075500 942.377563,954.959717 
	C952.909546,952.080811 961.703796,947.788452 968.847656,939.151672 
	C975.177246,931.499268 979.864807,923.806641 981.388306,913.948975 
	C982.209473,913.673096 983.020020,913.463867 983.030151,913.221191 
	C983.251343,907.928467 983.445557,902.631592 983.421509,897.336609 
	C983.419495,896.887451 981.845947,896.445374 980.999146,895.625610 
	C980.994751,894.502380 980.993591,893.753601 981.259033,892.783936 
	C981.020264,891.375427 980.515015,890.187744 980.004456,888.583801 
	C979.994568,886.780823 979.989929,885.394104 980.257446,883.790039 
	C981.551880,880.863281 982.699524,878.339966 986.489624,879.373352 
	C983.701721,887.592468 986.216370,895.909912 991.356934,896.359741 
	C992.100037,892.487488 993.115417,888.656860 993.510193,884.763245 
	C993.984131,880.089111 994.134521,875.357239 994.038025,870.658264 
	C993.961121,866.915405 995.326172,864.996582 999.139404,865.000366 
	C1006.104980,865.007324 1013.071045,865.047363 1020.036072,864.985291 
	C1025.837402,864.933655 1026.866211,863.843079 1027.344849,857.968262 
	C1028.789917,857.624390 1029.890015,857.312195 1030.990234,857.000000 
	C1036.643921,867.100281 1042.177490,877.270264 1047.997070,887.274109 
	C1051.386841,893.101318 1055.229004,898.665649 1058.877563,904.341858 
	C1061.988770,909.182190 1065.254517,913.931824 1068.145508,918.899841 
	C1068.981445,920.336426 1068.759644,922.388489 1068.717896,924.720947 
	C1066.692383,925.878418 1064.971802,926.473328 1063.741211,926.898804 
	C1063.474121,931.136536 1064.622803,936.091614 1062.696899,939.035522 
	C1057.340210,947.223938 1062.249512,952.429810 1066.605713,958.045105 
	C1067.591064,959.315369 1069.253296,960.772827 1070.678467,960.855103 
	C1074.855347,961.096252 1076.095337,963.197754 1076.032104,966.994019 
	C1075.918701,973.808899 1075.837524,980.633728 1076.119629,987.438843 
	C1076.187500,989.076721 1077.479492,991.796875 1078.640625,992.052063 
	C1082.731934,992.950989 1087.008057,993.036865 1091.220093,993.348267 
	C1094.358398,993.580139 1097.625610,993.211670 1100.618408,993.968567 
	C1104.061279,994.839233 1107.265381,996.654602 1111.009033,998.244934 
	C1110.772461,1000.017822 1109.721191,1002.386169 1110.363037,1004.131836 
	C1112.189331,1009.098694 1108.947754,1009.912781 1105.746826,1009.887451 
	C1089.829834,1009.761353 1073.915405,1009.326294 1058.080444,1008.618530 
	C1058.819946,1007.832764 1059.474976,1007.083191 1060.138550,1007.075684 
	C1070.825562,1006.954590 1081.521362,1006.665283 1092.198242,1006.984253 
	C1098.032349,1007.158508 1103.348633,1005.072998 1104.887085,999.996216 
	C1098.338013,999.996216 1092.023438,999.995789 1085.708862,999.996399 
	C1076.295288,999.997253 1066.879761,1000.119141 1057.468506,999.972290 
	C1042.843384,999.744080 1028.222656,999.208069 1013.597351,999.035828 
	C999.511292,998.869995 985.421814,999.000000 971.333801,999.000000 
	C964.254333,999.000000 957.174805,999.000000 950.095337,999.000000 
	C915.596252,999.000000 881.096863,998.935303 846.598328,999.070740 
	C841.506836,999.090759 836.410034,1000.574951 831.331299,1000.486450 
	C818.561951,1000.263916 805.800842,999.073059 793.037659,999.091980 
	C763.580872,999.135681 734.125366,999.784241 704.668091,999.937561 
	C694.046570,999.992859 683.416199,998.943176 672.798889,999.082642 
	C651.871033,999.357483 630.951233,1000.437683 610.024780,1000.549011 
	C596.601196,1000.620300 583.175781,999.255920 569.743774,999.070679 
	C549.914734,998.797119 530.078186,998.894165 510.247009,999.074646 
	C505.317078,999.119446 500.385712,1000.621216 495.472137,1000.530029 
	C483.371399,1000.305725 471.284698,999.228149 459.184875,999.071350 
	C439.022125,998.809875 418.852234,998.877502 398.688324,999.090393 
	C393.940491,999.140503 389.189697,1000.968262 384.465973,1000.859741 
	C370.711182,1000.543457 356.975006,999.349670 343.220703,999.102234 
	C332.939819,998.917236 322.644196,999.837036 312.351501,999.958557 
	C298.274170,1000.124634 284.191986,1000.124390 270.114655,999.958435 
	C259.906860,999.838074 249.705109,999.115601 239.498016,999.045105 
	C217.999603,998.896606 196.499435,999.000000 174.748077,998.721619 
	C173.110901,998.114258 171.725662,997.785278 170.219482,997.427612 
	C170.495895,994.467407 170.750992,991.735535 171.006073,989.003662 
M797.500000,976.000000 
	C807.832764,976.000000 818.166504,976.090576 828.498108,975.982239 
	C854.953735,975.704712 881.408447,975.334717 907.863647,975.014160 
	C910.840027,974.978088 913.882568,974.871460 913.735046,970.796936 
	C913.559082,965.935364 911.481628,964.383606 906.627808,965.065186 
	C904.502380,965.363586 902.382324,965.947571 900.256836,965.962036 
	C844.302612,966.343140 788.348145,966.698914 732.393250,966.951660 
	C726.604675,966.977844 720.812317,966.030151 715.023010,966.044983 
	C609.070618,966.316833 503.118500,966.670898 397.166290,966.994568 
	C384.086609,967.034485 371.006073,966.944641 357.927856,967.088074 
	C356.646881,967.102112 355.379364,968.339417 354.105591,969.008972 
	C355.342255,970.012085 356.442383,971.331726 357.849854,971.938965 
	C359.645386,972.713684 361.745789,972.748474 363.615143,973.391846 
	C368.803223,975.177429 373.864594,977.411926 379.384613,974.202576 
	C380.286377,973.678223 381.934845,974.419006 383.234497,974.601562 
	C386.497040,975.059631 389.757294,975.939758 393.018860,975.941406 
	C527.512573,976.009888 662.006287,976.000000 797.500000,976.000000 
M664.500000,984.000000 
	C575.055176,983.666687 485.610382,983.327759 396.165497,983.006287 
	C383.500732,982.960754 370.832428,983.162170 358.172424,982.912109 
	C353.764648,982.825134 353.315613,985.480713 353.042786,988.550720 
	C352.739624,991.961975 354.275879,993.912109 357.893005,994.008728 
	C362.844452,994.140869 367.801605,994.636169 372.742035,994.490845 
	C384.478729,994.145630 396.202820,993.349243 407.940033,993.051208 
	C417.765686,992.801697 427.602722,992.989075 437.434692,993.003296 
	C445.765686,993.015320 454.102448,992.869080 462.426208,993.128540 
	C475.013397,993.520874 487.590210,994.596497 500.173950,994.639648 
	C510.090363,994.673645 520.010315,993.108582 529.929260,993.101929 
	C549.383484,993.088806 568.838318,993.943420 588.292480,993.927063 
	C610.580139,993.908325 632.866821,993.130554 655.154968,993.070984 
	C670.109070,993.031067 685.065002,993.929626 700.020020,993.926819 
	C715.308472,993.923950 730.597412,993.036804 745.884949,993.071716 
	C767.173157,993.120361 788.459778,993.885986 809.747864,993.927917 
	C825.702148,993.959412 841.656799,993.287842 857.612732,993.011108 
	C879.399475,992.633057 901.187622,992.343323 922.974487,991.973145 
	C924.280884,991.950989 925.580933,991.552917 926.883911,991.329834 
	C926.888916,990.775146 926.893921,990.220520 926.898926,989.665894 
	C924.958374,989.443970 922.998169,989.327881 921.082153,988.973206 
	C918.688416,988.530090 915.728699,988.594177 914.085571,987.187134 
	C910.845337,984.412476 907.414062,983.958984 903.467590,983.961548 
	C824.145081,984.012939 744.822510,984.000000 664.500000,984.000000 
M1072.601807,978.433289 
	C1068.909058,974.987183 1065.667114,975.847351 1064.032837,980.152344 
	C1062.269897,984.796387 1059.817627,989.178650 1057.310059,994.419250 
	C1060.112549,994.289368 1063.668823,994.274902 1067.191040,993.920959 
	C1071.101196,993.528259 1073.720947,991.894775 1073.059570,987.200745 
	C1072.691650,984.589966 1072.927734,981.894104 1072.601807,978.433289 
M276.855377,898.590149 
	C277.742828,898.630554 278.630249,898.671021 279.517700,898.711426 
	C279.201630,897.228699 278.885529,895.746033 278.569458,894.263306 
	C278.081024,894.322449 277.592621,894.381592 277.104187,894.440735 
	C276.914642,895.627075 276.725098,896.813416 276.855377,898.590149 
M1057.682251,926.786682 
	C1056.999268,926.703186 1056.316406,926.619629 1055.633423,926.536133 
	C1055.727783,927.085510 1055.822021,927.634949 1055.916260,928.184387 
	C1056.561157,927.925354 1057.205933,927.666321 1057.682251,926.786682 
M276.957428,993.774353 
	C276.655823,993.213318 276.354218,992.652283 276.052582,992.091187 
	C275.552551,992.362244 275.052490,992.633301 274.552460,992.904358 
	C275.137909,993.245789 275.723328,993.587158 276.957428,993.774353 
z"/>
<path fill="#851314" opacity="1.000000" stroke="none" 
	d="
M941.997559,955.007324 
	C934.614502,966.075500 925.468567,968.739258 913.393188,964.361389 
	C908.741272,962.674866 903.448547,962.535767 898.414001,962.203796 
	C895.155884,961.989014 891.836853,962.958191 888.544617,962.961792 
	C838.216431,963.016785 787.888000,963.070435 737.560303,962.911621 
	C731.212402,962.891541 724.789368,960.664307 718.534912,961.157227 
	C664.353943,965.427551 610.106689,962.242004 555.899536,962.863220 
	C501.578857,963.485718 447.245453,963.029175 392.917725,962.956238 
	C387.294098,962.948669 381.671539,962.102844 376.047943,962.096130 
	C373.187927,962.092773 370.320374,963.558472 367.467651,963.519592 
	C363.907013,963.471130 360.358154,962.556824 356.403259,962.009155 
	C352.283936,962.334717 348.563446,962.645325 344.847778,963.005615 
	C340.092712,963.466614 336.890442,961.795715 336.041321,956.379395 
	C343.180969,957.118835 350.338684,958.421509 357.550812,959.251465 
	C360.918121,959.638977 364.390594,959.017151 367.815552,959.013977 
	C395.641510,958.987793 423.467773,958.925354 451.293213,959.045898 
	C459.583252,959.081787 467.872437,959.980957 476.159515,959.942871 
	C525.437927,959.716797 574.715210,959.223206 623.993713,959.060974 
	C640.445679,959.006775 656.900940,959.984558 673.353210,959.940369 
	C750.790649,959.732361 828.228027,959.119629 905.663940,959.301392 
	C914.300598,959.321655 922.244568,955.655090 930.791138,956.767578 
	C931.087036,956.806030 931.474426,956.141296 932.243652,955.407837 
	C933.112122,955.006836 933.556091,955.002197 934.145569,955.224365 
	C934.860229,955.294312 935.429260,955.137512 936.330078,954.983826 
	C937.104187,954.983398 937.546448,954.979797 938.389893,954.985718 
	C939.859863,954.999207 940.928711,955.003235 941.997559,955.007324 
z"/>
<path fill="#563233" opacity="1.000000" stroke="none" 
	d="
M527.999207,505.962433 
	C527.165955,505.906830 526.332703,505.851227 525.254822,505.519012 
	C525.155640,504.600494 525.147949,503.866852 525.469421,503.330444 
	C530.724731,494.562012 536.116821,485.874268 541.278625,477.051575 
	C545.314758,470.152985 549.024536,463.064331 552.974548,456.114258 
	C557.278748,448.540985 561.565857,440.952972 566.088745,433.510101 
	C572.918152,422.271667 579.969177,411.168152 586.882019,399.980133 
	C594.139893,388.233765 601.294067,376.423065 608.605225,364.710144 
	C610.895935,361.040283 613.548218,357.596069 616.034973,354.048553 
	C616.034973,354.048553 616.006165,354.004700 616.365356,353.962860 
	C621.254456,348.480103 627.726868,345.786377 634.049194,344.230194 
	C646.677368,341.121796 658.329651,343.093231 669.052429,352.014984 
	C676.162415,357.930725 680.441895,365.536285 685.603821,372.610809 
	C690.504395,379.327148 694.227295,386.903595 698.450989,394.111847 
	C698.953979,394.970306 699.328552,395.902893 699.798340,396.782074 
	C703.805603,404.280975 707.639832,411.880249 711.891113,419.238403 
	C715.343506,425.213745 719.221497,430.950836 723.089050,436.673035 
	C724.013550,438.040924 725.647339,438.929382 726.954956,440.038330 
	C727.963989,442.446411 728.973022,444.854492 729.992432,448.009216 
	C733.148560,454.635681 736.223083,460.555054 739.460266,466.384155 
	C742.643860,472.116791 745.976501,477.767120 749.272827,483.436646 
	C752.726624,489.376923 756.470642,495.165466 759.609619,501.266388 
	C762.257629,506.413025 767.566467,510.369995 766.972900,517.031128 
	C766.476257,516.481323 765.979553,515.931519 765.209412,514.881592 
	C763.632812,513.911255 762.329651,513.441162 760.780762,512.973694 
	C760.535034,512.976379 760.044189,512.981262 760.021484,512.604492 
	C758.684875,509.815399 757.370972,507.402985 756.025391,504.685486 
	C755.703857,503.867584 755.414062,503.354767 755.102905,502.505249 
	C754.482849,501.333374 753.884094,500.498169 753.243225,499.307861 
	C750.568176,494.243530 747.935242,489.534363 745.182373,484.530334 
	C744.420715,483.779297 743.779114,483.323090 743.100037,482.658234 
	C743.062622,482.449554 742.927368,482.047699 742.965454,481.683289 
	C738.437256,473.464752 733.870911,465.610657 729.257690,457.466949 
	C729.120422,456.796387 729.029968,456.415466 729.005859,455.659973 
	C728.865723,454.489990 728.832581,453.590576 728.426941,452.914734 
	C722.477417,443.002716 716.504700,433.104065 710.430481,423.268341 
	C710.028809,422.617859 708.764526,422.500092 707.794434,421.907776 
	C707.427246,421.477905 707.167053,421.274780 706.948303,420.708557 
	C703.638245,414.709717 700.243164,409.099335 696.945618,403.432220 
	C691.973328,394.887054 687.122375,386.270996 682.115112,377.746582 
	C679.321045,372.989807 676.315247,368.357422 673.317688,363.332275 
	C667.977356,358.615509 662.618225,354.355347 657.495056,349.828156 
	C649.130249,342.436371 629.823181,345.454926 622.302124,355.662628 
	C619.441223,357.495911 616.092102,358.711395 614.563599,361.094635 
	C607.416626,372.237946 600.633972,383.623871 594.020752,395.095856 
	C591.660706,399.189789 590.145752,403.770905 588.129517,408.383057 
	C587.854370,408.971710 587.694824,409.306946 587.231812,409.753113 
	C585.108521,411.943237 582.990295,413.832458 581.523804,416.136536 
	C576.768921,423.607239 572.209656,431.204285 567.682312,438.816467 
	C566.795105,440.308258 566.388306,442.085724 565.498535,443.918854 
	C564.746216,445.040710 564.255188,445.975098 563.440186,446.949005 
	C562.378784,447.941986 561.641296,448.895447 560.828369,450.196075 
	C560.035461,452.303467 559.318054,454.063660 558.295410,455.937134 
	C556.306885,459.018066 554.623596,461.985687 552.873535,465.156677 
	C552.806824,465.360016 552.716370,465.778381 552.413696,465.894592 
	C550.288330,468.993561 548.465698,471.976318 546.623413,475.298401 
	C545.939026,477.359100 545.274414,479.080475 544.310669,480.912781 
	C543.019958,482.700256 542.028320,484.376801 540.913086,486.364594 
	C540.412842,487.724976 540.036133,488.774109 539.376465,489.917480 
	C538.729614,490.679749 538.365784,491.347778 537.945923,492.298035 
	C537.603699,493.061035 537.317566,493.541870 536.697876,494.051819 
	C535.991638,494.771454 535.618835,495.462036 535.184021,496.447693 
	C535.058777,497.139130 534.995667,497.535431 534.581665,498.008179 
	C533.828491,499.380768 533.426270,500.676971 532.662231,501.983459 
	C530.866699,503.316650 529.432983,504.639557 527.999207,505.962433 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M615.662659,354.069580 
	C613.548218,357.596069 610.895935,361.040283 608.605225,364.710144 
	C601.294067,376.423065 594.139893,388.233765 586.882019,399.980133 
	C579.969177,411.168152 572.918152,422.271667 566.088745,433.510101 
	C561.565857,440.952972 557.278748,448.540985 552.974548,456.114258 
	C549.024536,463.064331 545.314758,470.152985 541.278625,477.051575 
	C536.116821,485.874268 530.724731,494.562012 525.469421,503.330444 
	C525.147949,503.866852 525.155640,504.600494 525.003845,505.612427 
	C524.989380,506.149963 524.981201,506.317383 524.616333,506.797485 
	C523.835693,508.069031 523.411804,509.027832 522.987976,509.986664 
	C522.987976,509.986664 523.000000,510.000000 522.668213,510.041382 
	C521.906067,510.748138 521.475769,511.413544 521.045471,512.078918 
	C519.898621,514.288513 518.751770,516.498169 516.921631,518.856079 
	C515.483032,519.004272 514.727600,519.004211 513.972229,519.004150 
	C515.904114,514.914124 517.804565,510.808746 519.797363,506.748627 
	C520.149597,506.030884 521.030640,505.582062 521.424622,504.873962 
	C525.592896,497.381195 529.535034,489.756836 533.885925,482.372894 
	C540.327026,471.441650 547.212585,460.771301 553.602844,449.811401 
	C559.603027,439.520416 565.141418,428.961060 571.034912,418.606262 
	C572.300049,416.383423 574.339600,414.615326 575.796997,412.483582 
	C577.037048,410.669678 577.992798,408.654633 579.003235,406.691925 
	C581.147583,402.526855 582.927063,398.133942 585.440674,394.208801 
	C588.832886,388.911804 593.065979,384.148041 596.386597,378.812103 
	C598.894348,374.782318 599.857605,369.678711 602.738708,366.022614 
	C606.272217,361.538483 608.934998,355.742096 615.662659,354.069580 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M726.965698,439.652222 
	C725.647339,438.929382 724.013550,438.040924 723.089050,436.673035 
	C719.221497,430.950836 715.343506,425.213745 711.891113,419.238403 
	C707.639832,411.880249 703.805603,404.280975 699.798340,396.782074 
	C699.328552,395.902893 698.953979,394.970306 698.450989,394.111847 
	C694.227295,386.903595 690.504395,379.327148 685.603821,372.610809 
	C680.441895,365.536285 676.162415,357.930725 669.052429,352.014984 
	C658.329651,343.093231 646.677368,341.121796 634.049194,344.230194 
	C627.726868,345.786377 621.254456,348.480103 616.387329,353.977417 
	C615.570923,350.365692 617.149780,348.054962 620.567749,346.699249 
	C626.185913,344.470825 631.575745,340.762604 637.357605,340.037842 
	C649.645630,338.497406 661.569458,340.886536 671.927795,348.309967 
	C673.871826,349.703186 675.423096,351.821655 676.747803,353.869232 
	C680.960327,360.380341 684.984863,367.013214 689.071289,373.605682 
	C690.113281,375.286621 691.056030,377.031555 692.146179,378.679718 
	C697.461853,386.716431 703.103577,394.555298 708.059753,402.806641 
	C712.171204,409.651611 715.430176,417.004547 719.182007,424.071228 
	C721.400330,428.249451 723.876709,432.290039 726.155518,436.437317 
	C726.617004,437.277069 726.712402,438.317963 726.965698,439.652222 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M766.977173,517.019897 
	C767.566467,510.369995 762.257629,506.413025 759.609619,501.266388 
	C756.470642,495.165466 752.726624,489.376923 749.272827,483.436646 
	C745.976501,477.767120 742.643860,472.116791 739.460266,466.384155 
	C736.223083,460.555054 733.148560,454.635681 730.001404,448.379150 
	C733.972717,452.470032 738.481567,456.579895 741.768066,461.505371 
	C745.084900,466.476166 747.270874,472.218201 749.804382,477.690826 
	C753.170227,484.961334 757.798584,491.406952 762.128845,498.103027 
	C768.896667,508.568512 774.487488,519.803101 780.427368,530.792786 
	C783.564270,536.596436 786.208862,542.669800 789.432739,548.421326 
	C790.770996,550.808899 793.523621,552.418762 794.797180,554.824768 
	C798.495728,561.811951 801.697144,569.060547 805.269775,576.117432 
	C806.066956,577.692078 807.716919,578.810669 808.745300,580.297485 
	C811.235596,583.897705 813.590820,587.591370 815.645508,591.586426 
	C813.491150,590.051514 811.795776,588.060364 809.858276,586.342102 
	C808.404297,585.052612 806.639038,584.114197 805.012329,583.019531 
	C805.012329,583.019531 804.923401,582.573975 804.941895,581.955566 
	C799.313965,571.084839 793.703857,560.812134 787.953064,550.618774 
	C787.487732,549.793884 786.057617,549.513367 785.075806,548.979858 
	C784.124512,546.891235 783.173157,544.802673 782.072144,542.045166 
	C780.950806,540.257324 779.979065,539.138428 779.007324,538.019531 
	C776.666626,533.295776 774.325928,528.571960 771.943481,523.130493 
	C770.257751,520.616211 768.613831,518.819763 766.969849,517.023315 
	C766.969849,517.023315 766.981384,517.008667 766.977173,517.019897 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M804.985229,583.346497 
	C806.639038,584.114197 808.404297,585.052612 809.858276,586.342102 
	C811.795776,588.060364 813.491150,590.051514 815.654541,591.957642 
	C826.226685,599.035156 836.721619,598.832947 847.478455,593.317810 
	C853.023621,590.474792 858.633972,587.759033 864.606201,584.992920 
	C861.331238,587.625122 857.839294,590.553284 853.949951,592.790955 
	C849.986938,595.070801 845.632996,596.671326 840.880615,598.837097 
	C839.567688,599.132141 838.824341,599.159363 837.610168,599.123291 
	C828.400818,599.304016 819.690125,599.484131 812.388916,593.365662 
	C811.869080,592.930115 810.806458,593.142212 809.996216,593.053223 
	C809.996216,593.053223 809.512329,593.010132 809.231689,592.642456 
	C807.602417,589.844971 806.253662,587.415222 804.904907,584.985474 
	C804.922668,584.548157 804.940369,584.110779 804.985229,583.346497 
z"/>
<path fill="#6E2026" opacity="1.000000" stroke="none" 
	d="
M509.092163,530.158997 
	C508.591492,531.667603 508.090790,533.176270 507.270966,534.842896 
	C506.512573,534.999878 506.073273,534.998901 505.303589,534.994263 
	C504.890503,528.774902 507.506195,523.883423 512.651184,520.429077 
	C513.016174,521.887573 513.030212,522.978088 513.044312,524.068665 
	C513.044312,524.068665 513.037598,524.539246 512.657288,524.809814 
	C511.215332,526.773193 510.153748,528.466064 509.092163,530.158997 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M513.374146,523.998413 
	C513.030212,522.978088 513.016174,521.887573 513.002441,520.388428 
	C513.316101,519.879639 513.569763,519.696716 513.868042,519.217651 
	C514.727600,519.004211 515.483032,519.004272 516.615295,519.008301 
	C515.896118,520.650879 514.800049,522.289551 513.374146,523.998413 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M170.695221,989.160278 
	C170.750992,991.735535 170.495895,994.467407 170.219482,997.427612 
	C171.725662,997.785278 173.110901,998.114258 174.489563,999.003174 
	C173.509750,999.711365 172.402023,999.616699 171.594345,1000.063965 
	C170.341064,1000.757935 169.292236,1001.821106 168.156677,1002.727661 
	C169.285721,1004.031189 170.265305,1006.265442 171.570084,1006.474915 
	C176.846191,1007.322327 182.216415,1007.823120 187.563065,1007.939758 
	C197.302490,1008.152161 207.052109,1007.870117 216.793304,1008.037598 
	C226.004715,1008.195923 235.217682,1009.075378 244.417801,1008.919434 
	C267.262970,1008.532349 290.073486,1009.063171 312.970612,1007.443726 
	C336.525879,1005.777771 360.332184,1007.694702 384.029297,1007.992798 
	C412.810699,1008.354919 441.592224,1008.776794 470.374969,1008.934082 
	C480.406494,1008.988953 490.439636,1008.191589 500.476013,1008.030334 
	C526.424438,1007.613464 552.374512,1007.219482 578.325256,1007.099243 
	C583.366516,1007.075867 588.415771,1008.926208 593.457947,1008.909119 
	C649.066528,1008.720520 704.674133,1008.234741 760.282715,1008.059631 
	C777.067200,1008.006714 793.853027,1008.843262 810.640015,1008.931030 
	C820.091431,1008.980530 829.546570,1008.333374 839.294067,1008.296936 
	C840.231995,1008.726868 840.874146,1008.966370 841.520142,1008.977539 
	C861.962891,1009.332031 882.405090,1009.756287 902.849426,1009.969360 
	C917.837585,1010.125549 932.828735,1010.032288 947.818054,1009.955994 
	C949.546936,1009.947205 951.272766,1009.333374 953.468628,1009.000000 
	C988.624878,1009.000000 1023.312439,1009.000000 1058.000000,1009.000000 
	C1073.915405,1009.326294 1089.829834,1009.761353 1105.746826,1009.887451 
	C1108.947754,1009.912781 1112.189331,1009.098694 1110.363037,1004.131836 
	C1109.721191,1002.386169 1110.772461,1000.017822 1111.009033,998.244934 
	C1107.265381,996.654602 1104.061279,994.839233 1100.618408,993.968567 
	C1097.625610,993.211670 1094.358398,993.580139 1091.220093,993.348267 
	C1087.008057,993.036865 1082.731934,992.950989 1078.640625,992.052063 
	C1077.479492,991.796875 1076.187500,989.076721 1076.119629,987.438843 
	C1075.837524,980.633728 1075.918701,973.808899 1076.032104,966.994019 
	C1076.095337,963.197754 1074.855347,961.096252 1070.678467,960.855103 
	C1069.253296,960.772827 1067.591064,959.315369 1066.605713,958.045105 
	C1062.249512,952.429810 1057.340210,947.223938 1062.696899,939.035522 
	C1064.622803,936.091614 1063.474121,931.136536 1063.741211,926.898804 
	C1064.971802,926.473328 1066.692383,925.878418 1068.720703,925.133484 
	C1071.917847,928.119324 1074.807251,931.255127 1077.457397,934.689575 
	C1075.442749,934.351685 1073.667480,933.715149 1071.182129,932.824097 
	C1071.182129,934.643860 1070.914917,935.955139 1071.228271,937.108765 
	C1072.597900,942.150330 1073.899048,947.236023 1075.747314,952.107605 
	C1076.356079,953.712280 1078.537598,954.720154 1080.005493,956.383667 
	C1081.194092,961.080872 1084.259644,965.096558 1082.064209,969.959229 
	C1081.895752,970.332520 1082.036987,971.047791 1082.307373,971.385803 
	C1085.085938,974.859741 1087.578003,978.699524 1090.932861,981.499146 
	C1092.054321,982.434875 1095.491699,980.864258 1097.704834,980.009888 
	C1098.182129,979.825623 1098.456787,977.474548 1097.953491,976.659790 
	C1094.832275,971.607239 1091.411865,966.740784 1088.207153,961.738037 
	C1087.275635,960.284058 1086.725098,958.586121 1085.963135,956.639465 
	C1083.290771,950.176208 1080.655151,944.073425 1078.019531,937.970642 
	C1079.155273,939.268860 1080.476318,940.448425 1081.396118,941.884888 
	C1086.967529,950.585754 1092.560791,959.276917 1097.898315,968.121155 
	C1099.841797,971.341492 1100.734375,975.207764 1102.745850,978.372925 
	C1108.511475,987.445496 1114.605713,996.309082 1120.567993,1005.256714 
	C1123.028442,1008.949036 1121.689453,1011.643555 1117.194946,1011.985962 
	C1116.035034,1012.074280 1114.862915,1012.004578 1113.696411,1012.004578 
	C798.563477,1012.002869 483.430542,1012.000854 168.297592,1011.998840 
	C167.797638,1011.998840 167.257797,1012.122864 166.804443,1011.977417 
	C164.470215,1011.228577 160.982498,1011.029602 160.151688,1009.451721 
	C159.223190,1007.688354 160.079880,1004.280029 161.277618,1002.245850 
	C163.943314,997.718384 167.305862,993.601196 170.695221,989.160278 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M965.611877,744.008179 
	C965.956360,744.063721 965.971008,744.529480 965.978149,744.762329 
	C967.547180,751.205261 969.473999,757.202698 975.621704,760.986694 
	C976.278015,768.687073 980.539490,774.488159 984.785645,780.387817 
	C986.852966,783.260132 988.136536,786.683838 990.011169,789.711792 
	C991.347473,791.870300 993.158630,793.729309 994.602966,795.828491 
	C995.544983,797.197571 996.209045,798.757812 996.659119,800.567261 
	C989.059998,794.750549 981.815552,788.632385 979.176208,778.810303 
	C978.937744,777.922791 976.542419,777.343140 975.087463,777.185425 
	C974.488403,777.120544 973.066589,778.671814 973.177307,779.275757 
	C973.408630,780.537964 974.363037,781.689514 975.106201,782.830627 
	C976.024902,784.241150 977.368896,785.473145 977.919678,787.001831 
	C978.740906,789.281189 978.780273,791.841309 979.594604,794.124268 
	C981.485535,799.425781 980.485413,801.280823 974.704956,800.871643 
	C970.877380,800.600830 967.136719,799.103821 962.405029,797.916931 
	C961.769897,799.832764 960.663147,802.525208 960.009705,805.323486 
	C959.357483,808.117004 958.350830,810.713013 955.206726,810.706604 
	C948.482910,810.692688 948.008240,815.107544 947.971313,820.049988 
	C946.647400,816.372864 944.473999,812.767822 944.154419,809.005310 
	C943.076538,796.316406 943.541870,783.442993 941.716858,770.887207 
	C940.152344,760.123718 938.467346,749.029480 932.657959,739.345703 
	C927.146484,730.158386 921.627441,720.970520 915.827271,711.966064 
	C914.671936,710.172485 912.222839,709.217224 910.382385,707.859192 
	C903.318420,702.646545 901.206909,695.111816 901.058533,686.922241 
	C900.841675,674.950867 900.882141,662.970581 901.107849,650.999634 
	C901.144836,649.037964 902.695862,647.104858 903.547119,645.158569 
	C904.739807,646.722473 905.992859,648.245239 907.099609,649.867737 
	C907.650452,650.675293 907.706421,651.904175 908.371826,652.537170 
	C916.263367,660.044495 919.048340,670.694885 925.028442,679.359680 
	C926.313293,681.221375 928.119812,682.723083 929.838135,684.695312 
	C930.755371,686.633972 931.520447,688.267944 932.631714,689.965515 
	C932.984070,690.770203 932.990173,691.511292 933.004211,692.623169 
	C937.867737,701.020203 942.724121,709.045898 947.578064,717.073120 
	C948.761780,719.030884 949.862305,721.043640 951.130310,722.944946 
	C955.813416,729.966980 960.550598,736.953003 965.611877,744.008179 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M1030.853271,856.675903 
	C1029.890015,857.312195 1028.789917,857.624390 1027.340576,857.601929 
	C1027.187500,853.764526 1020.398010,851.972290 1024.105225,848.459534 
	C1021.458740,844.613220 1019.153564,841.170959 1016.730896,837.813477 
	C1016.199646,837.077271 1015.326416,836.322144 1014.481262,836.122009 
	C1009.530762,834.949707 1009.790466,830.932922 1009.407776,827.222107 
	C1009.283264,826.015625 1009.295715,824.707397 1008.783142,823.675354 
	C1008.599731,823.306091 1006.539124,823.251648 1006.231567,823.686890 
	C1005.538147,824.668152 1005.066895,826.062378 1005.129272,827.255920 
	C1005.204651,828.696472 1005.526733,830.473877 1006.440125,831.456909 
	C1009.449707,834.695679 1009.349670,838.112793 1008.362061,842.086243 
	C1008.115662,843.077698 1010.322021,845.828552 1011.311096,845.783813 
	C1016.353333,845.555420 1016.987854,848.339111 1017.136108,852.364441 
	C1017.193420,853.919617 1018.428955,856.176331 1019.738098,856.772034 
	C1021.897827,857.754700 1024.553467,857.647705 1027.000000,858.000000 
	C1026.866211,863.843079 1025.837402,864.933655 1020.036072,864.985291 
	C1013.071045,865.047363 1006.104980,865.007324 999.139404,865.000366 
	C995.326172,864.996582 993.961121,866.915405 994.038025,870.658264 
	C994.134521,875.357239 993.984131,880.089111 993.510193,884.763245 
	C993.115417,888.656860 992.100037,892.487488 991.356934,896.359741 
	C986.216370,895.909912 983.701721,887.592468 986.489624,879.373352 
	C982.699524,878.339966 981.551880,880.863281 979.888550,883.788879 
	C978.510193,883.990723 977.772766,883.976501 977.031372,883.566528 
	C977.015015,882.115479 977.002625,881.060303 976.988159,879.611450 
	C974.973267,873.577026 973.641846,867.553955 970.753235,862.404358 
	C967.947693,857.402771 967.239502,850.723877 960.990723,847.999023 
	C960.991455,847.251038 960.992249,846.502991 960.993896,845.029663 
	C960.663147,843.202881 960.331604,842.101440 960.199646,840.753174 
	C961.640198,841.761230 963.062561,842.886292 964.077087,844.303345 
	C965.399902,846.151001 966.132141,848.470703 967.625977,850.130676 
	C968.748474,851.378113 970.670288,851.906189 972.236267,852.754517 
	C972.548767,851.197510 973.238037,849.607605 973.105225,848.089539 
	C972.651733,842.907410 974.869873,840.779846 979.909912,841.403015 
	C983.583801,841.857361 987.329468,841.932007 991.032104,841.817444 
	C992.001221,841.787415 993.723206,840.442505 993.743958,839.665222 
	C993.859558,835.344910 993.331238,831.005127 993.487610,826.688843 
	C993.574585,824.289429 994.362183,821.845032 995.233337,819.574097 
	C995.674072,818.425171 997.001770,817.616455 997.928467,816.653931 
	C996.619995,815.602966 995.460083,814.011780 993.974915,813.603516 
	C989.683472,812.423645 987.302551,809.811096 986.316528,805.664368 
	C989.246277,803.264893 991.534973,803.774536 993.851685,806.282593 
	C996.134399,808.753845 998.127136,808.767273 999.004883,804.990234 
	C1000.171753,806.059509 1001.637268,806.940430 1002.451843,808.231873 
	C1005.393555,812.896057 1008.205078,817.648621 1010.897217,822.462036 
	C1013.741516,827.547668 1016.391052,832.742310 1019.132263,837.885742 
	C1019.440002,838.463196 1019.656067,839.207031 1020.142578,839.553833 
	C1025.978271,843.712280 1027.442017,850.599670 1030.853271,856.675903 
M999.317261,842.561829 
	C997.711548,841.331543 995.720215,840.320862 995.289978,843.198669 
	C995.030640,844.932922 996.348511,846.903015 996.957703,848.767212 
	C999.244507,847.813049 1002.650024,847.463867 1003.566650,845.768738 
	C1005.102844,842.927979 1001.868713,843.174744 999.317261,842.561829 
M977.753906,860.053894 
	C976.949890,858.751465 976.145874,857.448975 975.341858,856.146484 
	C974.675232,856.753967 974.008606,857.361450 973.341980,857.968933 
	C974.655640,858.849731 975.969299,859.730530 977.753906,860.053894 
z"/>
<path fill="#871217" opacity="1.000000" stroke="none" 
	d="
M998.957764,804.661987 
	C998.127136,808.767273 996.134399,808.753845 993.851685,806.282593 
	C991.534973,803.774536 989.246277,803.264893 986.316528,805.664368 
	C987.302551,809.811096 989.683472,812.423645 993.974915,813.603516 
	C995.460083,814.011780 996.619995,815.602966 997.928467,816.653931 
	C997.001770,817.616455 995.674072,818.425171 995.233337,819.574097 
	C994.362183,821.845032 993.574585,824.289429 993.487610,826.688843 
	C993.331238,831.005127 993.859558,835.344910 993.743958,839.665222 
	C993.723206,840.442505 992.001221,841.787415 991.032104,841.817444 
	C987.329468,841.932007 983.583801,841.857361 979.909912,841.403015 
	C974.869873,840.779846 972.651733,842.907410 973.105225,848.089539 
	C973.238037,849.607605 972.548767,851.197510 972.236267,852.754517 
	C970.670288,851.906189 968.748474,851.378113 967.625977,850.130676 
	C966.132141,848.470703 965.399902,846.151001 964.077087,844.303345 
	C963.062561,842.886292 961.640198,841.761230 959.823303,840.754517 
	C958.494446,841.009521 957.741577,841.016357 956.988647,841.023132 
	C956.328674,840.250977 955.668762,839.478882 955.285645,838.094238 
	C956.428650,831.158997 954.599060,827.070679 949.984131,825.016663 
	C949.317017,823.595459 948.649902,822.174255 947.977051,820.401489 
	C948.008240,815.107544 948.482910,810.692688 955.206726,810.706604 
	C958.350830,810.713013 959.357483,808.117004 960.009705,805.323486 
	C960.663147,802.525208 961.769897,799.832764 962.405029,797.916931 
	C967.136719,799.103821 970.877380,800.600830 974.704956,800.871643 
	C980.485413,801.280823 981.485535,799.425781 979.594604,794.124268 
	C978.780273,791.841309 978.740906,789.281189 977.919678,787.001831 
	C977.368896,785.473145 976.024902,784.241150 975.106201,782.830627 
	C974.363037,781.689514 973.408630,780.537964 973.177307,779.275757 
	C973.066589,778.671814 974.488403,777.120544 975.087463,777.185425 
	C976.542419,777.343140 978.937744,777.922791 979.176208,778.810303 
	C981.815552,788.632385 989.059998,794.750549 996.656616,800.951050 
	C997.631958,802.111267 998.271301,803.222534 998.957764,804.661987 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M188.680252,955.157837 
	C189.591614,956.584412 190.185059,958.169617 190.778503,959.754822 
	C192.077988,958.739929 193.373444,957.719849 194.678528,956.712158 
	C195.422836,956.137512 196.117874,955.231995 196.946625,955.078918 
	C201.035858,954.323242 202.590759,951.878174 203.197449,947.870422 
	C203.461838,946.123901 205.795486,944.690735 207.189362,943.115234 
	C207.997696,944.982727 209.580338,946.904724 209.453369,948.706299 
	C209.188171,952.469055 208.498642,955.873596 205.096588,959.034912 
	C201.886932,962.017395 200.531387,967.154846 198.792770,971.516724 
	C196.981110,976.061768 198.171600,977.647766 202.921326,977.995483 
	C208.686554,978.417603 210.046860,980.109009 209.507034,988.375000 
	C209.246429,992.365417 205.471878,992.026306 202.546555,991.975525 
	C195.697739,991.856750 188.896286,991.842957 182.197067,993.733948 
	C178.919037,994.659180 176.471664,993.418945 175.032135,990.035095 
	C174.503296,988.791992 172.898315,988.006653 171.406677,987.006226 
	C171.066803,986.672791 171.009018,986.292969 171.156143,986.026123 
	C174.275940,980.367249 177.437256,974.731262 180.550171,969.068726 
	C182.402435,965.699463 184.155350,962.275513 186.015472,958.910767 
	C186.705887,957.661865 187.574814,956.511597 188.680252,955.157837 
z"/>
<path fill="#871217" opacity="1.000000" stroke="none" 
	d="
M1078.017090,937.599609 
	C1080.655151,944.073425 1083.290771,950.176208 1085.615479,956.603760 
	C1083.536377,956.619385 1081.768188,956.310181 1080.000000,956.000916 
	C1078.537598,954.720154 1076.356079,953.712280 1075.747314,952.107605 
	C1073.899048,947.236023 1072.597900,942.150330 1071.228271,937.108765 
	C1070.914917,935.955139 1071.182129,934.643860 1071.182129,932.824097 
	C1073.667480,933.715149 1075.442749,934.351685 1077.599243,934.994080 
	C1077.991821,935.742859 1078.003296,936.485779 1078.017090,937.599609 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M960.740112,848.210693 
	C967.239502,850.723877 967.947693,857.402771 970.753235,862.404358 
	C973.641846,867.553955 974.973267,873.577026 976.625977,879.567871 
	C974.204773,875.552368 972.097473,871.208008 970.095215,866.815857 
	C967.880981,861.958679 966.002869,856.888306 961.026672,853.994812 
	C961.026672,853.994812 960.974121,853.542847 960.963989,853.316406 
	C960.799011,851.534180 960.644226,849.978271 960.740112,848.210693 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M950.022766,825.378235 
	C954.599060,827.070679 956.428650,831.158997 955.279480,837.742065 
	C953.351440,833.914917 951.706421,829.827393 950.022766,825.378235 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M957.025513,841.363892 
	C957.741577,841.016357 958.494446,841.009521 959.623718,841.001343 
	C960.331604,842.101440 960.663147,843.202881 960.990051,844.655640 
	C959.677673,843.906250 958.370056,842.805420 957.025513,841.363892 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M904.930420,706.708862 
	C904.327393,706.588318 903.693115,706.142639 903.009033,705.384644 
	C903.605835,705.509521 904.252441,705.946655 904.930420,706.708862 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M646.733398,457.235107 
	C647.691101,458.825043 648.399963,460.641327 649.046204,462.479614 
	C650.422974,466.395782 651.645203,470.312775 655.880981,472.352539 
	C661.253540,474.939728 663.041931,480.009949 663.952148,485.374481 
	C665.854187,496.584656 672.104370,505.524292 678.877563,514.148010 
	C679.558289,515.014771 682.747070,515.278870 683.369507,514.577759 
	C685.577759,512.090027 687.589478,509.243835 688.928528,506.204315 
	C692.384583,498.359253 695.495117,494.298065 702.940796,497.045715 
	C703.916138,497.405640 704.907654,498.214478 705.484009,499.085175 
	C710.123779,506.095093 714.827454,513.071350 719.197083,520.249329 
	C724.718140,529.318726 728.178223,538.925293 725.223389,549.780945 
	C725.136414,550.100525 725.133850,550.442505 725.053772,550.764587 
	C724.234863,554.056702 724.457825,558.878479 722.362183,560.314697 
	C718.165649,563.190857 712.821960,564.797058 707.729126,565.868286 
	C699.759399,567.544739 693.299377,563.882629 686.685303,559.478333 
	C680.229980,555.179749 672.696716,552.417480 665.459106,549.407654 
	C661.226257,547.647400 657.202332,550.749329 656.000366,555.372742 
	C655.298828,558.071106 654.001953,560.788940 652.332825,563.018982 
	C650.058655,566.057251 650.106445,568.976013 653.056519,571.463501 
	C655.686279,573.680908 658.163208,576.079590 660.849609,578.703247 
	C658.336914,577.850220 655.627014,576.802979 653.040466,575.509033 
	C648.943787,573.459534 645.063049,570.919617 640.843750,569.190491 
	C636.516113,567.416809 635.697998,563.889404 636.242676,560.306335 
	C637.297363,553.369873 639.147400,546.694885 646.809204,543.634094 
	C649.519043,542.551575 651.818237,540.449463 654.320374,538.836609 
	C664.007263,532.592529 666.135559,522.300110 662.081055,511.529602 
	C658.842896,502.927582 653.884399,494.588806 654.802063,484.606567 
	C655.060974,481.789459 653.743652,477.930328 651.750427,475.975372 
	C646.288452,470.618164 646.358154,464.155365 646.733398,457.235107 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M616.906006,441.992188 
	C617.601990,440.469879 618.709961,438.997833 618.903076,437.414215 
	C619.241028,434.642792 618.798706,431.780579 619.081909,428.996979 
	C619.470154,425.181824 621.078613,421.239960 625.013245,420.797394 
	C628.161255,420.443268 632.221680,421.576813 634.742188,423.521301 
	C642.031006,429.144470 646.293762,436.801941 646.936035,446.615204 
	C646.057617,446.425568 644.502014,445.984711 644.320923,445.218872 
	C642.036438,435.558197 631.194885,435.014648 627.097107,427.371552 
	C626.573730,427.525604 626.050354,427.679657 625.526978,427.833740 
	C622.923340,434.847839 620.319702,441.861969 617.102539,448.725433 
	C616.634583,447.024017 616.780090,445.473206 616.932373,443.601135 
	C616.928101,442.850647 616.917053,442.421417 616.906006,441.992188 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M616.978394,450.980652 
	C615.837463,454.306183 614.733643,457.645203 613.527527,460.946930 
	C613.253784,461.696442 612.311890,462.272034 612.208130,463.002960 
	C610.680908,473.766754 602.297119,479.208130 594.700989,484.993317 
	C590.533569,488.167206 585.442139,490.126831 580.771118,492.641022 
	C578.995972,493.596497 577.231567,494.571869 575.378418,494.962219 
	C576.569397,493.358795 577.865601,492.356659 579.115479,491.299744 
	C586.102356,485.391144 595.785400,483.280487 601.368225,475.365784 
	C605.477356,469.540253 611.202393,464.661377 611.919617,456.538391 
	C611.955811,455.450592 611.975464,454.723450 612.337036,453.963318 
	C614.112122,452.947052 615.545288,451.963867 616.978394,450.980652 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M611.903076,456.899109 
	C611.202393,464.661377 605.477356,469.540253 601.368225,475.365784 
	C595.785400,483.280487 586.102356,485.391144 579.115479,491.299744 
	C577.865601,492.356659 576.569397,493.358795 575.147339,494.690857 
	C574.558777,495.000488 574.117554,495.005493 573.023315,495.048218 
	C571.906555,495.716766 571.442810,496.347626 570.979126,496.978485 
	C570.538757,496.979797 570.098328,496.981140 569.044556,496.693451 
	C563.264648,496.917053 558.098145,497.429688 553.002930,497.595337 
	C556.639648,496.085419 560.172363,494.805328 563.780029,493.792664 
	C567.336304,492.794373 571.287720,492.699310 574.496643,491.080048 
	C580.048706,488.278442 585.054016,484.416016 590.485229,481.346527 
	C597.051270,477.635712 601.014038,471.726379 605.428223,465.925232 
	C607.671936,462.976562 606.950073,457.791595 611.903076,456.899109 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M547.012207,503.012207 
	C546.016418,504.271851 545.230042,505.815979 543.982727,506.733826 
	C541.097351,508.856934 538.083862,510.849335 534.947449,512.577454 
	C530.678162,514.929871 528.475891,517.851807 528.763123,523.203430 
	C529.008118,527.769348 524.312317,531.676086 520.390137,530.957031 
	C525.407715,528.600220 526.516907,524.735229 526.035706,519.875183 
	C525.810730,517.602539 525.994263,515.289551 525.992676,512.994812 
	C525.992676,512.994812 526.000000,513.000000 526.310364,512.862732 
	C528.748413,511.134521 530.876160,509.543610 533.373047,507.917908 
	C537.156189,506.250793 540.570190,504.618469 544.360596,502.990295 
	C545.489502,502.993835 546.241943,502.993256 546.997803,502.996796 
	C547.001282,503.000916 547.012207,503.012207 547.012207,503.012207 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M525.651733,513.045898 
	C525.994263,515.289551 525.810730,517.602539 526.035706,519.875183 
	C526.516907,524.735229 525.407715,528.600220 520.162109,530.685852 
	C519.557190,531.001770 519.114319,531.003479 517.963623,531.001831 
	C516.504700,530.992493 515.753540,530.986511 515.002441,530.980469 
	C516.406311,523.847900 520.718994,518.392029 525.651733,513.045898 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M552.931641,497.942322 
	C558.098145,497.429688 563.264648,496.917053 568.714233,496.693359 
	C568.170715,497.878296 567.469238,499.368896 566.497009,499.573578 
	C560.313049,500.875458 554.069458,501.893646 547.428833,503.008728 
	C547.012207,503.012207 547.001282,503.000916 547.107300,502.736115 
	C547.469360,501.968414 547.725403,501.465485 548.213257,500.944519 
	C548.445068,500.926483 548.909973,500.918823 549.242554,500.908691 
	C550.045288,500.258484 550.515503,499.618439 551.240112,498.870422 
	C551.973572,498.489105 552.452637,498.215698 552.931641,497.942322 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M616.982605,450.650513 
	C615.545288,451.963867 614.112122,452.947052 612.336426,453.583405 
	C613.637756,450.131836 615.281738,447.027130 616.925659,443.922424 
	C616.780090,445.473206 616.634583,447.024017 616.740845,448.786804 
	C616.990723,449.439331 616.988770,449.879822 616.982605,450.650513 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M514.681641,531.026062 
	C515.753540,530.986511 516.504700,530.992493 517.631531,531.004150 
	C516.602051,531.672180 515.196777,532.334595 513.396240,532.992432 
	C513.454224,532.349060 513.907532,531.710327 514.681641,531.026062 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M663.027161,581.355713 
	C663.977295,582.101807 664.961914,583.194214 665.985596,584.630371 
	C665.037048,583.883423 664.049316,582.792725 663.027161,581.355713 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M571.301697,496.946838 
	C571.442810,496.347626 571.906555,495.716766 572.692383,495.053345 
	C572.551086,495.652283 572.087708,496.283722 571.301697,496.946838 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M937.988770,954.976257 
	C937.546448,954.979797 937.104187,954.983398 936.183411,954.760620 
	C935.136658,954.688660 934.568298,954.843140 934.000000,954.997559 
	C933.556091,955.002197 933.112122,955.006836 932.335327,955.014893 
	C932.001404,954.845459 932.000427,954.672607 931.999634,954.240295 
	C934.736206,952.994690 937.472595,952.008545 940.553955,951.370361 
	C939.928955,952.804321 938.958862,953.890320 937.988770,954.976257 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M322.035767,945.360840 
	C322.640594,945.469055 323.293549,945.898865 324.012695,946.643311 
	C323.413879,946.532715 322.748901,946.107605 322.035767,945.360840 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M325.030518,947.289917 
	C325.557678,947.161499 326.114288,947.327209 326.839142,947.737427 
	C326.358246,947.849304 325.709106,947.716614 325.030518,947.289917 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M761.026489,512.971008 
	C762.329651,513.441162 763.632812,513.911255 764.969482,514.733765 
	C765.844543,522.325623 766.736389,529.888672 772.601929,534.791687 
	C777.789124,539.127747 777.241333,544.373535 776.944336,549.735474 
	C776.669128,554.704956 775.929260,559.677673 774.967712,564.565063 
	C774.618774,566.338745 773.388428,568.320557 771.958557,569.436035 
	C764.541260,575.222473 756.754333,580.761902 746.741211,579.849548 
	C738.760315,579.122253 732.174072,582.222656 725.681091,585.894897 
	C717.715881,590.399841 709.950073,592.748230 700.720520,588.730347 
	C697.268250,587.227539 692.209045,588.208618 688.257996,589.353271 
	C682.865540,590.915405 676.284912,589.238220 672.927429,584.632507 
	C671.104370,582.131775 668.501221,580.215027 666.405396,577.894470 
	C662.411499,573.472534 661.666809,569.324707 664.555420,565.920288 
	C665.713318,564.555664 667.953857,562.904602 669.368286,563.168762 
	C675.938416,564.395630 683.081360,565.010315 688.730896,568.157043 
	C698.001953,573.320923 706.235107,576.349060 716.887634,571.405212 
	C727.647705,566.411499 730.508667,563.855591 731.026184,551.707031 
	C731.077454,550.504639 731.739380,549.328369 732.547363,548.487305 
	C733.238159,551.794250 733.381958,554.769958 733.787170,557.709717 
	C734.464233,562.621582 731.766052,566.637024 727.291321,569.267395 
	C724.772888,570.747742 723.058594,573.692566 721.253784,576.175171 
	C721.012939,576.506470 722.367615,578.816467 722.995972,578.822205 
	C726.093262,578.850403 729.228149,578.608276 732.287292,578.091675 
	C737.333252,577.239380 742.051514,572.865540 747.525574,576.863464 
	C747.973755,577.190735 748.826294,576.959961 749.490540,576.996216 
	C757.228210,577.417847 762.895813,572.766418 768.625671,568.720642 
	C770.956360,567.075012 773.293091,564.133301 773.794006,561.440247 
	C775.440002,552.591187 773.169189,544.559814 766.010498,538.613098 
	C762.615723,535.793091 761.912170,531.905151 763.267517,528.740662 
	C765.856628,522.695251 762.632812,518.077881 761.026489,512.971008 
M672.952087,579.565430 
	C674.891785,580.335815 677.294678,582.228821 678.680664,581.657043 
	C682.232971,580.191528 685.517395,577.841797 688.433533,575.300171 
	C688.921265,574.875122 687.518616,570.689026 686.134277,570.025696 
	C682.842285,568.448425 679.081848,567.669250 675.428040,567.079651 
	C672.079895,566.539368 668.290955,566.861572 667.208618,570.744019 
	C666.071960,574.821716 668.349182,577.718384 672.952087,579.565430 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M616.932373,443.601135 
	C615.281738,447.027130 613.637756,450.131836 611.994507,453.616455 
	C611.975464,454.723450 611.955811,455.450592 611.919617,456.538391 
	C606.950073,457.791595 607.671936,462.976562 605.428223,465.925232 
	C601.014038,471.726379 597.051270,477.635712 590.485229,481.346527 
	C585.054016,484.416016 580.048706,488.278442 574.496643,491.080048 
	C571.287720,492.699310 567.336304,492.794373 563.780029,493.792664 
	C560.172363,494.805328 556.639648,496.085419 553.002930,497.595367 
	C552.452637,498.215698 551.973572,498.489105 550.922852,498.911072 
	C549.870789,499.679382 549.390381,500.299103 548.909973,500.918823 
	C548.909973,500.918823 548.445068,500.926483 547.865479,500.951660 
	C546.185303,501.646606 545.084717,502.316376 543.984131,502.986145 
	C540.570190,504.618469 537.156189,506.250793 533.162231,507.660217 
	C531.074158,507.614410 529.566162,507.791504 528.058105,507.968597 
	C528.041443,507.523193 528.024719,507.077789 528.003601,506.297424 
	C529.432983,504.639557 530.866699,503.316650 532.908569,502.255859 
	C536.381714,501.718506 539.246765,500.919067 542.430176,500.056824 
	C543.448181,499.571533 544.147766,499.149078 545.202332,498.779358 
	C546.661133,497.509491 547.764954,496.186829 549.066589,494.729004 
	C549.527893,494.343323 549.600281,494.046173 549.593933,493.805786 
	C549.706299,493.909271 549.998718,493.997894 550.365234,494.008179 
	C551.880554,493.450531 553.337952,493.154877 554.122498,492.265533 
	C556.856995,489.165833 559.725281,487.618988 564.115906,488.869812 
	C569.013123,490.264984 573.682251,489.651459 577.769653,485.832947 
	C580.383362,483.391144 583.600098,481.551056 586.681213,479.660980 
	C598.457642,472.436798 605.896240,462.008850 609.758667,448.550049 
	C609.789490,447.813538 609.797852,447.392242 610.107788,446.850342 
	C610.585632,446.139313 610.761963,445.548889 610.984985,444.585907 
	C611.100220,443.214935 611.168701,442.216553 611.555969,441.108948 
	C611.967102,439.662415 612.059509,438.325104 612.148071,436.565613 
	C612.246826,434.471222 612.349487,432.798950 612.794678,431.083374 
	C616.321167,429.406647 615.212158,427.211945 613.932129,424.672424 
	C613.934692,423.922150 613.932983,423.493713 613.958862,422.622009 
	C613.661926,417.237549 613.354736,412.295227 613.006653,407.355743 
	C612.778442,404.118805 614.341675,403.169373 617.450867,402.886353 
	C620.227844,402.633606 623.160034,401.742126 625.580627,400.357666 
	C632.865662,396.190857 637.197571,395.440674 640.193115,400.010437 
	C644.384644,406.404541 648.304260,413.287842 650.589294,420.515381 
	C652.215454,425.658539 650.859924,431.716949 651.031860,437.360901 
	C651.099365,439.578796 651.702454,441.780426 652.056274,444.312744 
	C652.058533,445.065979 652.066162,445.495941 652.044800,446.363892 
	C651.967163,448.567139 651.918518,450.332397 651.869812,452.097626 
	C650.910583,447.813934 649.750549,443.563599 649.032227,439.239899 
	C647.322266,428.946747 645.637146,418.782593 637.020325,411.381531 
	C634.385010,409.117981 631.970947,407.675507 628.646118,409.022278 
	C620.658630,412.257812 616.472656,412.786774 616.903015,422.684357 
	C617.173889,428.914215 616.573425,435.181946 616.634888,441.712463 
	C616.917053,442.421417 616.928101,442.850647 616.932373,443.601135 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M756.057129,504.990601 
	C757.370972,507.402985 758.684875,509.815399 760.009155,512.998718 
	C759.596252,517.623535 759.605835,521.587769 758.657715,525.307922 
	C756.800354,532.595581 758.593567,537.851501 764.029175,542.882141 
	C767.098755,545.723083 769.759888,549.466492 771.342590,553.321716 
	C772.893555,557.099792 773.157410,561.930725 769.359314,564.847961 
	C765.397217,567.891235 760.948730,570.486023 756.357422,572.442993 
	C754.242432,573.344543 751.236267,572.442810 748.755066,571.851318 
	C748.426941,571.773132 748.764282,568.679077 748.886292,566.988403 
	C749.076355,564.356812 748.448730,563.167603 745.282227,563.180481 
	C743.101379,563.189331 740.354309,561.959473 738.838745,560.357483 
	C735.095520,556.400879 734.621643,551.204346 734.949707,545.567261 
	C734.946838,544.137817 734.931458,543.097473 734.946228,541.698120 
	C734.330505,540.550049 733.684631,539.760925 733.038757,538.971802 
	C733.038757,538.971802 732.971741,539.017456 732.981445,538.730591 
	C732.678650,537.959229 732.366028,537.474792 732.053467,536.990295 
	C732.033386,536.251526 732.013306,535.512695 732.004333,533.984863 
	C731.814819,530.998047 731.614319,528.800171 731.740601,526.695129 
	C733.081543,528.500427 734.095703,530.212952 735.070068,532.332275 
	C736.393982,535.822632 737.757568,538.906311 739.104980,542.352661 
	C739.114685,543.440491 739.140686,544.165527 739.105896,545.311523 
	C739.250427,555.108398 742.294067,556.395752 752.731018,554.218262 
	C755.323181,553.677490 756.354309,552.471008 755.440979,549.606445 
	C754.358398,546.210693 752.933350,542.581299 753.195374,539.171387 
	C753.735352,532.140381 755.355774,525.196777 756.412964,518.199219 
	C756.927551,514.792847 759.170349,511.221527 755.802368,507.655701 
	C755.713013,506.552673 755.624146,505.820251 755.664490,505.057068 
	C755.793762,505.026306 756.057129,504.990601 756.057129,504.990601 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M734.937256,545.956360 
	C734.621643,551.204346 735.095520,556.400879 738.838745,560.357483 
	C740.354309,561.959473 743.101379,563.189331 745.282227,563.180481 
	C748.448730,563.167603 749.076355,564.356812 748.886292,566.988403 
	C748.764282,568.679077 748.426941,571.773132 748.755066,571.851318 
	C751.236267,572.442810 754.242432,573.344543 756.357422,572.442993 
	C760.948730,570.486023 765.397217,567.891235 769.359314,564.847961 
	C773.157410,561.930725 772.893555,557.099792 771.342590,553.321716 
	C769.759888,549.466492 767.098755,545.723083 764.029175,542.882141 
	C758.593567,537.851501 756.800354,532.595581 758.657715,525.307922 
	C759.605835,521.587769 759.596252,517.623535 760.031860,513.375488 
	C760.044189,512.981262 760.535034,512.976379 760.780762,512.973694 
	C762.632812,518.077881 765.856628,522.695251 763.267517,528.740662 
	C761.912170,531.905151 762.615723,535.793091 766.010498,538.613098 
	C773.169189,544.559814 775.440002,552.591187 773.794006,561.440247 
	C773.293091,564.133301 770.956360,567.075012 768.625671,568.720642 
	C762.895813,572.766418 757.228210,577.417847 749.490540,576.996216 
	C748.826294,576.959961 747.973755,577.190735 747.525574,576.863464 
	C742.051514,572.865540 737.333252,577.239380 732.287292,578.091675 
	C729.228149,578.608276 726.093262,578.850403 722.995972,578.822205 
	C722.367615,578.816467 721.012939,576.506470 721.253784,576.175171 
	C723.058594,573.692566 724.772888,570.747742 727.291321,569.267395 
	C731.766052,566.637024 734.464233,562.621582 733.787170,557.709717 
	C733.381958,554.769958 733.238159,551.794250 732.936462,548.375793 
	C732.895447,547.486084 732.894958,547.055176 733.225281,546.304749 
	C734.016479,545.975525 734.476868,545.965942 734.937256,545.956360 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M708.878662,494.075623 
	C706.318604,491.620331 703.644714,489.271393 701.225281,486.684540 
	C697.667542,482.880554 693.540039,483.088959 689.465149,485.130035 
	C682.591492,488.572968 676.900513,487.560699 673.617676,480.658081 
	C671.013794,475.183075 667.070923,472.571716 661.663452,470.924011 
	C654.223267,468.656891 650.533020,462.533539 651.915894,454.279724 
	C652.914856,454.009918 653.336426,454.011200 653.819702,454.409241 
	C653.883484,455.874329 653.885559,456.942688 653.652466,458.217224 
	C653.898865,459.308411 654.380493,460.193481 654.893921,461.392273 
	C654.962646,462.124329 654.999634,462.542694 655.071533,463.315613 
	C658.285156,465.110443 662.006042,468.389862 664.540039,467.642914 
	C671.869507,465.482544 677.827087,466.772400 683.789062,471.003662 
	C685.749939,472.395294 688.297485,473.059509 690.663879,473.763489 
	C700.922668,476.815369 706.279663,484.192047 708.878662,494.075623 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M708.896362,494.071655 
	C706.279663,484.192047 700.922668,476.815369 690.663879,473.763489 
	C688.297485,473.059509 685.749939,472.395294 683.789062,471.003662 
	C677.827087,466.772400 671.869507,465.482544 664.540039,467.642914 
	C662.006042,468.389862 658.285156,465.110443 655.449463,463.365845 
	C660.981750,466.335083 665.921692,465.225800 670.526001,461.930908 
	C673.192749,460.022552 675.341187,459.886932 678.061279,461.941895 
	C681.583313,464.602692 685.185181,467.520355 689.235352,469.046082 
	C698.418213,472.505371 705.270874,477.910309 708.769409,487.338715 
	C709.557007,489.461212 711.522156,491.146729 713.012939,493.391907 
	C715.746765,498.808990 718.412964,503.867493 721.068970,509.257782 
	C721.729126,510.371490 722.399536,511.153412 723.053833,511.962311 
	C723.037720,511.989288 723.081787,511.932739 723.080261,512.290344 
	C724.295410,513.480774 725.512085,514.313599 726.799805,515.121704 
	C726.870789,515.096985 726.991028,515.006714 726.950928,515.383789 
	C726.928467,516.518860 726.946106,517.276978 726.964478,518.383789 
	C727.613342,519.510010 728.261414,520.287415 728.943542,521.373047 
	C729.012146,522.093506 729.046753,522.505615 729.090332,523.265625 
	C729.795776,524.075012 730.492126,524.536377 731.216919,525.268311 
	C731.301514,525.893311 731.357666,526.247803 731.413818,526.602356 
	C731.614319,528.800171 731.814819,530.998047 731.993042,533.615479 
	C730.819885,532.414246 729.452759,530.903748 728.559631,529.151550 
	C726.094238,524.314941 723.826965,519.377258 721.202454,513.938232 
	C720.257141,512.623108 719.590820,511.849335 718.924438,511.075562 
	C717.428772,508.537872 715.933105,506.000214 714.163452,502.903687 
	C712.236755,499.593445 710.583923,496.842102 708.931152,494.090759 
	C708.931152,494.090759 708.914124,494.067688 708.896362,494.071655 
z"/>
<path fill="#101010" opacity="1.000000" stroke="none" 
	d="
M787.976929,557.008545 
	C786.993286,557.784302 785.193787,558.519165 785.152588,559.342102 
	C784.751587,567.348999 779.826965,572.673096 774.667725,577.928894 
	C774.006409,578.602600 773.593079,579.519714 773.023865,580.673828 
	C768.213501,581.433594 763.444153,581.843628 758.674744,582.253662 
	C758.597961,581.680969 758.521118,581.108215 758.444336,580.535461 
	C759.898682,580.040161 761.382202,579.048340 762.802673,579.131714 
	C770.819763,579.602173 774.806885,574.343262 777.733582,568.294067 
	C780.380005,562.824341 782.083801,556.898621 784.744507,551.387451 
	C786.182800,553.403442 787.079834,555.205994 787.976929,557.008545 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M788.291260,557.157898 
	C787.079834,555.205994 786.182800,553.403442 785.128601,551.300232 
	C784.988892,550.550598 785.006287,550.101562 785.049805,549.316223 
	C786.057617,549.513367 787.487732,549.793884 787.953064,550.618774 
	C793.703857,560.812134 799.313965,571.084839 804.927368,581.728882 
	C801.663391,577.579163 798.315063,573.115417 795.239197,568.471313 
	C792.851746,564.866699 790.804260,561.036926 788.291260,557.157898 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M653.758057,454.012512 
	C653.336426,454.011200 652.914856,454.009918 652.174438,453.997253 
	C651.874084,453.565613 651.892395,453.145355 651.890259,452.411346 
	C651.918518,450.332397 651.967163,448.567139 652.386475,446.384583 
	C653.444031,446.014679 654.130859,446.062042 654.854431,446.541321 
	C654.913147,449.312744 654.935059,451.652313 654.657104,453.988617 
	C654.157471,453.994446 653.957764,454.003479 653.758057,454.012512 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M708.945557,494.463196 
	C710.583923,496.842102 712.236755,499.593445 713.893982,502.709808 
	C712.252258,500.328430 710.606079,497.582001 708.945557,494.463196 
z"/>
<path fill="#563233" opacity="1.000000" stroke="none" 
	d="
M766.999512,517.375488 
	C768.613831,518.819763 770.257751,520.616211 771.891968,522.774902 
	C770.264587,521.333923 768.646851,519.530823 766.999512,517.375488 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M734.949707,545.567261 
	C734.476868,545.965942 734.016479,545.975525 733.207642,545.981201 
	C732.695923,544.795593 732.532654,543.613953 732.958069,542.219482 
	C734.003174,542.023499 734.459656,542.040283 734.916077,542.057068 
	C734.931458,543.097473 734.946838,544.137817 734.949707,545.567261 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M778.997681,538.381348 
	C779.979065,539.138428 780.950806,540.257324 781.927979,541.732666 
	C780.951660,540.973694 779.969849,539.858398 778.997681,538.381348 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M734.946228,541.698120 
	C734.459656,542.040283 734.003174,542.023499 733.200806,541.996338 
	C732.886841,541.231873 732.918823,540.477722 732.994751,539.347717 
	C733.684631,539.760925 734.330505,540.550049 734.946228,541.698120 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M718.940674,511.422180 
	C719.590820,511.849335 720.257141,512.623108 720.929688,513.725342 
	C720.276245,513.292114 719.616516,512.530457 718.940674,511.422180 
z"/>
<path fill="#353434" opacity="1.000000" stroke="none" 
	d="
M731.804443,537.143250 
	C732.366028,537.474792 732.678650,537.959229 732.946899,538.751709 
	C732.453491,538.471802 732.004395,537.884033 731.804443,537.143250 
z"/>
<path fill="#CA222A" opacity="1.000000" stroke="none" 
	d="
M552.801575,819.823059 
	C552.801575,819.823059 552.830261,819.401550 553.280029,819.125366 
	C553.824158,815.563538 553.918579,812.277832 554.009766,808.544373 
	C554.020813,805.413330 554.035156,802.730042 554.434265,799.942810 
	C556.233582,796.093689 557.642029,792.349060 554.036682,788.571777 
	C553.680969,786.456360 553.332947,784.730042 552.936707,782.648315 
	C552.463867,781.813416 552.077637,780.961426 551.608704,780.913208 
	C547.805115,780.522034 543.987061,780.048889 540.171692,780.033936 
	C519.392212,779.952454 498.612213,779.994385 477.832428,779.995483 
	C470.927917,779.995850 464.011169,779.772705 457.126282,780.141663 
	C455.039185,780.253540 453.040100,782.008240 450.650635,783.052734 
	C447.868500,787.059998 445.436462,791.025085 442.679321,795.034302 
	C441.918549,795.738586 441.482910,796.398804 441.037109,797.291992 
	C441.026886,797.524902 440.997955,797.990234 440.644806,798.030518 
	C435.712311,800.365356 434.238251,804.151672 434.970947,809.435059 
	C434.694275,813.296570 434.144684,816.766663 434.328156,820.197632 
	C434.672424,826.637024 435.358002,833.062927 436.060425,839.477051 
	C436.556824,844.009827 434.497223,845.143188 430.261108,845.104370 
	C411.929718,844.936157 393.595764,845.052246 375.262756,845.040771 
	C373.677399,845.039795 372.092163,844.861633 369.849579,844.726013 
	C413.190613,786.387207 456.066620,728.674377 499.295227,670.946350 
	C503.128510,667.095581 506.109528,662.562195 510.226227,659.615112 
	C514.597229,656.485962 515.485535,652.533325 516.061035,647.733765 
	C516.367249,647.252747 516.620300,647.061462 517.281067,646.934204 
	C518.408081,645.930054 519.184875,644.937805 520.127014,643.646484 
	C526.897888,634.484863 533.509521,625.626892 540.104553,616.756653 
	C541.763489,614.525391 543.369629,612.254944 545.345337,609.967896 
	C550.380066,606.948303 555.073914,603.970215 559.744080,600.955444 
	C559.953186,600.820435 559.947266,600.352234 560.501831,600.030457 
	C564.970764,600.017029 568.979248,600.012390 573.263550,600.294189 
	C576.364380,600.389160 579.189514,600.197632 582.482422,600.004272 
	C593.622314,600.010742 604.294495,600.019165 615.056030,600.401855 
	C615.965515,601.178955 616.756348,601.849304 617.610291,601.942505 
	C624.848389,602.732788 625.364929,603.305115 625.435669,610.600769 
	C625.440369,611.081360 626.439026,611.552368 626.979858,612.441223 
	C626.988770,614.232727 626.993164,615.610779 626.700012,617.283813 
	C626.292053,617.889099 626.085327,618.199219 626.084839,618.509766 
	C626.048340,640.873474 625.999817,663.237305 626.088318,685.600586 
	C626.092896,686.751648 627.282410,687.898010 627.933289,689.512085 
	C627.954712,731.310669 627.963074,772.643860 627.579590,814.074463 
	C627.079712,819.115906 626.743042,824.072266 627.009766,828.995911 
	C627.085266,830.389099 628.923706,831.686829 630.214355,833.225464 
	C630.664490,834.263855 630.853760,835.105042 631.065430,836.330750 
	C631.745300,838.843628 632.402710,840.972046 632.971375,842.813171 
	C630.300659,843.962341 628.115051,844.902832 625.466064,845.867798 
	C599.733948,845.908325 574.465027,845.962769 549.196655,845.868408 
	C547.150208,845.860779 545.107544,844.833008 542.812622,844.211426 
	C544.813965,839.518372 546.412292,835.770386 548.331421,831.972717 
	C549.085144,831.272034 549.518066,830.621033 549.971802,829.981079 
	C549.992676,829.992126 549.968872,829.951294 550.318481,829.915894 
	C551.449158,827.585144 552.230164,825.289917 553.017822,822.597290 
	C552.950195,821.407593 552.875916,820.615356 552.801575,819.823059 
M487.194733,744.346008 
	C489.933075,744.877625 492.675354,745.874634 495.408997,745.851501 
	C502.866455,745.788452 510.321686,745.003662 517.775208,745.040039 
	C528.892273,745.094238 540.005493,745.696411 551.123413,745.888428 
	C556.910095,745.988403 560.036987,742.782104 559.877319,737.035767 
	C559.683960,730.078003 559.009583,723.128662 558.961548,716.172791 
	C558.830383,697.195190 558.990173,678.215332 558.817139,659.238403 
	C558.798767,657.216736 557.487305,653.606384 556.525574,653.519897 
	C554.384827,653.327759 551.999817,654.736206 549.890625,655.831665 
	C548.969177,656.310181 548.488892,657.627686 547.793152,658.552795 
	C537.469421,672.280640 527.232239,686.074707 516.791016,699.712646 
	C507.330322,712.069885 497.541443,724.177246 488.179688,736.607483 
	C486.846466,738.377686 487.107697,741.348755 487.194733,744.346008 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M626.997559,616.988892 
	C626.993164,615.610779 626.988770,614.232727 627.004639,612.039185 
	C628.759033,602.510254 627.447754,598.757385 618.422913,599.958069 
	C617.287842,600.109009 616.119568,600.010498 614.966675,600.027588 
	C604.294495,600.019165 593.622314,600.010742 582.205933,599.718079 
	C578.637085,599.625122 575.812439,599.816467 572.987793,600.007751 
	C568.979248,600.012390 564.970764,600.017029 560.062866,600.007690 
	C551.015320,598.480408 548.110596,604.387085 545.000000,610.002441 
	C543.369629,612.254944 541.763489,614.525391 540.104553,616.756653 
	C533.509521,625.626892 526.897888,634.484863 519.792969,643.694946 
	C518.505981,645.010437 517.718384,645.978333 516.930786,646.946167 
	C516.620300,647.061462 516.367249,647.252747 515.764893,647.898438 
	C513.128540,651.131165 510.799164,653.913940 508.691772,656.855835 
	C505.363770,661.501648 502.184723,666.254211 498.942596,670.961609 
	C456.066620,728.674377 413.190613,786.387207 369.849579,844.726013 
	C372.092163,844.861633 373.677399,845.039795 375.262756,845.040771 
	C393.595764,845.052246 411.929718,844.936157 430.261108,845.104370 
	C434.497223,845.143188 436.556824,844.009827 436.060425,839.477051 
	C435.358002,833.062927 434.672424,826.637024 434.328156,820.197632 
	C434.144684,816.766663 434.694275,813.296570 435.242645,809.233032 
	C437.380829,805.078064 439.189392,801.534180 440.997955,797.990234 
	C440.997955,797.990234 441.026886,797.524902 441.354828,797.236633 
	C442.123352,796.295654 442.563904,795.642944 443.004456,794.990234 
	C445.436462,791.025085 447.868500,787.059998 451.073944,783.046631 
	C454.799255,782.668091 457.750458,782.058899 460.703217,782.051025 
	C488.681396,781.976318 516.659912,781.975769 544.638123,782.045898 
	C547.421204,782.052856 550.202698,782.669800 552.984985,783.003662 
	C553.332947,784.730042 553.680969,786.456360 554.014160,789.034119 
	C554.016052,793.272583 554.032776,796.659729 554.049561,800.046814 
	C554.035156,802.730042 554.020813,805.413330 553.690002,808.554321 
	C553.160645,809.022766 552.947754,809.033386 552.734863,809.043945 
	C552.479492,802.944153 552.158691,796.846191 551.987305,790.744019 
	C551.821594,784.840149 551.134216,784.003174 545.363464,783.998352 
	C516.535156,783.974243 487.706787,784.017700 458.878601,783.952393 
	C454.260651,783.941956 451.285278,785.317383 449.239990,790.000854 
	C446.869781,795.428406 442.912842,800.141296 440.186615,805.443176 
	C438.573029,808.581238 437.036469,812.293091 437.159821,815.685364 
	C437.471954,824.266235 438.542389,832.839905 439.723694,841.359009 
	C440.209198,844.860229 439.480957,846.878540 435.945160,847.496887 
	C434.484009,847.752441 432.991486,847.962280 431.512939,847.964355 
	C411.183197,847.992737 390.853394,847.987122 370.523621,847.966125 
	C369.203125,847.964783 367.726654,848.110413 366.596619,847.591370 
	C364.568207,846.659546 363.485504,845.331482 365.430786,842.964050 
	C373.935577,832.613770 382.588989,822.366577 390.657654,811.681152 
	C404.829712,792.913025 418.498169,773.765320 432.598602,754.942078 
	C442.623566,741.559448 453.171265,728.568298 463.187714,715.179443 
	C471.482422,704.091980 479.237457,692.602234 487.432709,681.438354 
	C492.332397,674.763855 497.756409,668.475830 502.703094,661.834106 
	C512.572754,648.582581 522.231689,635.174377 532.065918,621.896240 
	C537.296082,614.834473 542.396057,607.630005 548.214478,601.075562 
	C550.218994,598.817505 554.212097,597.253479 557.313721,597.224976 
	C571.275513,597.096802 585.242432,597.773682 599.209534,597.872314 
	C606.108643,597.921082 613.010010,597.265442 619.914368,597.073425 
	C628.295044,596.840393 629.949219,598.489258 629.952637,606.778870 
	C629.982056,678.266418 630.164673,749.754700 629.818115,821.240417 
	C629.776489,829.821228 634.639526,834.961731 638.660522,841.105286 
	C639.646423,842.611572 639.992065,844.536926 640.632080,846.269653 
	C638.792786,846.840759 636.955139,847.903259 635.113892,847.909485 
	C604.786255,848.011902 574.458069,848.007385 544.130371,847.908813 
	C542.509399,847.903503 539.809387,847.180969 539.520020,846.218506 
	C539.056519,844.676636 539.857178,842.477600 540.708801,840.865479 
	C544.408203,833.862000 548.319336,826.970459 552.477539,819.930664 
	C552.875916,820.615356 552.950195,821.407593 552.746338,822.810913 
	C551.635132,825.598328 550.802002,827.774841 549.968872,829.951294 
	C549.968872,829.951294 549.992676,829.992126 549.653381,830.027710 
	C548.879578,830.716309 548.445129,831.369385 548.010620,832.022400 
	C546.412292,835.770386 544.813965,839.518372 542.812622,844.211426 
	C545.107544,844.833008 547.150208,845.860779 549.196655,845.868408 
	C574.465027,845.962769 599.733948,845.908325 625.932068,845.917480 
	C630.492676,845.942688 634.123901,845.942688 637.755066,845.942688 
	C638.027588,845.356323 638.300110,844.770020 638.572571,844.183655 
	C636.062744,841.437866 633.552917,838.692078 631.043091,835.946289 
	C630.853760,835.105042 630.664490,834.263855 630.185669,832.835144 
	C629.254578,826.157471 628.613037,820.067200 627.971497,813.976990 
	C627.963074,772.643860 627.954712,731.310669 627.963196,689.039185 
	C627.984253,665.958130 628.008423,643.815430 627.949585,621.672974 
	C627.945435,620.110779 627.329163,618.550232 626.997559,616.988892 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M552.770569,809.493042 
	C552.947754,809.033386 553.160645,809.022766 553.693237,809.002197 
	C553.918579,812.277832 553.824158,815.563538 553.333984,818.921143 
	C552.894165,815.976135 552.850220,812.959106 552.770569,809.493042 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M961.018921,854.358826 
	C966.002869,856.888306 967.880981,861.958679 970.095215,866.815857 
	C972.097473,871.208008 974.204773,875.552368 976.627991,879.961426 
	C977.002625,881.060303 977.015015,882.115479 977.017273,884.007568 
	C977.447693,888.092224 977.984131,891.332031 978.258057,894.593872 
	C978.322510,895.361267 977.500916,896.203003 977.086853,897.010498 
	C974.041077,883.742126 970.689514,870.581970 963.370667,858.853271 
	C962.954651,858.186584 962.308655,857.663330 961.440735,856.984375 
	C961.078308,856.171204 961.044739,855.447021 961.018921,854.358826 
z"/>
<path fill="#CB2229" opacity="1.000000" stroke="none" 
	d="
M769.517334,878.873352 
	C768.018677,878.218384 766.901062,877.595642 765.397522,876.582886 
	C761.181091,873.484009 757.420227,870.668579 753.496582,868.102051 
	C750.662170,866.247925 747.614685,864.718689 744.656311,863.055115 
	C741.091125,861.050232 737.519592,859.056519 733.586792,857.041138 
	C732.494446,857.000671 731.766052,856.977173 730.908936,856.696594 
	C730.214966,855.933777 729.649902,855.427979 729.077759,854.607422 
	C729.039062,853.874207 729.007385,853.455688 728.983643,852.665283 
	C727.957947,848.732178 726.088745,846.112854 721.647461,845.880737 
	C719.132080,843.060303 716.622681,840.972900 712.644287,841.185425 
	C712.414978,841.145020 711.962830,841.033386 711.798950,840.738159 
	C709.383301,839.384094 707.131470,838.325317 704.861206,837.245911 
	C704.842773,837.225281 704.852966,837.171021 704.842896,836.799683 
	C698.998779,832.031189 688.627014,831.799011 682.472046,835.782288 
	C679.591309,837.646606 676.593018,839.350769 673.546387,840.931946 
	C669.705261,842.925537 668.201050,841.942078 667.986694,837.557251 
	C667.668396,831.046143 667.187805,824.538513 667.067627,818.023987 
	C667.000977,814.415161 667.621033,810.798706 667.750183,807.179199 
	C667.825928,805.057678 667.448425,802.923767 667.441040,800.794800 
	C667.404846,790.403015 667.424683,780.011108 667.424683,769.052795 
	C669.397766,769.052795 671.171875,769.052795 672.969849,769.428162 
	C673.349060,775.581726 678.175171,777.657288 681.775391,781.002136 
	C695.321350,793.587097 708.145813,806.972107 722.023132,819.166809 
	C728.806946,825.128235 737.007019,829.760010 745.180725,833.727417 
	C748.765869,835.467590 753.117065,839.062744 758.453552,836.012146 
	C761.253174,836.016235 763.610718,836.016663 766.229431,836.294678 
	C767.944702,836.565491 769.431274,836.754822 770.847534,836.519958 
	C779.690674,835.053955 785.221497,828.667358 790.807983,822.585571 
	C795.340027,817.651733 797.949951,811.181213 796.719788,804.863708 
	C793.941345,790.594727 789.120667,777.500610 773.828125,770.982910 
	C763.909790,766.755737 756.117432,758.455872 745.904236,754.412781 
	C745.305359,754.175598 744.809570,753.676819 744.268005,753.296570 
	C738.278198,749.090881 732.730591,743.933167 726.196777,740.899353 
	C716.152161,736.235535 709.363586,728.492981 702.864868,720.189087 
	C701.469116,718.405518 700.734070,716.117676 699.587524,714.123718 
	C696.193054,708.220642 692.741760,702.350220 689.328186,696.458069 
	C689.168518,696.182495 689.116760,695.824097 689.085144,695.497498 
	C688.052368,684.838257 686.634460,674.195374 686.165771,663.510986 
	C685.924194,658.003906 686.982971,652.304993 691.836548,648.279480 
	C692.180359,647.994324 691.203857,646.117188 690.924072,644.618530 
	C692.306274,641.479248 693.605774,638.701599 695.267822,635.918579 
	C698.115967,634.059021 700.826660,632.433777 703.017090,630.279480 
	C704.827454,628.499084 705.620239,625.576721 707.565491,624.060486 
	C713.776062,619.219849 720.346375,614.843079 726.690613,610.168884 
	C727.694153,609.429443 728.246887,608.078064 729.332886,606.874146 
	C731.425354,606.122437 733.192993,605.506104 735.331299,604.898560 
	C737.133850,604.578979 738.565613,604.250671 740.209473,604.191406 
	C750.019775,602.098938 759.652161,600.098938 769.652771,600.877747 
	C770.388855,600.935059 771.228760,599.658752 772.433472,598.998779 
	C774.223572,598.998413 775.600586,599.001648 777.066101,599.389893 
	C778.485229,600.180237 779.803955,600.630920 781.148438,600.983337 
	C788.471069,602.902649 795.804993,604.779236 803.124329,606.711182 
	C805.770081,607.409485 808.386230,608.220215 811.011108,608.987061 
	C811.006287,608.994385 811.019287,608.984619 811.049683,609.344910 
	C813.381470,613.842041 816.607483,615.188721 821.333496,612.999756 
	C822.095703,613.015259 822.531250,613.025757 823.006348,613.327393 
	C823.702148,613.732361 824.358398,613.846313 825.341064,613.969543 
	C826.102905,613.993164 826.538269,614.007568 827.069336,614.410522 
	C830.906494,616.294922 834.634094,617.582214 838.470337,614.059387 
	C841.236938,613.580994 843.760437,613.528381 845.877930,612.574280 
	C851.011475,610.261108 855.956055,607.528320 861.362427,604.979492 
	C865.396301,604.871338 866.741943,606.338562 866.118225,610.167908 
	C865.617249,613.242981 865.836304,616.478577 866.098328,619.612610 
	C866.197327,620.796570 867.313538,621.895447 867.972290,623.484131 
	C867.970947,626.945496 867.963379,629.955872 867.581421,633.060059 
	C865.589172,635.952820 864.519104,638.701843 867.936890,641.436035 
	C867.953491,642.874390 867.957764,643.920593 867.610779,645.043396 
	C865.322815,646.586975 866.064880,650.933105 861.733887,649.948181 
	C861.246582,649.638062 861.056213,649.379883 860.934204,648.710205 
	C857.861084,643.502319 853.881470,639.921326 847.968811,639.022522 
	C841.224365,634.736694 834.647034,630.148804 827.667175,626.288696 
	C824.016724,624.269775 819.659302,623.529480 814.926453,622.198242 
	C813.816284,622.177734 813.400879,622.170715 812.871887,621.908813 
	C812.169495,621.742310 811.580566,621.830627 810.632568,621.915955 
	C809.156799,622.150757 808.040100,622.388672 806.923462,622.626587 
	C805.251282,622.626526 803.579102,622.626404 801.240540,622.416992 
	C799.720947,622.100952 798.867676,621.994141 797.798706,621.642090 
	C796.725159,621.270508 795.867493,621.144104 794.812744,620.759399 
	C793.739746,621.000305 792.863770,621.499573 791.714050,622.115479 
	C790.944702,622.569092 790.449097,622.906006 789.777588,623.231445 
	C789.601746,623.220032 789.250427,623.191650 789.250427,623.191650 
	C783.210938,623.811890 775.562378,631.309021 774.993713,637.160583 
	C774.898010,638.145752 775.152588,639.461975 774.615845,640.062439 
	C767.190796,648.368652 772.279541,657.200928 775.487488,664.281250 
	C778.934753,671.889893 783.663879,679.650879 791.354919,684.845337 
	C796.957703,688.629456 801.661438,693.744324 806.768250,698.263428 
	C807.762512,699.143372 808.669800,700.168274 809.782593,700.860596 
	C821.164612,707.942017 832.865845,714.556519 843.874390,722.173645 
	C849.939941,726.370605 855.575928,731.255920 860.867859,736.735107 
	C870.255798,746.455139 873.874573,759.049072 880.130188,770.256470 
	C882.962097,775.330200 882.772156,782.089722 883.958191,788.083496 
	C884.503723,790.840454 885.396179,793.579285 885.526367,796.354675 
	C885.678223,799.591675 885.343628,802.884033 884.894653,806.107056 
	C884.486694,809.035034 883.807129,811.952148 882.948914,814.783691 
	C881.828003,818.481567 880.520691,822.132935 879.097961,825.726807 
	C877.320374,830.217102 875.603760,834.762085 873.402588,839.048096 
	C867.829651,849.899475 859.863281,858.924438 850.269775,866.274841 
	C841.728394,872.819031 832.140503,877.481628 821.223022,879.041992 
	C818.112793,879.486511 815.165100,881.068420 812.046387,882.475708 
	C804.289124,882.858582 796.628418,882.894592 788.416382,882.550781 
	C784.174316,880.588806 780.545654,878.821106 776.750610,877.550598 
	C775.756836,877.217957 774.246277,878.429260 772.973022,878.931519 
	C771.948120,878.922852 770.923218,878.914246 769.517334,878.873352 
M803.504883,621.028442 
	C804.195435,620.702942 804.885986,620.377441 805.576538,620.052002 
	C805.459595,619.693665 805.342712,619.335266 805.225769,618.976929 
	C804.002441,619.272278 802.779175,619.567688 801.555908,619.863098 
	C801.965332,620.241821 802.374756,620.620483 803.504883,621.028442 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M861.000488,731.365234 
	C864.860657,735.156128 869.524597,738.809448 872.464539,743.535217 
	C880.372986,756.247559 885.263062,770.277039 888.122742,784.977478 
	C888.592346,787.391479 888.668091,789.882080 888.827759,792.791931 
	C887.493774,789.309204 885.683289,785.451233 885.126282,781.420044 
	C883.200012,767.480469 876.098511,755.649597 869.986938,743.460999 
	C867.817749,739.134888 864.054016,735.608337 861.000488,731.365234 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M888.491760,796.304199 
	C888.672241,801.596863 889.660034,807.478699 888.304199,812.758728 
	C885.939148,821.968628 882.493164,830.961426 878.775452,839.738159 
	C876.904175,844.155945 873.441650,847.899658 870.348145,851.972900 
	C873.153931,845.668213 876.553528,839.446533 879.413147,832.985962 
	C884.530823,821.423767 888.020447,809.403320 888.491760,796.304199 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M869.479858,852.870178 
	C867.003296,856.562622 864.709167,860.897461 861.282654,863.968933 
	C853.716370,870.751160 846.030151,877.653503 835.220215,879.287170 
	C837.560364,877.556335 840.070435,875.992981 842.679810,874.618103 
	C851.652222,869.890564 859.503967,863.833069 865.747192,855.757629 
	C866.625916,854.621033 868.002441,853.869263 869.479858,852.870178 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M827.904175,707.519775 
	C830.897827,708.493103 834.185791,709.460571 837.044067,711.153503 
	C841.841614,713.995117 846.525330,717.090088 850.948425,720.480957 
	C853.885254,722.732544 856.284668,725.685120 858.959961,728.664795 
	C858.184204,728.906799 857.172363,729.086182 856.591980,728.667480 
	C847.030884,721.769592 837.524231,714.796387 827.904175,707.519775 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M798.014465,621.887390 
	C798.867676,621.994141 799.720947,622.100952 800.822327,622.409058 
	C797.932007,623.788696 794.833557,625.091064 791.646851,626.119751 
	C784.650757,628.378174 780.169128,632.803955 778.726196,640.176208 
	C778.531921,641.168884 777.477966,641.993347 776.481934,642.974609 
	C776.670410,634.114624 781.952759,628.373535 789.152893,623.524292 
	C789.250427,623.191650 789.601746,623.220032 789.992249,623.441162 
	C791.255188,623.444519 792.127625,623.226685 793.319946,622.984802 
	C794.066833,622.935364 794.493835,622.910034 795.273682,622.922974 
	C796.422424,622.603271 797.218445,622.245300 798.014465,621.887390 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M775.702698,645.277527 
	C776.308411,648.107971 776.618469,651.215942 776.852661,654.792480 
	C776.320190,652.025696 775.863586,648.790405 775.702698,645.277527 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M823.992432,705.401184 
	C824.868774,705.513794 825.804565,705.970032 826.858643,706.735779 
	C826.002014,706.611755 825.026978,706.178284 823.992432,705.401184 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M820.975464,703.390259 
	C821.603210,703.452515 822.285156,703.837585 823.026611,704.536377 
	C822.400574,704.471008 821.715088,704.092041 820.975464,703.390259 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M527.683228,508.044983 
	C529.566162,507.791504 531.074158,507.614410 532.793091,507.695007 
	C530.876160,509.543610 528.748413,511.134521 526.307861,512.860291 
	C526.432800,511.370544 526.870605,509.745972 527.683228,508.044983 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M509.432983,530.074219 
	C510.153748,528.466064 511.215332,526.773193 512.632568,525.043823 
	C511.916748,526.668030 510.845306,528.328735 509.432983,530.074219 
z"/>
<path fill="#563233" opacity="1.000000" stroke="none" 
	d="
M523.279846,509.852295 
	C523.411804,509.027832 523.835693,508.069031 524.593750,507.047913 
	C524.475830,507.896393 524.023743,508.807129 523.279846,509.852295 
z"/>
<path fill="#563233" opacity="1.000000" stroke="none" 
	d="
M521.359253,512.010376 
	C521.475769,511.413544 521.906067,510.748138 522.660156,510.035522 
	C522.546875,510.639465 522.109924,511.290619 521.359253,512.010376 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M860.946350,649.062439 
	C861.056213,649.379883 861.246582,649.638062 861.908203,650.265381 
	C863.809021,651.391907 865.319031,652.089966 866.829041,652.788086 
	C867.228149,651.322083 867.746582,649.875427 867.986877,648.383911 
	C868.165466,647.275024 867.983521,646.107971 867.962097,644.966858 
	C867.957764,643.920593 867.953491,642.874390 867.965088,640.986938 
	C867.972656,637.752563 867.964172,635.359375 867.955750,632.966187 
	C867.963379,629.955872 867.970947,626.945496 867.992188,623.012268 
	C868.010620,616.116577 868.024780,610.143738 868.016968,604.170959 
	C868.012146,600.468567 866.151733,600.249634 863.595703,602.023376 
	C862.547241,602.750916 861.839539,603.969482 860.976746,604.964600 
	C855.956055,607.528320 851.011475,610.261108 845.877930,612.574280 
	C843.760437,613.528381 841.236938,613.580994 838.008057,614.032959 
	C833.736572,614.022156 830.355103,614.022034 826.973572,614.021912 
	C826.538269,614.007568 826.102905,613.993164 825.166016,613.727234 
	C824.098572,613.329163 823.532654,613.182739 822.966736,613.036316 
	C822.531250,613.025757 822.095703,613.015259 821.097534,612.724731 
	C818.344849,611.309021 816.154907,610.173340 813.978516,609.023071 
	C813.992126,609.008484 814.017273,608.977478 814.368652,608.980286 
	C815.188843,608.954285 815.657593,608.925476 816.126404,608.896729 
	C822.307251,609.945374 828.523315,611.969788 834.658325,611.748413 
	C840.017761,611.555054 845.586853,609.455627 850.518555,607.071472 
	C856.704224,604.081116 862.334229,599.941284 868.902954,595.863708 
	C869.318359,597.740540 869.924194,599.216675 869.929871,600.695190 
	C869.997681,618.342407 870.046143,635.990540 869.886963,653.636597 
	C869.867737,655.767883 870.895935,660.638611 865.276855,656.790894 
	C864.277588,655.885315 863.624084,655.463074 862.970581,655.040894 
	C861.853210,654.106079 860.735840,653.171265 859.286133,651.768188 
	C858.306580,650.862061 857.659424,650.424255 857.012268,649.986389 
	C856.059509,648.881592 855.106689,647.776855 854.393738,646.347290 
	C856.737854,647.035828 858.842102,648.049133 860.946350,649.062439 
z"/>
<path fill="#020102" opacity="1.000000" stroke="none" 
	d="
M838.080994,599.186584 
	C838.824341,599.159363 839.567688,599.132141 840.684326,599.136108 
	C845.042847,602.433777 847.060486,597.254456 850.614990,597.033447 
	C851.329834,598.450928 851.662231,599.864868 852.003052,601.648560 
	C850.596191,603.023010 849.180786,604.027771 847.085693,605.059692 
	C845.939758,605.726196 845.473572,606.365540 845.007324,607.004883 
	C843.915344,607.338257 842.823425,607.671631 841.281128,607.614868 
	C840.021973,606.820068 839.141418,605.965149 838.415588,606.080933 
	C831.830933,607.131165 825.328857,607.239563 818.931519,605.039185 
	C816.751953,604.224426 814.572449,603.409668 812.184265,602.325134 
	C812.759766,601.058899 813.543884,600.062439 815.074951,599.079346 
	C820.389893,599.728333 824.945984,600.715515 829.530212,600.869873 
	C832.358398,600.965149 835.229248,599.790894 838.080994,599.186584 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M734.960571,604.889832 
	C733.192993,605.506104 731.425354,606.122437 728.999390,606.980957 
	C721.103699,612.080933 713.764771,616.796387 706.672974,621.858032 
	C703.264221,624.290955 700.322144,627.377747 697.105103,630.104858 
	C697.041382,630.041260 696.914062,629.914001 696.914062,629.914001 
	C697.202209,629.133606 697.490356,628.353149 697.880615,627.273926 
	C698.307373,626.512573 698.632019,626.050049 699.005676,625.296143 
	C708.825500,618.053101 718.444702,610.870605 728.459717,604.290588 
	C731.458313,602.320496 735.562500,602.033264 739.416626,601.292480 
	C738.102173,602.703186 736.531372,603.796509 734.960571,604.889832 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M811.015869,608.979614 
	C808.386230,608.220215 805.770081,607.409485 803.124329,606.711182 
	C795.804993,604.779236 788.471069,602.902649 781.148438,600.983337 
	C779.803955,600.630920 778.485229,600.180237 777.454895,599.375488 
	C779.170959,598.652283 780.586731,598.328613 782.375732,598.009155 
	C783.494873,598.039795 784.240723,598.066284 785.052856,598.424438 
	C786.413513,598.841675 787.708008,598.927307 789.359497,599.010498 
	C790.188599,598.945740 790.660645,598.883423 791.132751,598.821045 
	C793.595642,599.348022 796.058472,599.875061 798.826782,601.019653 
	C800.422363,602.087524 801.712524,602.537781 803.325562,603.010010 
	C804.078918,603.041382 804.509460,603.050781 804.940002,603.060120 
	C804.940002,603.060120 805.377808,603.164185 805.689697,603.507690 
	C806.697449,604.196228 807.393311,604.541138 808.089233,604.886108 
	C808.969910,605.380859 809.850647,605.875549 810.885437,606.955811 
	C811.031616,608.020813 811.023743,608.500244 811.015869,608.979614 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M772.020386,599.002441 
	C771.228760,599.658752 770.388855,600.935059 769.652771,600.877747 
	C759.652161,600.098938 750.019775,602.098938 740.422485,603.924683 
	C741.988098,602.026489 743.552673,600.664062 745.117310,599.301575 
	C746.441589,599.173950 747.765930,599.046326 749.838013,598.948364 
	C751.392151,598.656921 752.198486,598.335754 753.004883,598.014526 
	C754.035095,597.970032 755.065247,597.925598 756.832642,597.922913 
	C758.045776,597.979004 758.521667,597.993286 759.292480,598.296631 
	C763.731750,598.724548 767.876099,598.863525 772.020386,599.002441 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M819.011230,605.395874 
	C825.328857,607.239563 831.830933,607.131165 838.415588,606.080933 
	C839.141418,605.965149 840.021973,606.820068 840.914673,607.612366 
	C833.390320,610.230774 826.013794,610.203308 819.011230,605.395874 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M772.433472,598.998779 
	C767.876099,598.863525 763.731750,598.724548 759.294189,598.024048 
	C759.001770,597.280884 759.002625,597.099243 759.003540,596.917664 
	C766.355286,596.923767 773.706970,596.929932 781.528442,597.206177 
	C781.999573,597.652527 782.001038,597.828674 782.002441,598.004883 
	C780.586731,598.328613 779.170959,598.652283 777.366455,598.990479 
	C775.600586,599.001648 774.223572,598.998413 772.433472,598.998779 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M860.934204,648.710205 
	C858.842102,648.049133 856.737854,647.035828 854.258789,646.057190 
	C853.160095,645.781311 852.436279,645.470703 851.416138,644.706177 
	C851.075439,643.839539 851.031067,643.426880 850.986633,643.014160 
	C850.081970,641.878479 849.177307,640.742859 848.120728,639.314819 
	C853.881470,639.921326 857.861084,643.502319 860.934204,648.710205 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M744.780212,599.214661 
	C743.552673,600.664062 741.988098,602.026489 740.210449,603.655640 
	C738.565613,604.250671 737.133850,604.578979 735.331299,604.898560 
	C736.531372,603.796509 738.102173,602.703186 739.830444,601.293701 
	C739.987854,600.977600 740.422058,600.916809 740.967529,600.906738 
	C742.027649,600.317444 742.542297,599.738220 743.056946,599.158936 
	C743.518982,599.148560 743.981079,599.138184 744.780212,599.214661 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M789.002441,599.012878 
	C787.708008,598.927307 786.413513,598.841675 785.061646,598.162476 
	C785.005493,597.394714 785.006714,597.220642 785.007935,597.046570 
	C786.052002,597.067932 787.096008,597.089355 788.550537,597.454224 
	C788.974854,598.202759 788.988708,598.607849 789.002441,599.012878 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M803.002747,602.988037 
	C801.712524,602.537781 800.422363,602.087524 799.099365,601.266724 
	C799.500854,600.894043 799.935120,600.891907 800.836243,601.169189 
	C801.869629,601.961792 802.436218,602.474915 803.002747,602.988037 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M803.325562,603.010010 
	C802.436218,602.474915 801.869629,601.961792 801.161743,601.192566 
	C802.302979,601.400452 803.585632,601.864441 804.904114,602.694275 
	C804.509460,603.050781 804.078918,603.041382 803.325562,603.010010 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M752.815308,597.757996 
	C752.198486,598.335754 751.392151,598.656921 750.236328,598.913574 
	C750.799927,598.399841 751.712830,597.950623 752.815308,597.757996 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M784.828369,596.796753 
	C785.006714,597.220642 785.005493,597.394714 784.995422,597.830811 
	C784.240723,598.066284 783.494873,598.039795 782.375732,598.009094 
	C782.001038,597.828674 781.999573,597.652527 781.996765,597.211548 
	C782.879883,596.813477 783.764343,596.680176 784.828369,596.796753 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M846.044312,641.359009 
	C846.898193,641.487488 847.810120,641.956665 848.851196,642.722717 
	C848.021057,642.579590 847.061707,642.139648 846.044312,641.359009 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M811.011108,608.987000 
	C811.023743,608.500244 811.031616,608.020813 811.087158,607.181763 
	C811.552795,606.848083 811.970703,606.874023 812.796875,607.195923 
	C813.475830,607.987061 813.746582,608.482239 814.017273,608.977478 
	C814.017273,608.977478 813.992126,609.008484 813.610901,609.012085 
	C812.492859,609.005371 811.756104,608.994995 811.019287,608.984619 
	C811.019287,608.984619 811.006287,608.994385 811.011108,608.987000 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M814.368652,608.980286 
	C813.746582,608.482239 813.475830,607.987061 813.110718,607.224365 
	C813.988464,607.385071 814.960693,607.813171 816.029663,608.568970 
	C815.657593,608.925476 815.188843,608.954285 814.368652,608.980286 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M845.327759,606.965210 
	C845.473572,606.365540 845.939758,605.726196 846.735657,605.060791 
	C846.592957,605.664978 846.120544,606.295227 845.327759,606.965210 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M857.047485,650.312744 
	C857.659424,650.424255 858.306580,650.862061 859.014343,651.611450 
	C858.410828,651.495056 857.746826,651.067078 857.047485,650.312744 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M850.652588,643.015747 
	C851.031067,643.426880 851.075439,643.839539 851.152222,644.560425 
	C850.485046,644.473511 849.785400,644.078430 849.033691,643.354370 
	C849.427185,643.022644 849.872864,643.020020 850.652588,643.015747 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M789.359497,599.010498 
	C788.988708,598.607849 788.974854,598.202759 788.939819,597.498047 
	C789.602112,597.538513 790.285706,597.878662 791.051025,598.519958 
	C790.660645,598.883423 790.188599,598.945740 789.359497,599.010498 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M742.737488,599.149963 
	C742.542297,599.738220 742.027649,600.317444 741.186157,600.890991 
	C741.378906,600.303894 741.898499,599.722473 742.737488,599.149963 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M863.020508,655.358521 
	C863.624084,655.463074 864.277588,655.885315 864.984497,656.622681 
	C864.382080,656.517212 863.726318,656.096680 863.020508,655.358521 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M758.826904,596.709229 
	C759.002625,597.099243 759.001770,597.280884 758.999207,597.735046 
	C758.521667,597.993286 758.045776,597.979004 757.217957,597.890564 
	C757.460815,597.377808 758.055542,596.939270 758.826904,596.709229 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M808.017822,604.568848 
	C807.393311,604.541138 806.697449,604.196228 805.910645,603.550293 
	C806.528564,603.583374 807.237488,603.917480 808.017822,604.568848 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M672.946045,769.052795 
	C671.171875,769.052795 669.397766,769.052795 667.424683,769.052795 
	C667.424683,780.011108 667.404846,790.403015 667.441040,800.794800 
	C667.448425,802.923767 667.825928,805.057678 667.750183,807.179199 
	C667.621033,810.798706 667.000977,814.415161 667.067627,818.023987 
	C667.187805,824.538513 667.668396,831.046143 667.986694,837.557251 
	C668.201050,841.942078 669.705261,842.925537 673.546387,840.931946 
	C676.593018,839.350769 679.591309,837.646606 682.472046,835.782288 
	C688.627014,831.799011 698.998779,832.031189 704.536072,836.796936 
	C703.827576,837.173584 703.415833,837.181641 702.782349,836.872986 
	C699.374695,836.452759 696.188843,836.349243 692.697144,836.233521 
	C691.983398,836.227234 691.575439,836.233093 690.756592,836.162720 
	C688.229675,837.055908 686.113586,838.025452 683.643127,839.023193 
	C681.806580,840.319336 680.324463,841.587280 678.842285,842.855225 
	C678.842285,842.855225 678.426453,842.923218 677.863892,842.974854 
	C674.774292,844.568787 672.195129,846.032898 669.734070,847.674133 
	C666.975952,849.513428 665.037659,849.753479 665.033691,845.615234 
	C665.007690,818.578064 664.968872,791.540710 665.120117,764.504395 
	C665.126648,763.335144 666.955200,762.176086 667.935059,761.012268 
	C667.935059,761.012268 667.955078,761.047302 667.984558,761.398438 
	C669.724365,764.118286 671.434753,766.487000 673.095276,768.904907 
	C673.045471,768.954163 672.946045,769.052795 672.946045,769.052795 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M758.011475,836.008484 
	C753.117065,839.062744 748.765869,835.467590 745.180725,833.727417 
	C737.007019,829.760010 728.806946,825.128235 722.023132,819.166809 
	C708.145813,806.972107 695.321350,793.587097 681.775391,781.002136 
	C678.175171,777.657288 673.349060,775.581726 672.969849,769.428162 
	C672.946045,769.052795 673.045471,768.954163 673.423462,768.982788 
	C676.789917,771.419434 679.778381,773.827454 682.766846,776.235535 
	C682.766846,776.235535 682.852905,776.629517 682.934082,777.166992 
	C685.012207,779.466919 687.009094,781.229492 689.005981,782.992065 
	C689.005981,782.992065 689.000000,783.000000 689.010803,783.346069 
	C694.344666,788.797058 699.667786,793.901917 704.990906,799.006775 
	C704.990906,799.006775 705.000000,799.000000 705.017212,799.331909 
	C705.686951,800.110596 706.339478,800.557434 706.991943,801.004211 
	C706.991943,801.004211 707.000000,801.000000 707.014771,801.346008 
	C708.017822,802.463562 709.006104,803.235107 709.994385,804.006592 
	C710.110291,804.319214 710.302002,804.575134 710.898071,805.203125 
	C714.745789,808.485291 718.264832,811.338562 721.783936,814.191772 
	C721.783936,814.191772 721.796997,814.256653 721.793396,814.292114 
	C725.065857,817.246155 728.072021,820.568909 731.685852,822.981689 
	C736.276978,826.046997 741.360718,828.374451 746.780334,831.340332 
	C750.889587,833.109863 754.450500,834.559204 758.011475,836.008484 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M667.929199,760.643066 
	C666.955200,762.176086 665.126648,763.335144 665.120117,764.504395 
	C664.968872,791.540710 665.007690,818.578064 665.033691,845.615234 
	C665.037659,849.753479 666.975952,849.513428 669.734070,847.674133 
	C672.195129,846.032898 674.774292,844.568787 677.659729,843.026855 
	C674.543579,845.892578 671.185791,848.929443 667.478149,851.452087 
	C666.868896,851.866638 664.476013,850.259888 663.381653,849.128540 
	C662.758606,848.484558 663.014221,846.911255 663.013367,845.756287 
	C662.993408,818.271118 662.965698,790.785828 663.054504,763.300964 
	C663.059875,761.651489 664.023743,760.005188 664.541626,758.357422 
	C665.668884,758.996216 666.796082,759.635010 667.929199,760.643066 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M703.004089,837.189636 
	C703.415833,837.181641 703.827576,837.173584 704.546143,837.168274 
	C704.852966,837.171021 704.842773,837.225281 704.937134,837.578125 
	C707.341980,838.965088 709.652405,839.999268 711.962830,841.033386 
	C711.962830,841.033386 712.414978,841.145020 712.734131,841.493652 
	C714.394958,843.524231 715.736755,845.206116 717.078552,846.888062 
	C714.351196,845.405823 711.632202,843.907959 708.894958,842.444336 
	C700.531921,837.972229 692.032776,838.254272 683.430115,841.706543 
	C682.201538,842.199524 680.898804,842.507629 679.236328,842.878235 
	C680.324463,841.587280 681.806580,840.319336 684.024170,838.992065 
	C686.895630,838.034912 689.031555,837.137024 691.167480,836.239014 
	C691.575439,836.233093 691.983398,836.227234 692.991028,836.467651 
	C696.728516,836.872498 699.866272,837.031067 703.004089,837.189636 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M717.295959,846.936035 
	C715.736755,845.206116 714.394958,843.524231 712.963440,841.534058 
	C716.622681,840.972900 719.132080,843.060303 721.798706,846.173340 
	C724.495178,848.768921 726.735474,850.903015 728.975769,853.037170 
	C729.007385,853.455688 729.039062,853.874207 728.721436,854.638367 
	C727.897522,855.007935 727.422729,855.031921 726.947998,855.055908 
	C726.947998,855.055908 726.511353,854.978943 726.239136,854.605652 
	C723.296082,851.836731 720.625244,849.441162 717.954346,847.045593 
	C717.954346,847.045593 717.513367,846.983948 717.295959,846.936035 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M704.978455,798.658936 
	C699.667786,793.901917 694.344666,788.797058 689.014771,783.343140 
	C694.327271,788.099731 699.646606,793.205444 704.978455,798.658936 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M682.819580,775.873840 
	C679.778381,773.827454 676.789917,771.419434 673.473267,768.933533 
	C671.434753,766.487000 669.724365,764.118286 668.002686,761.407471 
	C672.951599,765.880859 677.911926,770.696533 682.819580,775.873840 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M755.981079,873.020020 
	C753.553894,872.375793 751.126709,871.731506 748.320679,871.073608 
	C747.941833,871.059937 747.505310,870.980774 747.224487,870.619019 
	C745.587524,868.892334 744.231445,867.527466 743.246094,866.143555 
	C747.738159,868.423096 751.859619,870.721619 755.981079,873.020020 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M721.724609,813.856567 
	C718.264832,811.338562 714.745789,808.485291 711.112000,805.317261 
	C714.553284,807.842163 718.109314,810.681763 721.724609,813.856567 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M717.998047,847.385864 
	C720.625244,849.441162 723.296082,851.836731 726.020996,854.566162 
	C723.397339,852.508606 720.719543,850.117310 717.998047,847.385864 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M742.875366,866.162537 
	C744.231445,867.527466 745.587524,868.892334 747.005493,870.583740 
	C744.391724,869.180542 741.716125,867.450806 739.343323,865.361206 
	C740.087219,865.010437 740.528381,865.019653 741.222046,865.144287 
	C741.941589,865.560730 742.408508,865.861633 742.875366,866.162537 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M688.989136,782.645386 
	C687.009094,781.229492 685.012207,779.466919 683.001587,777.356934 
	C684.982666,778.772522 686.977417,780.535583 688.989136,782.645386 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M731.037720,856.953613 
	C731.766052,856.977173 732.494446,857.000671 733.645386,857.368896 
	C735.063477,859.123657 736.059021,860.533752 737.026855,861.973694 
	C736.999023,862.003540 736.948547,862.067566 736.612305,862.048645 
	C735.826416,862.046570 735.376770,862.063416 734.927124,862.080200 
	C734.927124,862.080261 734.486938,862.011047 734.214111,861.657166 
	C733.271484,860.893555 732.601685,860.483826 731.931946,860.074097 
	C731.804626,859.765381 731.607544,859.510559 731.156616,858.838257 
	C730.994202,857.895752 731.015991,857.424683 731.037720,856.953613 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M730.908936,856.696594 
	C731.015991,857.424683 730.994202,857.895752 730.942993,858.720215 
	C729.633362,857.950928 728.353088,856.828247 727.010376,855.380737 
	C727.422729,855.031921 727.897522,855.007935 728.728516,854.953125 
	C729.649902,855.427979 730.214966,855.933777 730.908936,856.696594 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M740.969482,865.028809 
	C740.528381,865.019653 740.087219,865.010437 739.313354,865.010803 
	C738.980530,865.020325 738.518127,864.992432 738.253418,864.650757 
	C737.642029,863.561829 737.295288,862.814697 736.948547,862.067566 
	C736.948547,862.067566 736.999023,862.003540 737.384644,861.996643 
	C738.836670,863.002747 739.903076,864.015747 740.969482,865.028809 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M736.612305,862.048645 
	C737.295288,862.814697 737.642029,863.561829 738.024414,864.616455 
	C737.050537,864.198120 736.041016,863.472412 734.979309,862.413452 
	C735.376770,862.063416 735.826416,862.046570 736.612305,862.048645 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M709.979004,803.662109 
	C709.006104,803.235107 708.017822,802.463562 707.012634,801.349976 
	C707.985046,801.777893 708.974365,802.547791 709.979004,803.662109 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M731.986755,860.392334 
	C732.601685,860.483826 733.271484,860.893555 733.994141,861.621887 
	C733.378540,861.530518 732.710083,861.120544 731.986755,860.392334 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M706.970581,800.676575 
	C706.339478,800.557434 705.686951,800.110596 705.013855,799.336426 
	C705.645264,799.455627 706.297241,799.902283 706.970581,800.676575 
z"/>
<path fill="#1E0E0E" opacity="1.000000" stroke="none" 
	d="
M804.559326,585.024902 
	C806.253662,587.415222 807.602417,589.844971 808.989136,592.630005 
	C808.575989,592.993042 808.124878,593.000916 807.335449,593.006592 
	C805.234314,592.027588 803.471558,591.050842 801.355591,590.035767 
	C802.072876,588.353149 803.143311,586.708740 804.559326,585.024902 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M690.841309,644.980286 
	C691.203857,646.117188 692.180359,647.994324 691.836548,648.279480 
	C686.982971,652.304993 685.924194,658.003906 686.165771,663.510986 
	C686.634460,674.195374 688.052368,684.838257 689.085144,695.497498 
	C689.116760,695.824097 689.168518,696.182495 689.328186,696.458069 
	C692.741760,702.350220 696.193054,708.220642 699.587524,714.123718 
	C700.734070,716.117676 701.469116,718.405518 702.864868,720.189087 
	C709.363586,728.492981 716.152161,736.235535 726.196777,740.899353 
	C732.730591,743.933167 738.278198,749.090881 744.268005,753.296570 
	C744.809570,753.676819 745.305359,754.175598 745.904236,754.412781 
	C756.117432,758.455872 763.909790,766.755737 773.828125,770.982910 
	C789.120667,777.500610 793.941345,790.594727 796.719788,804.863708 
	C797.949951,811.181213 795.340027,817.651733 790.807983,822.585571 
	C785.221497,828.667358 779.690674,835.053955 770.847534,836.519958 
	C769.431274,836.754822 767.944702,836.565491 766.234375,836.001221 
	C765.968384,835.234985 765.958740,835.039673 765.949097,834.844421 
	C772.095520,833.237976 778.311218,831.806458 781.862122,825.622681 
	C782.237732,824.968506 783.625427,824.895447 784.781128,824.282471 
	C790.009033,821.632080 792.164795,817.095947 792.834167,812.146667 
	C794.445007,800.238342 790.108337,782.982361 775.244019,774.459534 
	C757.026123,764.013733 739.566101,752.252869 721.676270,741.226135 
	C702.377502,729.330933 691.451050,711.599304 685.605530,690.249329 
	C685.001709,688.043823 684.820801,685.722595 684.114563,683.208862 
	C683.771545,681.960876 683.757629,680.959106 683.804749,679.163147 
	C683.526611,676.578308 683.187439,674.787659 682.848267,672.996948 
	C682.852173,670.631958 682.856018,668.266968 683.170288,665.223755 
	C683.552673,662.966492 683.624695,661.387512 683.696655,659.808472 
	C683.736267,659.103699 683.775818,658.398987 684.132080,657.150391 
	C686.579590,652.731079 688.710449,648.855713 690.841309,644.980286 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M786.459839,819.071167 
	C787.471802,815.489502 788.834534,811.821289 790.555359,808.064636 
	C790.768555,812.101318 791.304443,816.480225 786.459839,819.071167 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M774.862366,777.709290 
	C774.031311,777.564758 773.066345,777.127075 772.049500,776.344727 
	C772.907898,776.472046 773.818176,776.944092 774.862366,777.709290 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M783.315735,822.983093 
	C783.460266,822.347656 783.929749,821.715881 784.724792,821.051453 
	C785.032532,821.444641 785.014709,821.870422 784.966797,822.613464 
	C784.504700,822.949402 784.072693,822.968079 783.315735,822.983093 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M689.357117,710.980896 
	C689.032898,710.497925 689.036499,710.025208 689.078735,709.196838 
	C689.720764,709.349487 690.324219,709.857666 690.962585,710.682922 
	C690.559998,710.997009 690.122498,710.994080 689.357117,710.980896 
z"/>
<path fill="#390709" opacity="1.000000" stroke="none" 
	d="
M789.952515,798.690918 
	C789.357544,798.497925 788.739136,797.986145 788.142029,797.131348 
	C788.751892,797.316284 789.340454,797.844116 789.952515,798.690918 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M829.035767,630.369507 
	C831.971863,631.757080 834.965210,633.484802 838.030151,635.549805 
	C835.098816,634.161194 832.095947,632.435486 829.035767,630.369507 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M812.985413,622.163696 
	C813.400879,622.170715 813.816284,622.177734 814.535645,622.248291 
	C814.907776,622.953064 814.976013,623.594421 815.078247,624.557617 
	C812.520874,624.360046 809.929504,623.840576 807.130737,622.973877 
	C808.040100,622.388672 809.156799,622.150757 810.756348,622.154602 
	C811.821289,622.318726 812.403320,622.241211 812.985413,622.163696 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M823.054077,627.346558 
	C824.637268,627.755737 826.276917,628.509033 827.989990,629.598877 
	C826.412415,629.187134 824.761475,628.438904 823.054077,627.346558 
z"/>
<path fill="#5C1114" opacity="1.000000" stroke="none" 
	d="
M819.984863,625.462280 
	C820.587830,625.528503 821.267029,625.901489 822.021118,626.584961 
	C821.417786,626.520020 820.739380,626.144531 819.984863,625.462280 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M977.052612,897.452271 
	C977.500916,896.203003 978.322510,895.361267 978.258057,894.593872 
	C977.984131,891.332031 977.447693,888.092224 977.021240,884.403320 
	C977.772766,883.976501 978.510193,883.990723 979.616455,884.006104 
	C979.989929,885.394104 979.994568,886.780823 980.000732,888.949646 
	C980.332336,890.822693 980.662415,891.913757 980.992432,893.004822 
	C980.993591,893.753601 980.994751,894.502380 980.996765,896.093506 
	C981.000061,902.623962 981.002441,908.311951 981.004883,914.000000 
	C979.864807,923.806641 975.177246,931.499268 968.847656,939.151672 
	C961.703796,947.788452 952.909546,952.080811 942.377563,954.959717 
	C940.928711,955.003235 939.859863,954.999207 938.389893,954.985718 
	C938.958862,953.890320 939.928955,952.804321 940.955872,951.386780 
	C941.449707,951.029358 941.886780,951.003479 942.862427,951.227661 
	C946.600220,949.984253 949.799316,948.490967 952.998352,946.997559 
	C952.998352,946.997559 953.000000,947.000000 953.384949,947.001099 
	C959.393250,946.055359 964.917114,941.107727 966.012085,936.037048 
	C966.905762,935.205627 967.799438,934.374207 969.196655,933.236450 
	C974.591125,929.517090 978.279785,920.885864 976.947144,915.972778 
	C976.968018,913.613464 976.988953,911.254211 977.357666,908.398438 
	C977.488525,906.930786 977.271729,905.959473 977.054932,904.988220 
	C977.042725,902.623474 977.030579,900.258789 977.052612,897.452271 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M976.671387,916.200562 
	C978.279785,920.885864 974.591125,929.517090 969.347961,932.963806 
	C971.462402,927.474487 973.929016,921.951416 976.671387,916.200562 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M965.703735,936.163513 
	C964.917114,941.107727 959.393250,946.055359 953.383667,946.999878 
	C953.009155,946.575378 953.020752,946.153198 953.047363,945.416687 
	C957.173462,942.164856 961.284485,939.227417 965.703735,936.163513 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M977.024414,905.365540 
	C977.271729,905.959473 977.488525,906.930786 977.378540,907.956726 
	C977.032471,907.255249 977.013184,906.499023 977.024414,905.365540 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M765.694336,834.591980 
	C765.958740,835.039673 765.968384,835.234985 765.973145,835.723633 
	C763.610718,836.016663 761.253174,836.016235 758.453552,836.012146 
	C754.450500,834.559204 750.889587,833.109863 747.150635,831.358459 
	C750.446594,831.603210 753.920471,832.150085 757.660156,832.459473 
	C758.364441,832.207092 758.803040,832.192200 759.565308,832.231567 
	C761.739258,832.970337 763.589355,833.654907 765.694336,834.591980 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M690.924072,644.618530 
	C688.710449,648.855713 686.579590,652.731079 684.137451,656.796936 
	C684.223572,654.312683 683.961487,651.294067 685.143372,649.028687 
	C688.527405,642.541992 692.496948,636.360779 696.575195,629.986755 
	C696.914062,629.914001 697.041382,630.041260 697.015747,630.433960 
	C696.295105,632.525696 695.600159,634.224854 694.905273,635.924011 
	C693.605774,638.701599 692.306274,641.479248 690.924072,644.618530 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M683.383545,659.865356 
	C683.624695,661.387512 683.552673,662.966492 683.162964,664.780884 
	C682.422363,664.747803 681.999451,664.479370 681.353638,664.098083 
	C681.129211,662.974243 681.127808,661.963318 681.152344,660.580383 
	C681.808960,660.112976 682.439758,660.017578 683.383545,659.865356 
z"/>
<path fill="#270303" opacity="1.000000" stroke="none" 
	d="
M837.610168,599.123291 
	C835.229248,599.790894 832.358398,600.965149 829.530212,600.869873 
	C824.945984,600.715515 820.389893,599.728333 815.418701,599.042725 
	C813.364380,597.245361 811.713196,595.498108 810.029114,593.401978 
	C810.806458,593.142212 811.869080,592.930115 812.388916,593.365662 
	C819.690125,599.484131 828.400818,599.304016 837.610168,599.123291 
z"/>
<path fill="#612020" opacity="1.000000" stroke="none" 
	d="
M952.607910,946.996704 
	C949.799316,948.490967 946.600220,949.984253 943.190735,951.217896 
	C946.059387,949.637390 949.138367,948.316589 952.607910,946.996704 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M773.190796,879.009399 
	C774.246277,878.429260 775.756836,877.217957 776.750610,877.550598 
	C780.545654,878.821106 784.174316,880.588806 787.952148,882.508057 
	C787.113770,882.832581 786.188232,882.820007 785.118713,883.048706 
	C784.540771,883.204712 784.106873,883.119446 783.339844,883.034424 
	C781.255615,882.380798 779.504517,881.726929 777.321899,880.670959 
	C775.881348,879.903381 774.872375,879.537842 773.863403,879.172241 
	C773.863403,879.172241 773.408630,879.087341 773.190796,879.009399 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M682.634766,673.158691 
	C683.187439,674.787659 683.526611,676.578308 683.762573,678.789001 
	C683.296753,679.147278 682.934021,679.085571 682.293823,679.009216 
	C681.736267,677.895386 681.456299,676.796204 681.171448,675.355347 
	C681.584900,674.449219 682.003174,673.884827 682.634766,673.158691 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M741.222046,865.144287 
	C739.903076,864.015747 738.836670,863.002747 737.412415,861.966797 
	C736.059021,860.533752 735.063477,859.123657 734.009399,857.385803 
	C737.519592,859.056519 741.091125,861.050232 744.656311,863.055115 
	C747.614685,864.718689 750.662170,866.247925 753.496582,868.102051 
	C757.420227,870.668579 761.181091,873.484009 765.031433,876.555786 
	C764.870911,876.938049 764.690613,876.957336 764.239441,877.004639 
	C763.968567,877.032593 763.507812,876.979614 763.210999,876.620544 
	C760.907166,875.215576 758.900208,874.169678 756.893188,873.123779 
	C756.893188,873.123779 756.436707,873.077209 756.208862,873.048645 
	C751.859619,870.721619 747.738159,868.423096 743.246094,866.143555 
	C742.408508,865.861633 741.941589,865.560730 741.222046,865.144287 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M756.975464,873.452820 
	C758.900208,874.169678 760.907166,875.215576 762.980469,876.595642 
	C761.050354,875.880493 759.054016,874.831177 756.975464,873.452820 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M773.960327,879.497925 
	C774.872375,879.537842 775.881348,879.903381 776.960388,880.613525 
	C776.039368,880.580017 775.048340,880.201843 773.960327,879.497925 
z"/>
<path fill="#851314" opacity="1.000000" stroke="none" 
	d="
M1058.080444,1008.618530 
	C1023.312439,1009.000000 988.624878,1009.000000 953.278503,1008.659790 
	C947.987366,1007.546326 943.359253,1006.134277 938.722046,1006.103638 
	C908.330933,1005.902588 877.937500,1005.946960 847.545715,1006.087158 
	C844.694214,1006.100281 841.848389,1007.333313 839.000000,1008.000000 
	C829.546570,1008.333374 820.091431,1008.980530 810.640015,1008.931030 
	C793.853027,1008.843262 777.067200,1008.006714 760.282715,1008.059631 
	C704.674133,1008.234741 649.066528,1008.720520 593.457947,1008.909119 
	C588.415771,1008.926208 583.366516,1007.075867 578.325256,1007.099243 
	C552.374512,1007.219482 526.424438,1007.613464 500.476013,1008.030334 
	C490.439636,1008.191589 480.406494,1008.988953 470.374969,1008.934082 
	C441.592224,1008.776794 412.810699,1008.354919 384.029297,1007.992798 
	C360.332184,1007.694702 336.525879,1005.777771 312.970612,1007.443726 
	C290.073486,1009.063171 267.262970,1008.532349 244.417801,1008.919434 
	C235.217682,1009.075378 226.004715,1008.195923 216.793304,1008.037598 
	C207.052109,1007.870117 197.302490,1008.152161 187.563065,1007.939758 
	C182.216415,1007.823120 176.846191,1007.322327 171.570084,1006.474915 
	C170.265305,1006.265442 169.285721,1004.031189 168.156677,1002.727661 
	C169.292236,1001.821106 170.341064,1000.757935 171.594345,1000.063965 
	C172.402023,999.616699 173.509750,999.711365 174.741501,999.281616 
	C196.499435,999.000000 217.999603,998.896606 239.498016,999.045105 
	C249.705109,999.115601 259.906860,999.838074 270.114655,999.958435 
	C284.191986,1000.124390 298.274170,1000.124634 312.351501,999.958557 
	C322.644196,999.837036 332.939819,998.917236 343.220703,999.102234 
	C356.975006,999.349670 370.711182,1000.543457 384.465973,1000.859741 
	C389.189697,1000.968262 393.940491,999.140503 398.688324,999.090393 
	C418.852234,998.877502 439.022125,998.809875 459.184875,999.071350 
	C471.284698,999.228149 483.371399,1000.305725 495.472137,1000.530029 
	C500.385712,1000.621216 505.317078,999.119446 510.247009,999.074646 
	C530.078186,998.894165 549.914734,998.797119 569.743774,999.070679 
	C583.175781,999.255920 596.601196,1000.620300 610.024780,1000.549011 
	C630.951233,1000.437683 651.871033,999.357483 672.798889,999.082642 
	C683.416199,998.943176 694.046570,999.992859 704.668091,999.937561 
	C734.125366,999.784241 763.580872,999.135681 793.037659,999.091980 
	C805.800842,999.073059 818.561951,1000.263916 831.331299,1000.486450 
	C836.410034,1000.574951 841.506836,999.090759 846.598328,999.070740 
	C881.096863,998.935303 915.596252,999.000000 950.095337,999.000000 
	C957.174805,999.000000 964.254333,999.000000 971.333801,999.000000 
	C985.421814,999.000000 999.511292,998.869995 1013.597351,999.035828 
	C1028.222656,999.208069 1042.843384,999.744080 1057.468506,999.972290 
	C1066.879761,1000.119141 1076.295288,999.997253 1085.708862,999.996399 
	C1092.023438,999.995789 1098.338013,999.996216 1104.887085,999.996216 
	C1103.348633,1005.072998 1098.032349,1007.158508 1092.198242,1006.984253 
	C1081.521362,1006.665283 1070.825562,1006.954590 1060.138550,1007.075684 
	C1059.474976,1007.083191 1058.819946,1007.832764 1058.080444,1008.618530 
z"/>
<path fill="#851314" opacity="1.000000" stroke="none" 
	d="
M797.000000,976.000000 
	C662.006287,976.000000 527.512573,976.009888 393.018860,975.941406 
	C389.757294,975.939758 386.497040,975.059631 383.234497,974.601562 
	C381.934845,974.419006 380.286377,973.678223 379.384613,974.202576 
	C373.864594,977.411926 368.803223,975.177429 363.615143,973.391846 
	C361.745789,972.748474 359.645386,972.713684 357.849854,971.938965 
	C356.442383,971.331726 355.342255,970.012085 354.105591,969.008972 
	C355.379364,968.339417 356.646881,967.102112 357.927856,967.088074 
	C371.006073,966.944641 384.086609,967.034485 397.166290,966.994568 
	C503.118500,966.670898 609.070618,966.316833 715.023010,966.044983 
	C720.812317,966.030151 726.604675,966.977844 732.393250,966.951660 
	C788.348145,966.698914 844.302612,966.343140 900.256836,965.962036 
	C902.382324,965.947571 904.502380,965.363586 906.627808,965.065186 
	C911.481628,964.383606 913.559082,965.935364 913.735046,970.796936 
	C913.882568,974.871460 910.840027,974.978088 907.863647,975.014160 
	C881.408447,975.334717 854.953735,975.704712 828.498108,975.982239 
	C818.166504,976.090576 807.832764,976.000000 797.000000,976.000000 
z"/>
<path fill="#851314" opacity="1.000000" stroke="none" 
	d="
M665.000000,984.000000 
	C744.822510,984.000000 824.145081,984.012939 903.467590,983.961548 
	C907.414062,983.958984 910.845337,984.412476 914.085571,987.187134 
	C915.728699,988.594177 918.688416,988.530090 921.082153,988.973206 
	C922.998169,989.327881 924.958374,989.443970 926.898926,989.665894 
	C926.893921,990.220520 926.888916,990.775146 926.883911,991.329834 
	C925.580933,991.552917 924.280884,991.950989 922.974487,991.973145 
	C901.187622,992.343323 879.399475,992.633057 857.612732,993.011108 
	C841.656799,993.287842 825.702148,993.959412 809.747864,993.927917 
	C788.459778,993.885986 767.173157,993.120361 745.884949,993.071716 
	C730.597412,993.036804 715.308472,993.923950 700.020020,993.926819 
	C685.065002,993.929626 670.109070,993.031067 655.154968,993.070984 
	C632.866821,993.130554 610.580139,993.908325 588.292480,993.927063 
	C568.838318,993.943420 549.383484,993.088806 529.929260,993.101929 
	C520.010315,993.108582 510.090363,994.673645 500.173950,994.639648 
	C487.590210,994.596497 475.013397,993.520874 462.426208,993.128540 
	C454.102448,992.869080 445.765686,993.015320 437.434692,993.003296 
	C427.602722,992.989075 417.765686,992.801697 407.940033,993.051208 
	C396.202820,993.349243 384.478729,994.145630 372.742035,994.490845 
	C367.801605,994.636169 362.844452,994.140869 357.893005,994.008728 
	C354.275879,993.912109 352.739624,991.961975 353.042786,988.550720 
	C353.315613,985.480713 353.764648,982.825134 358.172424,982.912109 
	C370.832428,983.162170 383.500732,982.960754 396.165497,983.006287 
	C485.610382,983.327759 575.055176,983.666687 665.000000,984.000000 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M1072.746704,978.834961 
	C1072.927734,981.894104 1072.691650,984.589966 1073.059570,987.200745 
	C1073.720947,991.894775 1071.101196,993.528259 1067.191040,993.920959 
	C1063.668823,994.274902 1060.112549,994.289368 1057.310059,994.419250 
	C1059.817627,989.178650 1062.269897,984.796387 1064.032837,980.152344 
	C1065.667114,975.847351 1068.909058,974.987183 1072.746704,978.834961 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M981.388306,913.948975 
	C981.002441,908.311951 981.000061,902.623962 981.000000,896.467957 
	C981.845947,896.445374 983.419495,896.887451 983.421509,897.336609 
	C983.445557,902.631592 983.251343,907.928467 983.030151,913.221191 
	C983.020020,913.463867 982.209473,913.673096 981.388306,913.948975 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M276.695465,898.294922 
	C276.725098,896.813416 276.914642,895.627075 277.104187,894.440735 
	C277.592621,894.381592 278.081024,894.322449 278.569458,894.263306 
	C278.885529,895.746033 279.201630,897.228699 279.517700,898.711426 
	C278.630249,898.671021 277.742828,898.630554 276.695465,898.294922 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M1057.766602,927.096985 
	C1057.205933,927.666321 1056.561157,927.925354 1055.916260,928.184326 
	C1055.822021,927.634949 1055.727783,927.085510 1055.633423,926.536133 
	C1056.316406,926.619629 1056.999268,926.703186 1057.766602,927.096985 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M981.259033,892.783936 
	C980.662415,891.913757 980.332336,890.822693 980.006042,889.365845 
	C980.515015,890.187744 981.020264,891.375427 981.259033,892.783936 
z"/>
<path fill="#851317" opacity="1.000000" stroke="none" 
	d="
M276.633118,993.851440 
	C275.723328,993.587158 275.137909,993.245789 274.552429,992.904297 
	C275.052490,992.633301 275.552551,992.362244 276.052612,992.091187 
	C276.354218,992.652283 276.655823,993.213318 276.633118,993.851440 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M934.145569,955.224365 
	C934.568298,954.843140 935.136658,954.688660 935.851685,954.757446 
	C935.429260,955.137512 934.860229,955.294312 934.145569,955.224365 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M609.806152,446.970947 
	C609.797852,447.392242 609.789490,447.813538 609.440063,448.644806 
	C608.045288,450.708038 607.062683,452.412140 605.924683,454.005219 
	C602.200317,459.218628 599.070984,465.078186 594.502380,469.413818 
	C588.177063,475.416565 580.820618,480.354614 573.733215,485.511475 
	C572.526794,486.389282 570.166809,486.722412 568.866882,486.136932 
	C567.581360,485.557953 566.494446,483.594116 566.177917,482.060730 
	C565.828796,480.369812 566.575562,478.481079 566.630249,476.672058 
	C566.725769,473.514160 566.656860,470.351288 566.656860,467.190521 
	C566.143250,467.011322 565.629578,466.832123 565.115967,466.652893 
	C564.099487,468.125183 562.974182,469.534912 562.085022,471.080414 
	C557.775635,478.570221 556.871704,487.855438 549.998718,493.997894 
	C549.998718,493.997894 549.706299,493.909271 549.395996,493.582855 
	C551.274292,486.367798 553.498413,479.490143 555.632629,472.584686 
	C556.393250,470.123749 556.424011,467.896240 552.890564,467.513184 
	C552.843872,466.702423 552.780090,466.240417 552.716370,465.778381 
	C552.716370,465.778381 552.806824,465.360016 553.227783,465.134308 
	C555.454956,462.255859 557.261169,459.603149 559.507324,456.958984 
	C561.444824,456.990479 562.980896,456.803833 564.433594,457.070740 
	C573.086365,458.660278 576.881958,459.284149 574.210999,469.757507 
	C574.169922,469.918518 574.080750,470.104340 574.124390,470.244110 
	C574.585510,471.720062 574.671082,473.912140 575.658752,474.455688 
	C576.639771,474.995544 578.742004,474.204437 579.988586,473.419220 
	C583.336304,471.310394 586.333069,468.621704 589.750244,466.650330 
	C598.710938,461.480927 604.043579,453.678619 607.031738,443.692566 
	C607.070374,442.952332 607.090942,442.529266 607.467590,442.000153 
	C608.198914,440.253082 608.574219,438.612091 608.963257,436.537201 
	C608.970337,434.077484 608.963867,432.051636 608.979004,429.635742 
	C607.031738,425.160034 605.062744,421.074402 603.083984,416.531677 
	C603.076660,412.719055 603.079163,409.363464 603.319580,405.814880 
	C603.367310,404.748291 603.177185,403.874756 602.990906,402.627563 
	C602.988159,401.506592 602.981506,400.759247 603.132202,399.683380 
	C604.501892,392.636658 610.325256,389.845490 614.713684,385.782593 
	C618.630310,382.156433 622.316040,381.192688 626.590698,385.220673 
	C627.666138,386.234070 629.506287,386.435883 631.316589,387.029053 
	C632.067383,387.056213 632.496643,387.065002 633.028442,387.432587 
	C633.945374,388.185486 634.735046,388.853333 635.578247,388.929718 
	C641.417480,389.458435 644.707336,393.081238 646.616394,398.000061 
	C639.408630,389.220734 627.645935,392.877594 620.111389,387.394196 
	C617.466675,388.782959 615.278259,389.890991 613.128601,391.069702 
	C608.920105,393.377350 605.648071,397.014496 606.327881,401.699280 
	C607.660217,410.880127 610.057983,419.906403 612.027100,429.362671 
	C612.176941,430.194641 612.314514,430.660675 612.452087,431.126678 
	C612.349487,432.798950 612.246826,434.471222 611.827759,436.781372 
	C611.419983,438.685577 611.328613,439.951843 611.237183,441.218140 
	C611.168701,442.216553 611.100220,443.214935 610.699951,444.610046 
	C610.180908,445.661469 609.993530,446.316193 609.806152,446.970947 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M632.925903,387.073792 
	C632.496643,387.065002 632.067383,387.056213 631.176758,386.721497 
	C630.395874,386.014923 630.147522,385.518188 629.745667,385.271820 
	C626.292664,383.154755 623.026978,380.459595 619.281006,379.167267 
	C615.275574,377.785492 614.633850,382.471100 612.512268,384.498108 
	C612.159912,384.834686 611.621338,384.986023 611.296509,385.340057 
	C608.098511,388.826263 604.785095,392.225983 601.880859,395.944275 
	C601.361267,396.609497 602.566162,398.621674 602.974792,400.011932 
	C602.981506,400.759247 602.988159,401.506592 602.738403,402.812775 
	C602.681885,404.250397 602.881775,405.129150 603.081665,406.007904 
	C603.079163,409.363464 603.076660,412.719055 602.790100,416.750366 
	C602.105164,422.932373 604.745789,426.881256 608.957397,430.025818 
	C608.963867,432.051636 608.970337,434.077484 608.598389,436.621399 
	C607.850464,438.795074 607.481018,440.450623 607.111572,442.106201 
	C607.090942,442.529266 607.070374,442.952332 606.674683,443.726440 
	C605.274536,445.285370 604.180664,446.442078 603.238403,447.711334 
	C600.997070,450.730316 599.002319,453.955383 596.538452,456.774231 
	C595.616882,457.828552 593.599060,458.609802 592.201782,458.451355 
	C583.561462,457.471649 574.939209,456.288971 566.356567,454.893311 
	C565.127075,454.693390 564.144165,452.977386 563.483398,451.974915 
	C565.245789,452.048492 566.627441,451.890411 567.888611,452.209229 
	C573.928894,453.735931 579.682678,453.780701 584.835999,449.605591 
	C587.616638,447.352783 590.352539,446.718811 593.874146,448.613708 
	C595.419006,449.444977 598.378052,449.085144 599.974792,448.099457 
	C603.960083,445.639343 606.010864,432.097992 602.861145,428.722168 
	C599.632751,425.261902 598.642151,421.438904 598.988403,416.573853 
	C598.991089,414.774597 598.991882,413.390167 599.007690,411.624756 
	C600.420959,407.526215 599.808960,404.667633 595.039734,402.921753 
	C593.651306,405.627319 592.293152,408.273865 590.571228,410.937836 
	C589.720459,410.977722 589.233398,411.000183 588.497070,410.841187 
	C588.010315,410.320557 587.772827,409.981354 587.535278,409.642181 
	C587.694824,409.306946 587.854370,408.971710 588.445190,408.292725 
	C592.551392,402.421783 596.327576,396.958588 599.876465,391.351532 
	C604.567017,383.940613 609.079529,376.417023 614.025024,369.062134 
	C615.285339,370.763031 616.189270,372.343658 617.213867,374.259949 
	C617.647095,374.973267 617.919434,375.635895 618.278320,375.686646 
	C626.062256,376.786652 630.034546,385.476349 638.295837,385.734222 
	C639.229736,385.763367 640.120361,387.178375 640.630859,387.917542 
	C637.795776,387.614166 635.360840,387.343994 632.925903,387.073792 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M673.404846,363.668976 
	C676.315247,368.357422 679.321045,372.989807 682.115112,377.746582 
	C687.122375,386.270996 691.973328,394.887054 696.945618,403.432220 
	C700.243164,409.099335 703.638245,414.709717 706.629883,420.735352 
	C705.842834,421.110687 705.415710,421.096130 704.723206,420.763855 
	C702.002991,420.290314 699.549377,420.031952 697.092957,420.003174 
	C693.571777,419.961853 691.879517,418.250183 692.028931,414.781189 
	C692.113647,412.815552 692.219604,410.825897 692.598083,408.902618 
	C693.602539,403.798401 691.143860,398.788269 686.055420,395.695862 
	C686.048828,395.460571 686.012634,394.991211 686.019775,394.605988 
	C685.297058,388.725769 681.723938,384.839630 677.026123,383.437408 
	C672.771729,382.167572 672.078796,379.432770 673.216797,376.922150 
	C675.297302,372.332397 673.263245,368.314911 673.055054,363.958923 
	C673.168945,363.859070 673.404846,363.668976 673.404846,363.668976 
z"/>
<path fill="#C8C8C8" opacity="1.000000" stroke="none" 
	d="
M598.986633,416.988708 
	C598.642151,421.438904 599.632751,425.261902 602.861145,428.722168 
	C606.010864,432.097992 603.960083,445.639343 599.974792,448.099457 
	C598.378052,449.085144 595.419006,449.444977 593.874146,448.613708 
	C590.352539,446.718811 587.616638,447.352783 584.835999,449.605591 
	C579.682678,453.780701 573.928894,453.735931 567.888611,452.209229 
	C566.627441,451.890411 565.245789,452.048492 563.476990,451.635834 
	C563.005371,450.833099 562.976379,450.381317 563.082031,449.632690 
	C563.399170,448.527069 563.581665,447.718292 563.764160,446.909485 
	C564.255188,445.975098 564.746216,445.040710 565.777710,443.884979 
	C566.810059,443.389709 567.302063,443.115753 567.861816,443.256470 
	C567.152283,451.135498 567.209656,451.640686 574.820068,450.776825 
	C578.190735,450.394196 581.633728,447.928406 584.396301,445.629944 
	C587.532104,443.020935 590.585754,439.883636 592.477905,436.337250 
	C594.171204,433.163544 593.979065,429.040100 595.100891,425.487946 
	C596.031982,422.539886 597.664734,419.813354 598.986633,416.988708 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M562.947327,449.929535 
	C562.976379,450.381317 563.005371,450.833099 563.040771,451.623962 
	C564.144165,452.977386 565.127075,454.693390 566.356567,454.893311 
	C574.939209,456.288971 583.561462,457.471649 592.201782,458.451355 
	C593.599060,458.609802 595.616882,457.828552 596.538452,456.774231 
	C599.002319,453.955383 600.997070,450.730316 603.238403,447.711334 
	C604.180664,446.442078 605.274536,445.285370 606.656616,444.043640 
	C604.043579,453.678619 598.710938,461.480927 589.750244,466.650330 
	C586.333069,468.621704 583.336304,471.310394 579.988586,473.419220 
	C578.742004,474.204437 576.639771,474.995544 575.658752,474.455688 
	C574.671082,473.912140 574.585510,471.720062 574.124390,470.244110 
	C574.080750,470.104340 574.169922,469.918518 574.210999,469.757507 
	C576.881958,459.284149 573.086365,458.660278 564.433594,457.070740 
	C562.980896,456.803833 561.444824,456.990479 559.429932,456.661255 
	C558.808594,456.177887 558.704590,456.000885 558.600647,455.823853 
	C559.318054,454.063660 560.035461,452.303467 561.168396,450.234619 
	C562.038391,449.927155 562.492859,449.928345 562.947327,449.929535 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M552.873535,467.861908 
	C556.424011,467.896240 556.393250,470.123749 555.632629,472.584686 
	C553.498413,479.490143 551.274292,486.367798 549.283630,493.479370 
	C549.600281,494.046173 549.527893,494.343323 548.707947,494.710907 
	C547.429749,494.881531 546.708008,494.935059 545.642700,495.007385 
	C543.809387,496.574219 542.319641,498.122284 540.556885,499.703064 
	C539.918152,499.721558 539.552368,499.707275 539.143311,499.288300 
	C539.099243,496.641785 539.098572,494.400024 539.304810,491.910583 
	C539.560974,491.049683 539.610229,490.436462 539.659424,489.823242 
	C540.036133,488.774109 540.412842,487.724976 541.258667,486.314941 
	C542.688538,484.236633 543.649170,482.519226 544.609802,480.801819 
	C545.274414,479.080475 545.939026,477.359100 546.970215,475.198730 
	C549.182434,472.460449 551.028015,470.161194 552.873535,467.861908 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M622.652649,355.582794 
	C629.823181,345.454926 649.130249,342.436371 657.495056,349.828156 
	C662.618225,354.355347 667.977356,358.615509 673.317688,363.332275 
	C673.404846,363.668976 673.168945,363.859070 672.673157,363.930725 
	C666.765564,364.026215 663.730835,359.935699 659.976746,356.669495 
	C659.268372,355.904572 658.619324,355.457764 657.926331,354.664551 
	C656.259949,353.158905 654.637512,351.999664 652.833496,350.524414 
	C648.207275,346.680206 638.227844,347.015564 634.371582,350.813721 
	C631.004761,352.587036 628.022949,354.356384 624.740845,356.145477 
	C624.041504,356.196625 623.642456,356.227997 623.093933,356.091827 
	C622.944397,355.924286 622.652649,355.582794 622.652649,355.582794 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M613.668701,368.941895 
	C609.079529,376.417023 604.567017,383.940613 599.876465,391.351532 
	C596.327576,396.958588 592.551392,402.421783 588.560730,408.039307 
	C590.145752,403.770905 591.660706,399.189789 594.020752,395.095856 
	C600.633972,383.623871 607.416626,372.237946 614.563599,361.094635 
	C616.092102,358.711395 619.441223,357.495911 622.302124,355.662628 
	C622.652649,355.582794 622.944397,355.924286 622.962280,356.380920 
	C621.628296,357.830261 620.276489,358.822968 618.618164,359.985596 
	C616.763977,363.084290 615.216309,366.013092 613.668701,368.941895 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M686.012634,394.991211 
	C686.012634,394.991211 686.048828,395.460571 686.063171,396.065247 
	C689.678711,402.381622 691.603210,408.406097 690.078918,415.274933 
	C689.785828,416.595398 690.327087,418.253937 690.912659,419.564819 
	C691.331970,420.503571 692.470642,421.777069 693.282654,421.772888 
	C697.185730,421.752747 701.086731,421.349091 704.988525,421.081604 
	C705.415710,421.096130 705.842834,421.110687 706.588501,421.098450 
	C707.167053,421.274780 707.427246,421.477905 707.779297,422.221924 
	C707.907776,423.182373 707.944275,423.601929 707.964111,424.286865 
	C707.921448,424.727692 707.895447,424.903168 707.411072,425.062073 
	C704.130432,425.025909 701.308105,424.989868 698.485840,424.989746 
	C689.869019,424.989441 686.606812,420.881653 687.844910,412.281403 
	C688.210632,409.740906 687.684326,406.892700 686.885803,404.404053 
	C685.533203,400.188843 683.638855,396.147491 682.273560,392.165619 
	C683.720764,393.195862 684.866699,394.093536 686.012634,394.991211 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M707.980713,424.021484 
	C707.944275,423.601929 707.907776,423.182373 707.886353,422.448669 
	C708.764526,422.500092 710.028809,422.617859 710.430481,423.268341 
	C716.504700,433.104065 722.477417,443.002716 728.426941,452.914734 
	C728.832581,453.590576 728.865723,454.489990 728.674805,455.620911 
	C726.926514,454.578278 725.575623,453.200165 724.178223,451.607422 
	C724.131714,451.392853 724.044922,450.962372 724.025635,450.606201 
	C722.370422,448.132812 720.734436,446.015594 718.981812,443.689575 
	C718.646545,443.237549 718.382202,443.064026 718.043396,442.603333 
	C715.346436,437.827850 712.678101,433.409210 710.024170,428.620697 
	C709.352600,426.841034 708.666687,425.431274 707.980713,424.021484 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M567.794006,442.841797 
	C567.302063,443.115753 566.810059,443.389709 566.039001,443.697540 
	C566.388306,442.085724 566.795105,440.308258 567.682312,438.816467 
	C572.209656,431.204285 576.768921,423.607239 581.523804,416.136536 
	C582.990295,413.832458 585.108521,411.943237 587.231812,409.753113 
	C587.772827,409.981354 588.010315,410.320557 588.412109,411.153259 
	C585.685669,415.715607 582.795044,419.784424 579.588623,424.018555 
	C578.493164,426.104065 577.713440,428.024261 576.939026,429.970947 
	C576.944336,429.997467 576.998352,429.995911 576.763611,430.072083 
	C576.190186,430.331848 576.063782,430.611450 576.075317,431.298157 
	C575.962402,432.029968 575.923950,432.450592 575.941956,432.934753 
	C575.998413,432.998291 575.871521,432.884521 575.541504,432.894592 
	C574.452209,433.929565 573.692871,434.954437 572.633667,436.107147 
	C571.534119,437.455109 570.734436,438.675232 569.639160,440.030548 
	C568.846313,441.063934 568.349121,441.962128 567.836609,442.867493 
	C567.821289,442.874664 567.794006,442.841766 567.794006,442.841797 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M729.304565,457.756561 
	C733.870911,465.610657 738.437256,473.464752 742.629272,481.637146 
	C739.914978,478.888458 737.575073,475.821472 735.162231,472.434113 
	C734.742432,471.067627 734.395752,470.021484 734.015259,468.631897 
	C732.401917,466.444122 730.822266,464.599762 729.214111,462.554443 
	C729.185486,462.353455 729.131409,461.951080 729.128540,461.529785 
	C729.185303,459.991180 729.244934,458.873871 729.304565,457.756561 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M735.235229,472.754456 
	C737.575073,475.821472 739.914978,478.888458 742.591125,482.001587 
	C742.927368,482.047699 743.062622,482.449554 742.951782,482.961487 
	C742.762329,483.683746 742.683838,483.894012 742.257568,484.066406 
	C734.812927,483.154938 735.913452,477.428131 735.235229,472.754456 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M745.302307,484.825165 
	C747.935242,489.534363 750.568176,494.243530 753.175415,499.391571 
	C753.149658,499.830414 753.008606,499.993408 752.749695,499.887512 
	C752.019775,499.482422 751.548767,499.183228 750.928223,498.574219 
	C748.839233,494.191406 746.899841,490.118378 745.024780,485.734406 
	C745.160156,485.223999 745.231201,485.024597 745.302307,484.825165 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M539.186646,499.693054 
	C539.552368,499.707275 539.918152,499.721558 540.874084,499.828125 
	C541.680115,499.986816 541.895935,500.053223 542.111755,500.119629 
	C539.246765,500.919067 536.381714,501.718506 533.270386,502.245544 
	C533.426270,500.676971 533.828491,499.380768 534.688477,498.371704 
	C536.492981,499.003571 537.839783,499.348297 539.186646,499.693054 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M729.257690,457.466949 
	C729.244934,458.873871 729.185303,459.991180 728.846313,461.448486 
	C728.050720,461.521393 727.534363,461.254333 726.870911,460.649353 
	C725.890747,457.481628 725.057678,454.651825 724.224670,451.822021 
	C725.575623,453.200165 726.926514,454.578278 728.608521,455.995453 
	C729.029968,456.415466 729.120422,456.796387 729.257690,457.466949 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M552.890564,467.513184 
	C551.028015,470.161194 549.182434,472.460449 546.989990,474.859375 
	C548.465698,471.976318 550.288330,468.993561 552.413696,465.894592 
	C552.780090,466.240417 552.843872,466.702423 552.890564,467.513184 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M539.143311,499.288330 
	C537.839783,499.348297 536.492981,499.003571 535.039307,498.295288 
	C534.995667,497.535431 535.058777,497.139130 535.480286,496.368530 
	C536.236206,495.337036 536.633789,494.679871 537.031433,494.022705 
	C537.317566,493.541870 537.603699,493.061035 538.220703,492.327728 
	C538.733643,492.102905 538.915771,492.130554 539.097900,492.158234 
	C539.098572,494.400024 539.099243,496.641785 539.143311,499.288330 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M558.295410,455.937134 
	C558.704590,456.000885 558.808594,456.177887 558.989990,456.652679 
	C557.261169,459.603149 555.454956,462.255859 553.294495,464.930969 
	C554.623596,461.985687 556.306885,459.018066 558.295410,455.937134 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M544.310669,480.912781 
	C543.649170,482.519226 542.688538,484.236633 541.382263,486.003693 
	C542.028320,484.376801 543.019958,482.700256 544.310669,480.912781 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M563.082031,449.632690 
	C562.492859,449.928345 562.038391,449.927155 561.243896,449.887421 
	C561.641296,448.895447 562.378784,447.941986 563.440186,446.949005 
	C563.581665,447.718292 563.399170,448.527069 563.082031,449.632690 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M745.182373,484.530334 
	C745.231201,485.024597 745.160156,485.223999 744.701355,485.721436 
	C743.881958,486.002594 743.450378,485.985748 742.979858,485.651184 
	C742.829041,484.923767 742.717163,484.514038 742.605286,484.104309 
	C742.683838,483.894012 742.762329,483.683746 742.989136,483.170166 
	C743.779114,483.323090 744.420715,483.779297 745.182373,484.530334 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M753.217529,499.746704 
	C753.884094,500.498169 754.482849,501.333374 754.805420,502.565857 
	C754.329895,502.994415 754.130554,503.025726 753.828125,502.841980 
	C753.547729,502.340729 753.294739,502.160950 752.993164,501.739227 
	C753.016296,500.925079 753.012451,500.459259 753.008606,499.993408 
	C753.008606,499.993408 753.149658,499.830414 753.217529,499.746704 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M539.304871,491.910583 
	C538.915771,492.130554 538.733643,492.102905 538.276733,492.045532 
	C538.365784,491.347778 538.729614,490.679749 539.376465,489.917480 
	C539.610229,490.436462 539.560974,491.049683 539.304871,491.910583 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M536.697876,494.051819 
	C536.633789,494.679871 536.236206,495.337036 535.542358,496.073395 
	C535.618835,495.462036 535.991638,494.771454 536.697876,494.051819 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M753.931274,503.057037 
	C754.130554,503.025726 754.329895,502.994415 754.826721,502.902527 
	C755.414062,503.354767 755.703857,503.867584 756.025391,504.685486 
	C756.057129,504.990601 755.793762,505.026306 755.387451,505.048706 
	C754.605347,505.062775 754.229492,505.054443 753.877319,504.713806 
	C753.911133,503.940033 753.921204,503.498535 753.931274,503.057037 
z"/>
<path fill="#871217" opacity="1.000000" stroke="none" 
	d="
M839.294067,1008.296875 
	C841.848389,1007.333313 844.694214,1006.100281 847.545715,1006.087158 
	C877.937500,1005.946960 908.330933,1005.902588 938.722046,1006.103638 
	C943.359253,1006.134277 947.987366,1007.546326 952.809814,1008.659790 
	C951.272766,1009.333374 949.546936,1009.947205 947.818054,1009.955994 
	C932.828735,1010.032288 917.837585,1010.125549 902.849426,1009.969360 
	C882.405090,1009.756287 861.962891,1009.332031 841.520142,1008.977539 
	C840.874146,1008.966370 840.231995,1008.726868 839.294067,1008.296875 
z"/>
<path fill="#851314" opacity="1.000000" stroke="none" 
	d="
M1080.005493,956.383667 
	C1081.768188,956.310181 1083.536377,956.619385 1085.652344,956.964294 
	C1086.725098,958.586121 1087.275635,960.284058 1088.207153,961.738037 
	C1091.411865,966.740784 1094.832275,971.607239 1097.953491,976.659790 
	C1098.456787,977.474548 1098.182129,979.825623 1097.704834,980.009888 
	C1095.491699,980.864258 1092.054321,982.434875 1090.932861,981.499146 
	C1087.578003,978.699524 1085.085938,974.859741 1082.307373,971.385803 
	C1082.036987,971.047791 1081.895752,970.332520 1082.064209,969.959229 
	C1084.259644,965.096558 1081.194092,961.080872 1080.005493,956.383667 
z"/>
<path fill="#871217" opacity="1.000000" stroke="none" 
	d="
M1026.995850,857.633667 
	C1024.553467,857.647705 1021.897827,857.754700 1019.738098,856.772034 
	C1018.428955,856.176331 1017.193420,853.919617 1017.136108,852.364441 
	C1016.987854,848.339111 1016.353333,845.555420 1011.311096,845.783813 
	C1010.322021,845.828552 1008.115662,843.077698 1008.362061,842.086243 
	C1009.349670,838.112793 1009.449707,834.695679 1006.440125,831.456909 
	C1005.526733,830.473877 1005.204651,828.696472 1005.129272,827.255920 
	C1005.066895,826.062378 1005.538147,824.668152 1006.231567,823.686890 
	C1006.539124,823.251648 1008.599731,823.306091 1008.783142,823.675354 
	C1009.295715,824.707397 1009.283264,826.015625 1009.407776,827.222107 
	C1009.790466,830.932922 1009.530762,834.949707 1014.481262,836.122009 
	C1015.326416,836.322144 1016.199646,837.077271 1016.730896,837.813477 
	C1019.153564,841.170959 1021.458740,844.613220 1024.105225,848.459534 
	C1020.398010,851.972290 1027.187500,853.764526 1026.995850,857.633667 
z"/>
<path fill="#871217" opacity="1.000000" stroke="none" 
	d="
M999.681641,842.711304 
	C1001.868713,843.174744 1005.102844,842.927979 1003.566650,845.768738 
	C1002.650024,847.463867 999.244507,847.813049 996.957764,848.767212 
	C996.348511,846.903015 995.030640,844.932922 995.289978,843.198669 
	C995.720215,840.320862 997.711548,841.331543 999.681641,842.711304 
z"/>
<path fill="#871217" opacity="1.000000" stroke="none" 
	d="
M977.518433,860.332642 
	C975.969299,859.730530 974.655640,858.849731 973.341980,857.968933 
	C974.008606,857.361450 974.675232,856.753967 975.341858,856.146484 
	C976.145874,857.448975 976.949890,858.751465 977.518433,860.332642 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M544.360596,502.990265 
	C545.084717,502.316376 546.185303,501.646606 547.633667,500.969727 
	C547.725403,501.465485 547.469360,501.968414 547.103760,502.731995 
	C546.241943,502.993256 545.489502,502.993835 544.360596,502.990265 
z"/>
<path fill="#1C1C1D" opacity="1.000000" stroke="none" 
	d="
M549.242554,500.908691 
	C549.390381,500.299103 549.870789,499.679382 550.668457,499.019012 
	C550.515503,499.618439 550.045288,500.258484 549.242554,500.908691 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M672.631714,579.365479 
	C668.349182,577.718384 666.071960,574.821716 667.208618,570.744019 
	C668.290955,566.861572 672.079895,566.539368 675.428040,567.079651 
	C679.081848,567.669250 682.842285,568.448425 686.134277,570.025696 
	C687.518616,570.689026 688.921265,574.875122 688.433533,575.300171 
	C685.517395,577.841797 682.232971,580.191528 678.680664,581.657043 
	C677.294678,582.228821 674.891785,580.335815 672.631714,579.365479 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M652.061646,443.989441 
	C651.702454,441.780426 651.099365,439.578796 651.031860,437.360901 
	C650.859924,431.716949 652.215454,425.658539 650.589294,420.515381 
	C648.304260,413.287842 644.384644,406.404541 640.193115,400.010437 
	C637.197571,395.440674 632.865662,396.190857 625.580627,400.357666 
	C623.160034,401.742126 620.227844,402.633606 617.450867,402.886353 
	C614.341675,403.169373 612.778442,404.118805 613.006653,407.355743 
	C613.354736,412.295227 613.661926,417.237549 613.598694,422.536804 
	C612.473511,420.965729 611.423340,419.092773 611.063660,417.095551 
	C610.308777,412.902740 610.763611,408.275696 609.127930,404.521332 
	C607.618774,401.057404 608.183472,398.773743 610.224121,396.472137 
	C612.599182,393.793335 615.283447,392.113495 619.384338,393.569000 
	C621.979980,394.490234 625.170288,394.602661 627.909180,394.090393 
	C635.992432,392.578644 641.453491,394.281006 646.333618,403.470917 
	C646.878540,404.496979 648.094910,405.166412 649.019348,406.231201 
	C649.038818,406.461151 649.090759,406.919830 649.059631,407.304077 
	C652.820312,416.389160 656.467896,425.078308 653.053833,434.895142 
	C652.542114,436.366577 653.103088,438.180969 652.948486,439.815979 
	C652.815552,441.222504 652.369385,442.599426 652.061646,443.989441 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M550.365234,494.008179 
	C556.871704,487.855438 557.775635,478.570221 562.085022,471.080414 
	C562.974182,469.534912 564.099487,468.125183 565.115967,466.652893 
	C565.629578,466.832123 566.143250,467.011322 566.656860,467.190521 
	C566.656860,470.351288 566.725769,473.514160 566.630249,476.672058 
	C566.575562,478.481079 565.828796,480.369812 566.177917,482.060730 
	C566.494446,483.594116 567.581360,485.557953 568.866882,486.136932 
	C570.166809,486.722412 572.526794,486.389282 573.733215,485.511475 
	C580.820618,480.354614 588.177063,475.416565 594.502380,469.413818 
	C599.070984,465.078186 602.200317,459.218628 605.924683,454.005219 
	C607.062683,452.412140 608.045288,450.708038 609.417603,448.960022 
	C605.896240,462.008850 598.457642,472.436798 586.681213,479.660980 
	C583.600098,481.551056 580.383362,483.391144 577.769653,485.832947 
	C573.682251,489.651459 569.013123,490.264984 564.115906,488.869812 
	C559.725281,487.618988 556.856995,489.165833 554.122498,492.265533 
	C553.337952,493.154877 551.880554,493.450531 550.365234,494.008179 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M648.999878,406.001221 
	C648.094910,405.166412 646.878540,404.496979 646.333618,403.470917 
	C641.453491,394.281006 635.992432,392.578644 627.909180,394.090393 
	C625.170288,394.602661 621.979980,394.490234 619.384338,393.569000 
	C615.283447,392.113495 612.599182,393.793335 610.224121,396.472137 
	C608.183472,398.773743 607.618774,401.057404 609.127930,404.521332 
	C610.763611,408.275696 610.308777,412.902740 611.063660,417.095551 
	C611.423340,419.092773 612.473511,420.965729 613.571045,422.980133 
	C613.932983,423.493713 613.934692,423.922150 613.680420,424.888672 
	C612.954590,426.616791 612.484680,427.806732 612.014771,428.996674 
	C610.057983,419.906403 607.660217,410.880127 606.327881,401.699280 
	C605.648071,397.014496 608.920105,393.377350 613.128601,391.069702 
	C615.278259,389.890991 617.466675,388.782959 620.111389,387.394196 
	C627.645935,392.877594 639.408630,389.220734 646.645264,398.223999 
	C647.020996,398.485931 647.098450,398.931458 647.100220,399.300751 
	C647.734619,401.780426 648.367249,403.890808 648.999878,406.001221 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M652.056274,444.312744 
	C652.369385,442.599426 652.815552,441.222504 652.948486,439.815979 
	C653.103088,438.180969 652.542114,436.366577 653.053833,434.895142 
	C656.467896,425.078308 652.820312,416.389160 649.403870,407.371094 
	C651.407776,409.838348 653.493713,412.461517 654.547302,415.448761 
	C655.821716,419.062347 656.933411,423.001068 656.843079,426.768555 
	C656.688110,433.227966 655.545593,439.663727 654.817688,446.109406 
	C654.130859,446.062042 653.444031,446.014679 652.415527,445.946594 
	C652.066162,445.495941 652.058533,445.065979 652.056274,444.312744 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M542.430176,500.056824 
	C541.895935,500.053223 541.680115,499.986816 541.147095,499.795380 
	C542.319641,498.122284 543.809387,496.574219 545.617737,495.339600 
	C545.573425,496.677551 545.210388,497.702087 544.847351,498.726624 
	C544.147766,499.149078 543.448181,499.571533 542.430176,500.056824 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M612.027100,429.362671 
	C612.484680,427.806732 612.954590,426.616791 613.676147,425.210571 
	C615.212158,427.211945 616.321167,429.406647 612.794678,431.083374 
	C612.314514,430.660675 612.176941,430.194641 612.027100,429.362671 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M545.202332,498.779358 
	C545.210388,497.702087 545.573425,496.677551 545.961304,495.320801 
	C546.708008,494.935059 547.429749,494.881531 548.510132,494.846130 
	C547.764954,496.186829 546.661133,497.509491 545.202332,498.779358 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M611.555908,441.108948 
	C611.328613,439.951843 611.419983,438.685577 611.831665,437.203522 
	C612.059509,438.325104 611.967102,439.662415 611.555908,441.108948 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M610.107727,446.850342 
	C609.993530,446.316193 610.180908,445.661469 610.653320,444.982605 
	C610.761963,445.548889 610.585632,446.139313 610.107727,446.850342 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M753.853638,505.046112 
	C754.229492,505.054443 754.605347,505.062775 755.258179,505.079437 
	C755.624146,505.820251 755.713013,506.552673 755.547485,507.962250 
	C754.398071,514.277344 753.583313,519.929626 752.581360,525.548462 
	C751.836975,529.723572 750.475769,533.817017 750.045776,538.014099 
	C749.039856,547.831604 747.276855,549.073181 739.166687,544.890625 
	C739.140686,544.165527 739.114685,543.440491 739.341064,542.129761 
	C738.098877,538.337952 736.604370,535.131775 735.109863,531.925537 
	C734.095703,530.212952 733.081543,528.500427 731.740601,526.695129 
	C731.357666,526.247803 731.301514,525.893311 731.447998,525.107666 
	C731.756897,524.128357 731.863098,523.580261 731.963379,522.687134 
	C731.036011,521.172119 730.114685,520.002075 729.148682,518.471741 
	C728.399658,517.076477 727.695374,516.041626 726.991028,515.006714 
	C726.991028,515.006714 726.870789,515.096985 726.903931,514.771973 
	C726.957214,513.965454 726.977417,513.483948 726.981445,512.648132 
	C724.848450,508.312134 722.926331,504.209534 720.552429,500.387512 
	C718.705139,497.413116 716.295166,494.788147 714.057190,491.655457 
	C712.995483,489.193024 712.011963,487.083008 711.038940,484.581726 
	C710.301392,475.840240 704.396240,471.614197 697.886841,467.993408 
	C696.106567,467.003113 693.971985,466.649902 691.768372,465.974976 
	C691.536743,465.945587 691.072388,465.896027 691.019104,465.556488 
	C688.971985,463.816132 686.978210,462.415314 684.948853,460.793213 
	C684.913269,460.571899 684.858826,460.126923 684.923218,459.834656 
	C685.018127,459.346344 685.048645,459.150299 685.434082,458.995270 
	C690.533875,461.014313 695.278931,462.992310 700.359009,464.980591 
	C701.459778,465.330383 702.225464,465.669891 703.041504,466.351288 
	C706.471802,469.735107 710.483215,472.328125 713.054688,475.944824 
	C715.641479,479.583130 718.081238,481.488770 722.676575,481.099304 
	C727.149109,480.720215 729.242004,484.225586 730.944946,487.729950 
	C732.356445,490.634369 733.560974,493.640442 735.003723,496.528259 
	C737.000854,500.525513 737.990845,504.416321 735.875916,508.810364 
	C734.738342,511.173981 733.428833,514.197571 733.984985,516.489441 
	C735.447327,522.515747 737.825989,528.327454 739.965027,534.178833 
	C741.213867,537.595032 744.078125,538.012024 746.226440,535.029419 
	C747.146179,533.752502 747.591675,532.053284 747.966187,530.477783 
	C749.978455,522.012390 751.900513,513.525574 753.853638,505.046112 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M739.105896,545.311523 
	C747.276855,549.073181 749.039856,547.831604 750.045776,538.014099 
	C750.475769,533.817017 751.836975,529.723572 752.581360,525.548462 
	C753.583313,519.929626 754.398071,514.277344 755.548035,508.332825 
	C759.170349,511.221527 756.927551,514.792847 756.412964,518.199219 
	C755.355774,525.196777 753.735352,532.140381 753.195374,539.171387 
	C752.933350,542.581299 754.358398,546.210693 755.440979,549.606445 
	C756.354309,552.471008 755.323181,553.677490 752.731018,554.218262 
	C742.294067,556.395752 739.250427,555.108398 739.105896,545.311523 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M735.070068,532.332275 
	C736.604370,535.131775 738.098877,538.337952 739.357300,541.767090 
	C737.757568,538.906311 736.393982,535.822632 735.070068,532.332275 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M691.072388,465.896027 
	C691.072388,465.896027 691.536743,465.945587 691.904175,466.285675 
	C694.217896,468.096558 696.054321,469.750214 698.133728,470.999359 
	C702.239807,473.466034 706.296204,475.753296 707.656433,480.971832 
	C708.053467,482.495056 709.865173,483.649506 711.028442,484.972992 
	C712.011963,487.083008 712.995483,489.193024 713.802368,491.751831 
	C713.398865,492.478271 713.172058,492.755829 712.945251,493.033386 
	C711.522156,491.146729 709.557007,489.461212 708.769409,487.338715 
	C705.270874,477.910309 698.418213,472.505371 689.235352,469.046082 
	C685.185181,467.520355 681.583313,464.602692 678.061279,461.941895 
	C675.341187,459.886932 673.192749,460.022552 670.526001,461.930908 
	C665.921692,465.225800 660.981750,466.335083 655.414551,463.011261 
	C654.999634,462.542694 654.962646,462.124329 654.935608,461.042206 
	C654.592896,459.589325 654.240234,458.800201 653.887695,458.011078 
	C653.885559,456.942688 653.883484,455.874329 653.819702,454.409241 
	C653.957764,454.003479 654.157471,453.994446 654.938049,454.197815 
	C655.741577,455.029083 655.912109,455.672913 656.195068,456.262878 
	C658.969116,462.047791 662.273132,462.806885 667.354370,459.187042 
	C669.738403,457.488678 672.489075,456.305023 675.427856,454.919739 
	C676.848633,455.306763 677.915161,455.662109 679.009155,456.379211 
	C680.977356,457.869598 682.918091,458.998260 684.858826,460.126923 
	C684.858826,460.126923 684.913269,460.571899 684.973328,461.146362 
	C687.046387,463.112549 689.059387,464.504272 691.072388,465.896027 
z"/>
<path fill="#4A4A4A" opacity="1.000000" stroke="none" 
	d="
M653.652466,458.217224 
	C654.240234,458.800201 654.592896,459.589325 654.903809,460.728516 
	C654.380493,460.193481 653.898865,459.308411 653.652466,458.217224 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M713.012939,493.391907 
	C713.172058,492.755829 713.398865,492.478271 713.880493,492.104279 
	C716.295166,494.788147 718.705139,497.413116 720.552429,500.387512 
	C722.926331,504.209534 724.848450,508.312134 726.638977,512.626160 
	C725.235718,512.616577 724.158752,512.274658 723.081787,511.932739 
	C723.081787,511.932739 723.037720,511.989288 723.048096,511.614990 
	C722.398682,510.469116 721.738953,509.697571 721.079224,508.925995 
	C718.412964,503.867493 715.746765,498.808990 713.012939,493.391907 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M729.193298,518.832092 
	C730.114685,520.002075 731.036011,521.172119 731.601929,522.681030 
	C730.524780,522.985901 729.803040,522.951782 729.081360,522.917725 
	C729.046753,522.505615 729.012146,522.093506 728.978882,521.029053 
	C728.987671,519.916992 728.995056,519.457275 729.049316,518.955200 
	C729.096191,518.912781 729.193298,518.832092 729.193298,518.832092 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M723.080261,512.290344 
	C724.158752,512.274658 725.235718,512.616577 726.655151,512.980469 
	C726.977417,513.483948 726.957214,513.965454 726.832886,514.796692 
	C725.512085,514.313599 724.295410,513.480774 723.080261,512.290344 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M726.950928,515.383789 
	C727.695374,516.041626 728.399658,517.076477 729.148682,518.471741 
	C729.193298,518.832092 729.096191,518.912781 728.789307,518.843750 
	C727.976257,518.528076 727.469971,518.281555 726.963745,518.035034 
	C726.946106,517.276978 726.928467,516.518860 726.950928,515.383789 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M729.090332,523.265625 
	C729.803040,522.951782 730.524780,522.985901 731.607910,523.026001 
	C731.863098,523.580261 731.756897,524.128357 731.419617,524.837158 
	C730.492126,524.536377 729.795776,524.075012 729.090332,523.265625 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M726.964478,518.383789 
	C727.469971,518.281555 727.976257,518.528076 728.742432,518.886108 
	C728.995056,519.457275 728.987671,519.916992 728.944946,520.720703 
	C728.261414,520.287415 727.613342,519.510010 726.964478,518.383789 
z"/>
<path fill="#676767" opacity="1.000000" stroke="none" 
	d="
M721.068970,509.257782 
	C721.738953,509.697571 722.398682,510.469116 723.064209,511.588013 
	C722.399536,511.153412 721.729126,510.371490 721.068970,509.257782 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M675.073669,454.888123 
	C672.489075,456.305023 669.738403,457.488678 667.354370,459.187042 
	C662.273132,462.806885 658.969116,462.047791 656.195068,456.262878 
	C655.912109,455.672913 655.741577,455.029083 655.237915,454.201050 
	C654.935059,451.652313 654.913147,449.312744 654.854431,446.541321 
	C655.545593,439.663727 656.688110,433.227966 656.843079,426.768555 
	C656.933411,423.001068 655.821716,419.062347 654.547302,415.448761 
	C653.493713,412.461517 651.407776,409.838348 649.434998,406.986847 
	C649.090759,406.919830 649.038818,406.461151 649.019348,406.231201 
	C648.367249,403.890808 647.734619,401.780426 647.431763,399.345154 
	C649.154419,399.695221 650.547180,400.370178 651.967163,401.421265 
	C652.332764,402.874481 652.536865,404.018341 653.033264,405.016907 
	C655.022644,409.018799 657.102661,412.975677 659.128662,417.321350 
	C659.444153,419.132782 659.780090,420.572052 659.874146,422.163879 
	C660.084290,422.882446 660.536194,423.448425 660.982422,424.340942 
	C660.976807,425.102539 660.976990,425.537659 660.617676,426.054962 
	C655.741516,433.314941 656.841675,441.362549 657.256409,449.104279 
	C657.428284,452.312805 659.924683,455.396790 661.363953,458.537415 
	C663.618652,457.468140 665.845947,456.335327 668.135864,455.347656 
	C670.368591,454.384674 672.545105,451.678040 675.073669,454.888123 
z"/>
<path fill="#6D1D20" opacity="1.000000" stroke="none" 
	d="
M486.912842,744.055054 
	C487.107697,741.348755 486.846466,738.377686 488.179688,736.607483 
	C497.541443,724.177246 507.330322,712.069885 516.791016,699.712646 
	C527.232239,686.074707 537.469421,672.280640 547.793152,658.552795 
	C548.488892,657.627686 548.969177,656.310181 549.890625,655.831665 
	C551.999817,654.736206 554.384827,653.327759 556.525574,653.519897 
	C557.487305,653.606384 558.798767,657.216736 558.817139,659.238403 
	C558.990173,678.215332 558.830383,697.195190 558.961548,716.172791 
	C559.009583,723.128662 559.683960,730.078003 559.877319,737.035767 
	C560.036987,742.782104 556.910095,745.988403 551.123413,745.888428 
	C540.005493,745.696411 528.892273,745.094238 517.775208,745.040039 
	C510.321686,745.003662 502.866455,745.788452 495.408997,745.851501 
	C492.675354,745.874634 489.933075,744.877625 486.912842,744.055054 
M524.101868,695.687500 
	C523.350403,696.973755 522.732117,698.363281 521.826904,699.530273 
	C512.400940,711.681396 502.877258,723.757324 493.539734,735.975586 
	C492.459900,737.388489 492.343262,739.537598 491.878174,741.030396 
	C512.273132,741.030396 531.675720,741.105530 551.074463,740.865295 
	C552.398376,740.848938 554.540710,738.638611 554.838135,737.155090 
	C555.645508,733.127563 555.933472,728.943359 555.952148,724.819092 
	C556.046936,703.858826 556.013794,682.897766 555.942139,661.937317 
	C555.936707,660.354797 555.281067,658.774536 554.886230,657.007935 
	C544.423340,670.097839 534.445435,682.580933 524.101868,695.687500 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M552.936646,782.648315 
	C550.202698,782.669800 547.421204,782.052856 544.638123,782.045898 
	C516.659912,781.975769 488.681396,781.976318 460.703217,782.051025 
	C457.750458,782.058899 454.799255,782.668091 451.424011,783.004395 
	C453.040100,782.008240 455.039185,780.253540 457.126282,780.141663 
	C464.011169,779.772705 470.927917,779.995850 477.832428,779.995483 
	C498.612213,779.994385 519.392212,779.952454 540.171692,780.033936 
	C543.987061,780.048889 547.805115,780.522034 551.608704,780.913208 
	C552.077637,780.961426 552.463867,781.813416 552.936646,782.648315 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M626.699951,617.283813 
	C627.329163,618.550232 627.945435,620.110779 627.949585,621.672974 
	C628.008423,643.815430 627.984253,665.958130 627.950134,688.573669 
	C627.282410,687.898010 626.092896,686.751648 626.088318,685.600586 
	C625.999817,663.237305 626.048340,640.873474 626.084839,618.509766 
	C626.085327,618.199219 626.292053,617.889099 626.699951,617.283813 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M499.295227,670.946350 
	C502.184723,666.254211 505.363770,661.501648 508.691772,656.855835 
	C510.799164,653.913940 513.128540,651.131165 515.654419,648.112244 
	C515.485535,652.533325 514.597229,656.485962 510.226227,659.615112 
	C506.109528,662.562195 503.128510,667.095581 499.295227,670.946350 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M631.065552,836.330750 
	C633.552917,838.692078 636.062744,841.437866 638.572571,844.183655 
	C638.300110,844.770020 638.027588,845.356323 637.755066,845.942688 
	C634.123901,845.942688 630.492676,845.942688 626.395508,845.892944 
	C628.115051,844.902832 630.300659,843.962341 632.971375,842.813171 
	C632.402710,840.972046 631.745300,838.843628 631.065552,836.330750 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M615.056030,600.401855 
	C616.119568,600.010498 617.287842,600.109009 618.422913,599.958069 
	C627.447754,598.757385 628.759033,602.510254 627.000244,611.625854 
	C626.439026,611.552368 625.440369,611.081360 625.435669,610.600769 
	C625.364929,603.305115 624.848389,602.732788 617.610291,601.942505 
	C616.756348,601.849304 615.965515,601.178955 615.056030,600.401855 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M545.345337,609.967896 
	C548.110596,604.387085 551.015320,598.480408 559.602417,600.016479 
	C559.947266,600.352234 559.953186,600.820435 559.744080,600.955444 
	C555.073914,603.970215 550.380066,606.948303 545.345337,609.967896 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M627.579590,814.074463 
	C628.613037,820.067200 629.254578,826.157471 629.924927,832.638062 
	C628.923706,831.686829 627.085266,830.389099 627.009766,828.995911 
	C626.743042,824.072266 627.079712,819.115906 627.579590,814.074463 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M440.644806,798.030518 
	C439.189392,801.534180 437.380829,805.078064 435.300598,808.823975 
	C434.238251,804.151672 435.712311,800.365356 440.644806,798.030518 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M554.434265,799.942810 
	C554.032776,796.659729 554.016052,793.272583 554.021851,789.423218 
	C557.642029,792.349060 556.233582,796.093689 554.434265,799.942810 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M573.263550,600.294189 
	C575.812439,599.816467 578.637085,599.625122 581.738159,599.719971 
	C579.189514,600.197632 576.364380,600.389160 573.263550,600.294189 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M550.318481,829.915894 
	C550.802002,827.774841 551.635132,825.598328 552.739685,823.208252 
	C552.230164,825.289917 551.449158,827.585144 550.318481,829.915894 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M517.281067,646.934204 
	C517.718384,645.978333 518.505981,645.010437 519.627625,643.994080 
	C519.184875,644.937805 518.408081,645.930054 517.281067,646.934204 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M548.331421,831.972717 
	C548.445129,831.369385 548.879578,830.716309 549.632507,830.016602 
	C549.518066,830.621033 549.085144,831.272034 548.331421,831.972717 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M442.679321,795.034302 
	C442.563904,795.642944 442.123352,796.295654 441.365051,797.003723 
	C441.482910,796.398804 441.918549,795.738586 442.679321,795.034302 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M695.267822,635.918579 
	C695.600159,634.224854 696.295105,632.525696 697.079407,630.497559 
	C700.322144,627.377747 703.264221,624.290955 706.672974,621.858032 
	C713.764771,616.796387 721.103699,612.080933 728.674561,607.116394 
	C728.246887,608.078064 727.694153,609.429443 726.690613,610.168884 
	C720.346375,614.843079 713.776062,619.219849 707.565491,624.060486 
	C705.620239,625.576721 704.827454,628.499084 703.017090,630.279480 
	C700.826660,632.433777 698.115967,634.059021 695.267822,635.918579 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M861.362427,604.979492 
	C861.839539,603.969482 862.547241,602.750916 863.595703,602.023376 
	C866.151733,600.249634 868.012146,600.468567 868.016968,604.170959 
	C868.024780,610.143738 868.010620,616.116577 867.985901,622.561279 
	C867.313538,621.895447 866.197327,620.796570 866.098328,619.612610 
	C865.836304,616.478577 865.617249,613.242981 866.118225,610.167908 
	C866.741943,606.338562 865.396301,604.871338 861.362427,604.979492 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M867.610779,645.043396 
	C867.983521,646.107971 868.165466,647.275024 867.986877,648.383911 
	C867.746582,649.875427 867.228149,651.322083 866.829041,652.788086 
	C865.319031,652.089966 863.809021,651.391907 862.124756,650.376709 
	C866.064880,650.933105 865.322815,646.586975 867.610779,645.043396 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M811.049683,609.344910 
	C811.756104,608.994995 812.492859,609.005371 813.597290,609.026611 
	C816.154907,610.173340 818.344849,611.309021 820.770874,612.719727 
	C816.607483,615.188721 813.381470,613.842041 811.049683,609.344910 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M827.069336,614.410522 
	C830.355103,614.022034 833.736572,614.022156 837.580322,614.048706 
	C834.634094,617.582214 830.906494,616.294922 827.069336,614.410522 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M728.983643,852.665283 
	C726.735474,850.903015 724.495178,848.768921 722.103577,846.342285 
	C726.088745,846.112854 727.957947,848.732178 728.983643,852.665283 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M867.581421,633.060059 
	C867.964172,635.359375 867.972656,637.752563 867.952881,640.594849 
	C864.519104,638.701843 865.589172,635.952820 867.581421,633.060059 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M711.798950,840.738159 
	C709.652405,839.999268 707.341980,838.965088 704.955566,837.598755 
	C707.131470,838.325317 709.383301,839.384094 711.798950,840.738159 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M803.144531,621.013794 
	C802.374756,620.620483 801.965332,620.241821 801.555908,619.863098 
	C802.779175,619.567688 804.002441,619.272278 805.225769,618.976929 
	C805.342712,619.335266 805.459595,619.693665 805.576538,620.052002 
	C804.885986,620.377441 804.195435,620.702942 803.144531,621.013794 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M794.920837,622.884705 
	C794.493835,622.910034 794.066833,622.935364 793.212036,622.762817 
	C792.573059,622.299805 792.307617,622.111084 791.987793,621.998779 
	C792.863770,621.499573 793.739746,621.000305 794.805908,621.071411 
	C794.971008,622.056030 794.945923,622.470398 794.920837,622.884705 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M795.273682,622.922974 
	C794.945923,622.470398 794.971008,622.056030 795.002930,621.329712 
	C795.867493,621.144104 796.725159,621.270508 797.798706,621.642090 
	C797.218445,622.245300 796.422424,622.603271 795.273682,622.922974 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M791.714050,622.115479 
	C792.307617,622.111084 792.573059,622.299805 792.892090,622.786987 
	C792.127625,623.226685 791.255188,623.444519 790.168091,623.452637 
	C790.449097,622.906006 790.944702,622.569092 791.714050,622.115479 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M812.871887,621.908813 
	C812.403320,622.241211 811.821289,622.318726 811.115479,622.157654 
	C811.580566,621.830627 812.169495,621.742310 812.871887,621.908813 
z"/>
<path fill="#AD2830" opacity="1.000000" stroke="none" 
	d="
M823.006348,613.327393 
	C823.532654,613.182739 824.098572,613.329163 824.839539,613.717957 
	C824.358398,613.846313 823.702148,613.732361 823.006348,613.327393 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M702.782349,836.872986 
	C699.866272,837.031067 696.728516,836.872498 693.296814,836.479858 
	C696.188843,836.349243 699.374695,836.452759 702.782349,836.872986 
z"/>
<path fill="#881F24" opacity="1.000000" stroke="none" 
	d="
M690.756592,836.162720 
	C689.031555,837.137024 686.895630,838.034912 684.378601,838.963928 
	C686.113586,838.025452 688.229675,837.055908 690.756592,836.162720 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M603.132202,399.683380 
	C602.566162,398.621674 601.361267,396.609497 601.880859,395.944275 
	C604.785095,392.225983 608.098511,388.826263 611.296509,385.340057 
	C611.621338,384.986023 612.159912,384.834686 612.512268,384.498108 
	C614.633850,382.471100 615.275574,377.785492 619.281006,379.167267 
	C623.026978,380.459595 626.292664,383.154755 629.745667,385.271820 
	C630.147522,385.518188 630.395874,386.014923 630.855225,386.703156 
	C629.506287,386.435883 627.666138,386.234070 626.590698,385.220673 
	C622.316040,381.192688 618.630310,382.156433 614.713684,385.782593 
	C610.325256,389.845490 604.501892,392.636658 603.132202,399.683380 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M651.940002,401.045135 
	C650.547180,400.370178 649.154419,399.695221 647.430054,398.975861 
	C647.098450,398.931458 647.020996,398.485931 646.992188,398.262024 
	C644.707336,393.081238 641.417480,389.458435 635.578247,388.929718 
	C634.735046,388.853333 633.945374,388.185486 633.028442,387.432587 
	C635.360840,387.343994 637.795776,387.614166 640.627319,387.929688 
	C641.023926,387.975006 641.037964,387.967560 641.164734,388.266174 
	C642.205383,389.028717 643.119263,389.492676 644.331909,390.113342 
	C647.067078,393.861725 649.503540,397.453430 651.940002,401.045135 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M608.979004,429.635742 
	C604.745789,426.881256 602.105164,422.932373 602.799927,417.207458 
	C605.062744,421.074402 607.031738,425.160034 608.979004,429.635742 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M607.467590,442.000153 
	C607.481018,440.450623 607.850464,438.795074 608.584717,437.055298 
	C608.574219,438.612091 608.198914,440.253082 607.467590,442.000153 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M603.319580,405.814880 
	C602.881775,405.129150 602.681885,404.250397 602.734497,403.186432 
	C603.177185,403.874756 603.367310,404.748291 603.319580,405.814880 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M724.178223,451.607452 
	C725.057678,454.651825 725.890747,457.481628 726.862427,461.027039 
	C727.666626,462.829407 728.332092,463.916199 729.247192,465.175476 
	C730.001953,466.229187 730.507141,467.110382 731.005859,468.378479 
	C730.251953,470.806366 729.504456,472.847321 728.295227,476.149109 
	C723.494019,472.166382 719.778320,468.764648 715.736328,465.809021 
	C710.222351,461.777069 706.450867,454.054138 697.455688,456.882263 
	C696.504761,457.181213 694.993042,456.181152 693.914246,455.507324 
	C688.486816,452.117004 683.250671,448.399017 677.692566,445.245270 
	C670.859985,441.368439 668.714233,435.196808 667.924622,427.985901 
	C667.643433,425.417511 667.004517,422.731079 665.844604,420.445862 
	C662.446594,413.751373 658.176880,407.456116 655.249573,400.578461 
	C652.477051,394.064178 647.819092,389.400513 642.520264,385.439880 
	C638.838745,382.688141 633.605286,381.984283 629.997253,379.170380 
	C626.018250,376.067108 622.296570,373.500153 617.093262,373.924286 
	C616.189270,372.343658 615.285339,370.763031 614.025024,369.062164 
	C615.216309,366.013092 616.763977,363.084290 618.646667,360.461182 
	C618.989014,363.259583 618.776245,365.780853 619.095032,368.233032 
	C619.258484,369.490295 620.106750,371.319489 621.100159,371.698456 
	C626.705444,373.836945 629.391418,368.750244 632.841187,366.106750 
	C633.672180,365.469940 632.957397,361.752991 631.932556,360.076660 
	C631.030090,358.600525 628.701233,357.996429 627.152710,356.702728 
	C628.864258,355.271484 630.425354,354.144714 631.989319,353.015869 
	C637.434082,361.397339 637.447632,361.445831 646.356079,358.951263 
	C647.664062,358.584991 648.722290,357.018158 649.986450,356.888611 
	C651.319092,356.752075 653.503967,357.291077 653.980530,358.220154 
	C654.708557,359.639465 654.580017,361.694916 654.300842,363.395813 
	C653.581970,367.774200 653.626892,371.163300 658.584229,373.650574 
	C661.389648,375.058167 662.885376,378.982635 665.115295,381.653015 
	C668.267761,385.428162 670.940796,390.041260 674.910889,392.604462 
	C683.023987,397.842468 686.882874,404.636047 685.174561,413.996613 
	C683.622437,422.501312 689.651855,428.417725 698.228821,427.224487 
	C701.609619,426.754150 705.888428,425.587616 706.582825,431.006073 
	C703.741699,430.961304 701.284424,430.686401 698.888794,430.957306 
	C697.431946,431.122070 694.982910,432.012634 694.900452,432.782837 
	C694.745972,434.225555 695.680115,436.072784 696.701843,437.302917 
	C700.785645,442.219574 705.142456,446.908630 709.263855,451.795288 
	C711.276978,454.182190 712.985291,456.825195 714.848633,459.339630 
	C716.601074,461.704407 718.543152,462.390350 721.109680,460.338318 
	C723.623657,458.328278 722.647461,456.681763 721.331116,454.401581 
	C719.242493,450.783691 717.740417,446.827179 716.337097,443.007935 
	C717.146667,442.988068 717.609375,442.974121 718.072083,442.960205 
	C718.382202,443.064026 718.646545,443.237549 718.941162,444.068726 
	C720.693054,446.758575 722.369019,448.860474 724.044922,450.962372 
	C724.044922,450.962372 724.131714,451.392853 724.178223,451.607452 
M682.902832,405.254150 
	C681.549500,400.306061 677.763977,398.106323 673.510193,395.784210 
	C670.319763,394.042572 667.894775,390.704620 665.489929,387.780548 
	C663.635071,385.525146 662.859497,382.162292 660.694702,380.424957 
	C655.310242,376.103821 649.927490,372.147125 651.817261,364.016968 
	C651.879639,363.748718 651.694702,363.261200 651.469727,363.115082 
	C650.323792,362.370819 648.838989,360.878510 648.002014,361.169586 
	C645.629211,361.994843 643.427795,363.443878 641.383911,364.952209 
	C641.051270,365.197723 641.543457,367.281982 642.205627,368.025391 
	C644.656006,370.776398 647.337402,373.321686 649.932007,375.944275 
	C649.445190,376.024811 648.958374,376.105316 648.471558,376.185852 
	C648.276245,378.700775 647.292297,381.501953 648.076172,383.661530 
	C649.168762,386.671539 651.614868,389.181030 653.424194,391.942444 
	C655.592896,395.252258 658.460205,398.343262 659.698669,401.980621 
	C664.090027,414.877472 670.878601,425.725250 683.738159,433.113342 
	C683.477295,423.782867 683.230408,414.952179 682.902832,405.254150 
z"/>
<path fill="#C8C8C8" opacity="1.000000" stroke="none" 
	d="
M617.213867,374.259949 
	C622.296570,373.500153 626.018250,376.067108 629.997253,379.170380 
	C633.605286,381.984283 638.838745,382.688141 642.520264,385.439880 
	C647.819092,389.400513 652.477051,394.064178 655.249573,400.578461 
	C658.176880,407.456116 662.446594,413.751373 665.844604,420.445862 
	C667.004517,422.731079 667.643433,425.417511 667.924622,427.985901 
	C668.714233,435.196808 670.859985,441.368439 677.692566,445.245270 
	C683.250671,448.399017 688.486816,452.117004 693.914246,455.507324 
	C694.993042,456.181152 696.504761,457.181213 697.455688,456.882263 
	C706.450867,454.054138 710.222351,461.777069 715.736328,465.809021 
	C719.778320,468.764648 723.494019,472.166382 728.295227,476.149109 
	C729.504456,472.847321 730.251953,470.806366 731.303833,468.504059 
	C732.421814,468.486938 733.235474,468.731140 734.049072,468.975342 
	C734.395752,470.021484 734.742432,471.067627 735.162231,472.434113 
	C735.913452,477.428131 734.812927,483.154938 742.257568,484.066406 
	C742.717163,484.514038 742.829041,484.923767 742.969727,486.061340 
	C745.646057,491.871674 748.293640,496.954193 750.952393,502.037933 
	C750.963562,502.039185 750.958801,502.056396 750.959778,502.428497 
	C749.735474,505.648407 748.697876,508.605469 747.149597,511.265259 
	C746.774292,511.910065 743.981934,512.139648 743.559143,511.590881 
	C741.864014,509.390625 740.382812,506.923370 739.335205,504.346405 
	C736.691956,497.844391 734.910156,490.915405 731.612732,484.787292 
	C729.865540,481.540283 725.780518,479.463806 722.549377,477.139069 
	C720.908569,475.958527 718.780762,475.478058 716.943481,474.542908 
	C712.072876,472.063812 709.643433,465.589996 702.991150,466.009399 
	C702.225464,465.669891 701.459778,465.330383 700.315125,464.632690 
	C694.829956,460.857452 689.808105,457.303192 684.578369,454.087036 
	C682.298401,452.684845 679.592163,451.975830 677.064026,450.949585 
	C677.048584,450.947113 677.039062,450.923706 676.960693,450.568481 
	C674.068604,449.137054 671.326172,447.472015 668.424683,447.120575 
	C663.892761,446.571747 661.720032,444.473663 662.061157,440.058685 
	C662.377197,435.967926 663.332275,431.917633 663.512573,427.827759 
	C663.695740,423.675659 664.149048,419.140625 659.149170,416.949188 
	C657.102661,412.975677 655.022644,409.018799 653.033264,405.016907 
	C652.536865,404.018341 652.332764,402.874481 651.967163,401.421265 
	C649.503540,397.453430 647.067078,393.861725 644.266418,389.775879 
	C642.947449,388.843628 641.992737,388.405609 641.037964,387.967560 
	C641.037964,387.967560 641.023926,387.975006 641.027466,387.962860 
	C640.120361,387.178375 639.229736,385.763367 638.295837,385.734222 
	C630.034546,385.476349 626.062256,376.786652 618.278320,375.686646 
	C617.919434,375.635895 617.647095,374.973267 617.213867,374.259949 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M579.904419,423.853271 
	C582.795044,419.784424 585.685669,415.715607 588.661316,411.334717 
	C589.233398,411.000183 589.720459,410.977722 590.923767,410.914490 
	C594.729309,407.104523 596.621521,411.110596 598.992676,412.005768 
	C598.991882,413.390167 598.991089,414.774597 598.988403,416.573853 
	C597.664734,419.813354 596.031982,422.539886 595.100891,425.487946 
	C593.979065,429.040100 594.171204,433.163544 592.477905,436.337250 
	C590.585754,439.883636 587.532104,443.020935 584.396301,445.629944 
	C581.633728,447.928406 578.190735,450.394196 574.820068,450.776825 
	C567.209656,451.640686 567.152283,451.135498 567.861816,443.256439 
	C567.794006,442.841766 567.821289,442.874664 568.203857,442.867188 
	C569.035828,441.871613 569.485291,440.883484 569.934753,439.895355 
	C570.734436,438.675232 571.534119,437.455109 573.007507,436.174133 
	C575.791504,438.012543 575.509827,439.420563 573.265198,441.230103 
	C572.173035,442.110565 571.173279,444.895966 571.718262,445.612732 
	C572.619324,446.797791 575.419678,448.010742 576.339478,447.447601 
	C579.805542,445.325439 583.954041,443.106812 585.780640,439.787231 
	C588.925842,434.071320 590.625061,427.537140 592.676758,421.263153 
	C593.044189,420.139496 592.183533,418.614288 591.889893,417.274475 
	C590.631470,417.784912 589.109924,418.004425 588.163879,418.860138 
	C585.316467,421.435669 582.693298,424.259003 579.983215,426.594910 
	C579.957336,425.420532 579.930847,424.636902 579.904419,423.853271 
z"/>
<path fill="#C8C8C8" opacity="1.000000" stroke="none" 
	d="
M599.007690,411.624756 
	C596.621521,411.110596 594.729309,407.104523 591.287476,410.897034 
	C592.293152,408.273865 593.651306,405.627319 595.039734,402.921753 
	C599.808960,404.667633 600.420959,407.526215 599.007690,411.624756 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M686.019775,394.605988 
	C684.866699,394.093536 683.720764,393.195862 682.036987,392.147888 
	C681.499146,391.997589 681.024780,391.973663 680.984802,391.624329 
	C678.291016,389.523163 675.637146,387.771332 672.872986,385.801178 
	C672.561951,385.310852 672.300903,385.122437 671.942932,384.622742 
	C671.269470,380.497528 670.503235,376.783325 670.036743,373.031830 
	C669.666748,370.056274 668.896362,367.413940 665.264648,368.489777 
	C661.057312,369.736145 660.195618,367.509766 660.028015,364.263947 
	C659.903076,361.845093 660.024414,359.413513 660.036011,356.987610 
	C663.730835,359.935699 666.765564,364.026215 672.559326,364.030579 
	C673.263245,368.314911 675.297302,372.332397 673.216797,376.922150 
	C672.078796,379.432770 672.771729,382.167572 677.026123,383.437408 
	C681.723938,384.839630 685.297058,388.725769 686.019775,394.605988 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M704.723206,420.763855 
	C701.086731,421.349091 697.185730,421.752747 693.282654,421.772888 
	C692.470642,421.777069 691.331970,420.503571 690.912659,419.564819 
	C690.327087,418.253937 689.785828,416.595398 690.078918,415.274933 
	C691.603210,408.406097 689.678711,402.381622 686.069702,396.300507 
	C691.143860,398.788269 693.602539,403.798401 692.598083,408.902618 
	C692.219604,410.825897 692.113647,412.815552 692.028931,414.781189 
	C691.879517,418.250183 693.571777,419.961853 697.092957,420.003174 
	C699.549377,420.031952 702.002991,420.290314 704.723206,420.763855 
z"/>
<path fill="#C8C8C8" opacity="1.000000" stroke="none" 
	d="
M681.024780,391.973663 
	C681.024780,391.973663 681.499146,391.997589 681.735718,392.015320 
	C683.638855,396.147491 685.533203,400.188843 686.885803,404.404053 
	C687.684326,406.892700 688.210632,409.740906 687.844910,412.281403 
	C686.606812,420.881653 689.869019,424.989441 698.485840,424.989746 
	C701.308105,424.989868 704.130432,425.025909 707.450378,425.401611 
	C708.635315,426.835388 709.322571,427.912964 710.009888,428.990540 
	C712.678101,433.409210 715.346436,437.827850 718.043396,442.603333 
	C717.609375,442.974121 717.146667,442.988068 716.064331,442.812714 
	C713.620117,439.754944 711.795471,436.886505 709.941284,433.693848 
	C709.272827,432.915405 708.633972,432.461182 707.778198,431.895538 
	C707.291565,431.583374 707.102051,431.324036 706.992676,431.006104 
	C705.888428,425.587616 701.609619,426.754150 698.228821,427.224487 
	C689.651855,428.417725 683.622437,422.501312 685.174561,413.996613 
	C686.882874,404.636047 683.023987,397.842468 674.910889,392.604462 
	C670.940796,390.041260 668.267761,385.428162 665.115295,381.653015 
	C662.885376,378.982635 661.389648,375.058167 658.584229,373.650574 
	C653.626892,371.163300 653.581970,367.774200 654.300842,363.395813 
	C654.580017,361.694916 654.708557,359.639465 653.980530,358.220154 
	C653.503967,357.291077 651.319092,356.752075 649.986450,356.888611 
	C648.722290,357.018158 647.664062,358.584991 646.356079,358.951263 
	C637.447632,361.445831 637.434082,361.397339 631.989319,353.015869 
	C630.425354,354.144714 628.864258,355.271484 626.864380,356.689880 
	C625.964050,356.696228 625.502625,356.410980 625.041138,356.125732 
	C628.022949,354.356384 631.004761,352.587036 634.801819,350.879761 
	C637.626648,351.540253 639.646790,352.105957 641.643799,352.743927 
	C645.769897,354.061981 649.752930,354.634827 653.015137,350.840454 
	C654.637512,351.999664 656.259949,353.158905 657.674438,354.888794 
	C657.351440,357.361603 657.212891,359.262756 657.125977,361.166229 
	C656.909302,365.913605 656.922485,370.245819 662.546204,372.617523 
	C664.594360,373.481323 665.582947,376.773254 667.149353,378.887451 
	C668.697266,380.976837 670.364502,382.977875 671.979431,385.017639 
	C672.300903,385.122437 672.561951,385.310852 672.910522,386.149658 
	C675.713867,388.468903 678.369324,390.221283 681.024780,391.973663 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M624.740845,356.145508 
	C625.502625,356.410980 625.964050,356.696228 626.713867,356.994324 
	C628.701233,357.996429 631.030090,358.600525 631.932556,360.076660 
	C632.957397,361.752991 633.672180,365.469940 632.841187,366.106750 
	C629.391418,368.750244 626.705444,373.836945 621.100159,371.698456 
	C620.106750,371.319489 619.258484,369.490295 619.095032,368.233032 
	C618.776245,365.780853 618.989014,363.259583 618.953125,360.291290 
	C620.276489,358.822968 621.628296,357.830261 623.111816,356.548462 
	C623.642456,356.227997 624.041504,356.196625 624.740845,356.145508 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M671.942932,384.622742 
	C670.364502,382.977875 668.697266,380.976837 667.149353,378.887451 
	C665.582947,376.773254 664.594360,373.481323 662.546204,372.617523 
	C656.922485,370.245819 656.909302,365.913605 657.125977,361.166229 
	C657.212891,359.262756 657.351440,357.361603 657.718384,355.235168 
	C658.619324,355.457764 659.268372,355.904572 659.976685,356.669495 
	C660.024414,359.413513 659.903076,361.845093 660.028015,364.263947 
	C660.195618,367.509766 661.057312,369.736145 665.264648,368.489777 
	C668.896362,367.413940 669.666748,370.056274 670.036743,373.031830 
	C670.503235,376.783325 671.269470,380.497528 671.942932,384.622742 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M652.833496,350.524414 
	C649.752930,354.634827 645.769897,354.061981 641.643799,352.743927 
	C639.646790,352.105957 637.626648,351.540253 635.186768,350.875793 
	C638.227844,347.015564 648.207275,346.680206 652.833496,350.524414 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M710.024170,428.620667 
	C709.322571,427.912964 708.635315,426.835388 707.908752,425.418213 
	C707.895447,424.903168 707.921448,424.727692 707.964111,424.286865 
	C708.666687,425.431274 709.352600,426.841034 710.024170,428.620667 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M724.025635,450.606201 
	C722.369019,448.860474 720.693054,446.758575 719.057800,444.277527 
	C720.734436,446.015594 722.370422,448.132812 724.025635,450.606201 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M579.982605,426.985657 
	C582.693298,424.259003 585.316467,421.435669 588.163879,418.860138 
	C589.109924,418.004425 590.631470,417.784912 591.889893,417.274475 
	C592.183533,418.614288 593.044189,420.139496 592.676758,421.263153 
	C590.625061,427.537140 588.925842,434.071320 585.780640,439.787231 
	C583.954041,443.106812 579.805542,445.325439 576.339478,447.447601 
	C575.419678,448.010742 572.619324,446.797791 571.718262,445.612732 
	C571.173279,444.895966 572.173035,442.110565 573.265198,441.230103 
	C575.509827,439.420563 575.791504,438.012543 573.307373,436.046265 
	C573.692871,434.954437 574.452209,433.929565 575.616211,433.052277 
	C576.116760,433.260284 576.212646,433.320709 576.308533,433.381134 
	C576.167480,433.211151 576.026489,433.041199 575.885498,432.871216 
	C575.923950,432.450592 575.962402,432.029968 576.269104,431.184326 
	C576.776489,430.556488 576.930176,430.302032 576.998352,429.995911 
	C576.998352,429.995911 576.944336,429.997467 577.273987,429.951843 
	C578.396667,428.932709 579.189636,427.959198 579.982605,426.985657 
z"/>
<path fill="#C8C8C8" opacity="1.000000" stroke="none" 
	d="
M579.983215,426.594910 
	C579.189636,427.959198 578.396667,428.932709 577.268677,429.925354 
	C577.713440,428.024261 578.493164,426.104065 579.588623,424.018555 
	C579.930847,424.636902 579.957336,425.420532 579.983215,426.594910 
z"/>
<path fill="#C8C8C8" opacity="1.000000" stroke="none" 
	d="
M569.639160,440.030548 
	C569.485291,440.883484 569.035828,441.871613 568.219116,442.860046 
	C568.349121,441.962128 568.846313,441.063934 569.639160,440.030548 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M576.763611,430.072083 
	C576.930176,430.302032 576.776489,430.556488 576.343506,430.873108 
	C576.063782,430.611450 576.190186,430.331848 576.763611,430.072083 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M734.015259,468.631897 
	C733.235474,468.731140 732.421814,468.486938 731.310303,468.117157 
	C730.507141,467.110382 730.001953,466.229187 729.259766,464.798920 
	C729.096069,463.751678 729.169373,463.253540 729.242676,462.755402 
	C730.822266,464.599762 732.401917,466.444122 734.015259,468.631897 
z"/>
<path fill="#C8C8C8" opacity="1.000000" stroke="none" 
	d="
M729.214050,462.554443 
	C729.169373,463.253540 729.096069,463.751678 729.010132,464.626404 
	C728.332092,463.916199 727.666626,462.829407 727.009644,461.364960 
	C727.534363,461.254333 728.050720,461.521393 728.849243,461.869751 
	C729.131409,461.951080 729.185486,462.353455 729.214050,462.554443 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M750.941162,502.036682 
	C748.293640,496.954193 745.646057,491.871674 743.008667,486.379028 
	C743.450378,485.985748 743.881958,486.002594 744.637024,486.032410 
	C746.899841,490.118378 748.839233,494.191406 750.894043,498.968811 
	C750.986694,500.461029 750.963928,501.248840 750.941162,502.036682 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M750.952393,502.037933 
	C750.963928,501.248840 750.986694,500.461029 751.043640,499.278595 
	C751.548767,499.183228 752.019775,499.482422 752.749695,499.887512 
	C753.012451,500.459259 753.016296,500.925079 752.861938,501.922302 
	C752.122192,502.321228 751.540466,502.188812 750.958801,502.056396 
	C750.958801,502.056396 750.963562,502.039185 750.952393,502.037933 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M750.959778,502.428497 
	C751.540466,502.188812 752.122192,502.321228 752.835022,502.270569 
	C753.294739,502.160950 753.547729,502.340729 753.828186,502.841980 
	C753.921204,503.498535 753.911133,503.940033 753.877380,504.713806 
	C751.900513,513.525574 749.978455,522.012390 747.966187,530.477783 
	C747.591675,532.053284 747.146179,533.752502 746.226440,535.029419 
	C744.078125,538.012024 741.213867,537.595032 739.965027,534.178833 
	C737.825989,528.327454 735.447327,522.515747 733.984985,516.489441 
	C733.428833,514.197571 734.738342,511.173981 735.875916,508.810364 
	C737.990845,504.416321 737.000854,500.525513 735.003723,496.528259 
	C733.560974,493.640442 732.356445,490.634369 730.944946,487.729950 
	C729.242004,484.225586 727.149109,480.720215 722.676575,481.099304 
	C718.081238,481.488770 715.641479,479.583130 713.054688,475.944824 
	C710.483215,472.328125 706.471802,469.735107 703.041504,466.351288 
	C709.643433,465.589996 712.072876,472.063812 716.943481,474.542908 
	C718.780762,475.478058 720.908569,475.958527 722.549377,477.139069 
	C725.780518,479.463806 729.865540,481.540283 731.612732,484.787292 
	C734.910156,490.915405 736.691956,497.844391 739.335205,504.346405 
	C740.382812,506.923370 741.864014,509.390625 743.559143,511.590881 
	C743.981934,512.139648 746.774292,511.910065 747.149597,511.265259 
	C748.697876,508.605469 749.735474,505.648407 750.959778,502.428497 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M677.079468,450.952026 
	C679.592163,451.975830 682.298401,452.684845 684.578369,454.087036 
	C689.808105,457.303192 694.829956,460.857452 699.980042,464.622375 
	C695.278931,462.992310 690.533875,461.014313 685.273315,458.729736 
	C683.845398,457.596832 682.932983,456.770538 681.860596,455.641449 
	C680.160156,453.876434 678.619812,452.414215 677.079468,450.952026 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M711.038940,484.581726 
	C709.865173,483.649506 708.053467,482.495056 707.656433,480.971832 
	C706.296204,475.753296 702.239807,473.466034 698.133728,470.999359 
	C696.054321,469.750214 694.217896,468.096558 692.135803,466.315063 
	C693.971985,466.649902 696.106567,467.003113 697.886841,467.993408 
	C704.396240,471.614197 710.301392,475.840240 711.038940,484.581726 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M682.020630,455.944275 
	C682.932983,456.770538 683.845398,457.596832 684.918457,458.688660 
	C685.048645,459.150299 685.018127,459.346344 684.923218,459.834656 
	C682.918091,458.998260 680.977356,457.869598 679.388733,456.354736 
	C680.500793,455.960419 681.260681,455.952332 682.020630,455.944275 
z"/>
<path fill="#8B8887" opacity="1.000000" stroke="none" 
	d="
M691.019104,465.556488 
	C689.059387,464.504272 687.046387,463.112549 685.008911,461.367645 
	C686.978210,462.415314 688.971985,463.816132 691.019104,465.556488 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M681.860596,455.641479 
	C681.260681,455.952332 680.500793,455.960419 679.361267,455.992981 
	C677.915161,455.662109 676.848633,455.306763 675.427856,454.919769 
	C672.545105,451.678040 670.368591,454.384674 668.135864,455.347656 
	C665.845947,456.335327 663.618652,457.468140 661.363953,458.537415 
	C659.924683,455.396790 657.428284,452.312805 657.256409,449.104279 
	C656.841675,441.362549 655.741516,433.314941 660.628296,426.460480 
	C658.297302,434.194672 659.476990,441.628601 661.122681,448.987732 
	C661.367798,450.083923 663.175781,451.585541 664.279175,451.601135 
	C668.528381,451.661407 672.785156,451.194244 677.039062,450.923706 
	C677.039062,450.923706 677.048584,450.947113 677.064026,450.949554 
	C678.619812,452.414215 680.160156,453.876434 681.860596,455.641479 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M676.960693,450.568451 
	C672.785156,451.194244 668.528381,451.661407 664.279175,451.601135 
	C663.175781,451.585541 661.367798,450.083923 661.122681,448.987732 
	C659.476990,441.628601 658.297302,434.194672 660.987793,426.378265 
	C660.976990,425.537659 660.976807,425.102539 660.981018,424.047729 
	C660.695618,422.955811 660.405823,422.483551 660.116028,422.011292 
	C659.780090,420.572052 659.444153,419.132782 659.128662,417.321350 
	C664.149048,419.140625 663.695740,423.675659 663.512573,427.827759 
	C663.332275,431.917633 662.377197,435.967926 662.061157,440.058685 
	C661.720032,444.473663 663.892761,446.571747 668.424683,447.120575 
	C671.326172,447.472015 674.068604,449.137054 676.960693,450.568451 
z"/>
<path fill="#A5A5A5" opacity="1.000000" stroke="none" 
	d="
M659.874268,422.163879 
	C660.405823,422.483551 660.695618,422.955811 660.986816,423.721222 
	C660.536194,423.448425 660.084290,422.882446 659.874268,422.163879 
z"/>
<path fill="#060606" opacity="1.000000" stroke="none" 
	d="
M524.284729,695.375732 
	C534.445435,682.580933 544.423340,670.097839 554.886230,657.007935 
	C555.281067,658.774536 555.936707,660.354797 555.942139,661.937317 
	C556.013794,682.897766 556.046936,703.858826 555.952148,724.819092 
	C555.933472,728.943359 555.645508,733.127563 554.838135,737.155090 
	C554.540710,738.638611 552.398376,740.848938 551.074463,740.865295 
	C531.675720,741.105530 512.273132,741.030396 491.878174,741.030396 
	C492.343262,739.537598 492.459900,737.388489 493.539734,735.975586 
	C502.877258,723.757324 512.400940,711.681396 521.826904,699.530273 
	C522.732117,698.363281 523.350403,696.973755 524.284729,695.375732 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M641.164734,388.266174 
	C641.992737,388.405609 642.947449,388.843628 643.967651,389.619141 
	C643.119263,389.492676 642.205383,389.028717 641.164734,388.266174 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M682.943115,405.687836 
	C683.230408,414.952179 683.477295,423.782867 683.738159,433.113342 
	C670.878601,425.725250 664.090027,414.877472 659.698669,401.980621 
	C658.460205,398.343262 655.592896,395.252258 653.424194,391.942444 
	C651.614868,389.181030 649.168762,386.671539 648.076172,383.661530 
	C647.292297,381.501953 648.276245,378.700775 648.471558,376.185852 
	C648.958374,376.105316 649.445190,376.024811 649.932007,375.944275 
	C647.337402,373.321686 644.656006,370.776398 642.205627,368.025391 
	C641.543457,367.281982 641.051270,365.197723 641.383911,364.952209 
	C643.427795,363.443878 645.629211,361.994843 648.002014,361.169586 
	C648.838989,360.878510 650.323792,362.370819 651.469727,363.115082 
	C651.694702,363.261200 651.879639,363.748718 651.817261,364.016968 
	C649.927490,372.147125 655.310242,376.103821 660.694702,380.424957 
	C662.859497,382.162292 663.635071,385.525146 665.489929,387.780548 
	C667.894775,390.704620 670.319763,394.042572 673.510193,395.784210 
	C677.763977,398.106323 681.549500,400.306061 682.943115,405.687836 
z"/>
<path fill="#E4E4E4" opacity="1.000000" stroke="none" 
	d="
M709.970825,434.018066 
	C711.795471,436.886505 713.620117,439.754944 715.717468,442.818665 
	C717.740417,446.827179 719.242493,450.783691 721.331116,454.401581 
	C722.647461,456.681763 723.623657,458.328278 721.109680,460.338318 
	C718.543152,462.390350 716.601074,461.704407 714.848633,459.339630 
	C712.985291,456.825195 711.276978,454.182190 709.263855,451.795288 
	C705.142456,446.908630 700.785645,442.219574 696.701843,437.302917 
	C695.680115,436.072784 694.745972,434.225555 694.900452,432.782837 
	C694.982910,432.012634 697.431946,431.122070 698.888794,430.957306 
	C701.284424,430.686401 703.741699,430.961304 706.582825,431.006073 
	C707.102051,431.324036 707.291565,431.583374 707.818298,432.214600 
	C708.707153,433.102753 709.338989,433.560425 709.970825,434.018066 
z"/>
<path fill="#B7B7B7" opacity="1.000000" stroke="none" 
	d="
M680.984802,391.624329 
	C678.369324,390.221283 675.713867,388.468903 673.020874,386.367981 
	C675.637146,387.771332 678.291016,389.523163 680.984802,391.624329 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M709.941284,433.693848 
	C709.338989,433.560425 708.707153,433.102753 708.035217,432.326050 
	C708.633972,432.461182 709.272827,432.915405 709.941284,433.693848 
z"/>
<path fill="#D9D9D9" opacity="1.000000" stroke="none" 
	d="
M575.941895,432.934753 
	C576.026489,433.041199 576.167480,433.211151 576.308533,433.381134 
	C576.212646,433.320709 576.116760,433.260284 575.946167,433.042175 
	C575.871521,432.884521 575.998413,432.998291 575.941895,432.934753 
z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
